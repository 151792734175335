import React from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

/********************************/
/*    症状カード表示用レコード    */
/* 関連画面：
  個人ホーム画面:PersonalHome.js */
/********************************/

const useStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
  },
  content: {
    flex: '1 0 auto',
    margin: '0',
    height: '5em'
  },
  cover: {
    maxWidth: 65,
    maxHeight: 65,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 1,
    paddingBottom: 1,
  },
  action: {
    width: 60
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 1,
    paddingBottom: 1,
  },
  paper: {
    padding: 2,
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '60',
    maxHeight: '60',
  },
};
class MedicalRecord extends React.Component { 
  
  constructor(props) {
    super(props);
    this.state = {
      record:     props.record,
      memberName: props.record.memberName,
      member_url: props.record.member_url,
      memberID:   props.record.memberID,
      FamilyID:   props.FamilyID,
      uid:        props.record.uid,
      //medicalrecordid: props.medicalrecordid
    }
  }
  
  handleSymptomCard = () => {
    console.log('症状カードUID:'+this.state.uid)
    console.log('MedicalRecordId:'+this.state.record.MedicalRecordId)
    console.log('FamilyID:'+this.state.FamilyID)
    document.title="個人ホーム - ふぁみかるくん"
    window.history.pushState(null, `診療情報 - ふぁみかるくん`, "/SymptomCard/"+this.state.record.MedicalRecordId)
    document.title="診療情報 - ふぁみかるくん"
    this.props.history.push({
      // pathname: '/followup',
      pathname: '/SymptomCard/'+this.state.record.MedicalRecordId,
      state: {
        uid:             this.state.uid,                    //ログインユーザーuid
        //MedicalRecordId: this.state.record.MedicalRecordId, //診療情報のレコードID
        record:          this.state.record,                 //診療情報のレコード本体
        FamilyID:        this.state.FamilyID,               //診療情報登録対象の家族ID
        MemberID:        this.state.record.MemberID,        //診療情報登録対象のメンバーID
        DisplayName:     this.state.memberName,             //診療情報登録対象の表示名
        member_url:      this.state.member_url,             //診療情報登録対象のアイコン画像パス
        Diagnosis:       this.state.record.Diagnosis,       //診療情報の診断結果
        HospitalName:    this.state.record.HospitalName,    //診療情報の医療機関名
      }
    })
    console.log(`username: ${this.state.memberName}`);
  }

  render(){
    return (
      <React.Fragment>
        <Card className={this.props.classes.root}>
          <CardActionArea onClick={()=>{this.handleSymptomCard()}}>
            <CardContent className={this.props.classes.content}>
              <div className="text-left textTitle famikarColor">
                <Typography variant="subtitle1" className="border-bottom" component="div">
                  <div>
                    {this.state.record.CreateTime.getFullYear()}年{this.state.record.CreateTime.getMonth()+1}月{this.state.record.CreateTime.getDate()}日
                  </div>
                </Typography>
                <Typography variant="body1" component="div">
                  <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Grid item xs={12}> {/* 受診科目、医療機関の表示領域をカード幅いっぱいに広げるため、xs=6 ⇒ xs=12に変更。 2020/07/11 */}
                      <div style={{width:'100%'}}>
                        <Grid container justifyContent="flex-start" alignItems="flex-start">
                          <Grid item xs={12}>
                            <div style={{textAlign:'left'}}>
                              {this.state.record.Title} {/* 症状・診断結果等、表示タイトル */}
                            </div>
                          </Grid>
                          {/* // 受診医療機関、診療科目は非表示扱いとする 2021/08/14 高橋
                          <Grid item xs={12}>
                            <div style={{textAlign:'left'}}>
                              {this.state.record.HospitalName} //診療科目 受診医療機関
                              &nbsp;{this.state.record.MedicalSpecialty} //診療科目 受診医療科
                              {console.log('this.state.record.medicalrecordid: ' + this.state.record.MedicalRecordId)}
                            </div>
                          </Grid>
                          */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </Typography>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(withRouter(MedicalRecord))