import React                      from "react";
import {withRouter}               from "react-router-dom";
//import { makeStyles, withStyles } from '@material-ui/core/styles';
import FamikarHeader from '../components/FamikarHeader';
import AppBar        from '@material-ui/core/AppBar'
import Grid          from '@material-ui/core/Grid';
import TextField     from '@material-ui/core/TextField';
import Input         from '@material-ui/core/Input';
import Button        from '@material-ui/core/Button';
// import InputLabel    from '@material-ui/core/InputLabel';
// import MenuItem      from '@material-ui/core/MenuItem';
// import FormControl   from '@material-ui/core/FormControl';
// import Select        from '@material-ui/core/Select';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from 'firebase/app';
import 'firebase/auth';

let uid = ''          //招待送信者のuidを格納する。
let tmpFamilyIDs = [] //FamilyIDを格納
let Sender = ''      //送信者名を格納
let DisplayNames = [] //メンバー表示名リスト
//let FamilyName        //家族名一時格納

let MemberList = [ //メンバー一覧用
  // {
  //   FamilyID:    [],  //家族ID
  //   MemberID:    "",   //メンバーID
  //   DisplayName: '', //メンバー表示名
  // }
]

class Invitation extends React.Component {
  constructor(props) {
    super(props);
    var {params} = this.props.match //クエリ文字列を取得する。
    this.state = {
      isLogin:    false, 
      uid:        '',                                       //ログインユーザーのuid
      FamilyID:   (params.FamilyID) ? params.FamilyID : '', //家族ID
      Email:      '',                                       //招待対象者のEメールアドレス
      Name:       '',                                       //招待対象者のお名前
      Sender:     '',                                       //招待送信者名
      SenderID:   '',                                       //招待した人のuid
      Message:    '',                                       //招待対象者宛のメッセージ
      Permission: '',                                       //招待対象者のアクセス権
      //MemberPermission: [],                                 //招待対象者のゲストアクセス権付与対象メンバー情報
      //MemberList: [],                                       //メンバー一覧
      //DisplayNames: [],                                     
      //FamilyName: '',                                       //家族名
    }
  }

  componentDidMount() { //ページ表示時、情報更新時に実行
    console.log("compoonentDidMount: this.state.FamilyID["+this.state.FamilyID+"]")
    const db = firebase.firestore();
    this.getLoginInfo(db) // ログイン情報取得
  }

  // ログイン情報取得
  getLoginInfo(db) {
    console.log("Invitation.js getLoginInfo()")
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
          if (user) {
            uid = user.uid //ログインユーザーのuidを取得する
            resolve()
          }
        })
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.FamilyID === '') {
          db.collection("Person").doc(uid).get().then((Person_result)=>{ //ログインユーザー情報から、家族IDの情報を取得する
            tmpFamilyIDs.push(Person_result.data().FamilyID[0].id) //1件目の家族IDを取得する。
            Sender = Person_result.data().FamilyName + ' ' + Person_result.data().FirstName //招待送信者名を取得する
            window.history.replaceState(null, null, "/Invitation/" + tmpFamilyIDs[0]) //URLに家族IDが含まれない場合は、家族IDを含むURLに書き替えを行う。
            resolve()
          })
        } else {
          tmpFamilyIDs.push(this.state.FamilyID) //1件目の家族IDを取得する。
          db.collection("Person").doc(uid).get().then((Person_result)=>{ //ログインユーザー情報から、家族IDの情報を取得する
            Sender = Person_result.data().FamilyName + ' ' + Person_result.data().FirstName //招待送信者名を取得する
            resolve()
          })
        }
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{ //家族のメンバー情報を取得する。
        db.collection("Family").doc(tmpFamilyIDs[0]).collection("Member").orderBy("DisplayName").get().then((result)=>{
          MemberList = []
          DisplayNames = []
          result.forEach((doc)=> {
            let dataT = doc.data()
            let DeleteFlag = (dataT.DeleteFlag == null) ? false : dataT.DeleteFlag //家族情報の削除フラグがある場合は、非表示にする。
            if (DeleteFlag === false) {
              MemberList.push({
                FamilyID:          tmpFamilyIDs[0],    //家族ID
                MemberID:          doc.id,             //メンバーID
                DisplayName:       dataT.DisplayName,  //メンバー表示名
              })
              DisplayNames.push(doc.id)
            }
          })
          //console.dir(MemberList)
          //console.dir(DisplayNames)
          resolve()
        }).catch(function(error) {
          console.log("Error getting family member documents: ", error)
          resolve()
        })
      })
    })
    .then(()=>{
      //console.log("uid: " + uid)
      this.setState({
        uid:          uid,             //ログインユーザーのuid
        FamilyID:     tmpFamilyIDs[0], //家族ID
        Sender:       Sender,          //招待送信者名
        MemberList:   MemberList,      //メンバー一覧用
        DisplayNames: DisplayNames,    //メンバー表示名リスト
      })
    })
  }

  handleChange = (e) => {
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        if (e.target.name !== 'MemberPermission') {
          this.setState({
            [e.target.name]: e.target.value
          })
        } else if (e.target.name === 'MemberPermission') {
          
        }
        resolve()
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        console.log(e.target.name + ": " + e.target.value)
        if (e.target.name === 'MemberPermission') {
          console.log("this.state.MemberPermission: " + this.state.MemberPermission)
        }
        resolve()
      })
    })
  }

  /******************/
  // 家族ホーム画面に戻る
  /******************/
  handleGoBack = () =>{
    if (this.state.FamilyID) { //家族IDが明らかな場合
      this.props.history.push({ pathname: '/FamilyHome/' + this.state.FamilyID }) //家族ホーム画面に移動
    } else { //家族IDが不明な場合
      this.props.history.push({ pathname: '/' }) //一旦、トップページに移動
    }
  }

  /* 招待情報取得 */
  handleInvitation = async() => {
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        resolve()
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        resolve()
      })
    })
    //招待情報をデータベースに格納する
    
  }

  render(){
    /* 当面は招待機能をフルアクセスで提供する。
    //招待対象者アクセス権の選択肢
    const PermissionList = []
    PermissionList.push(<MenuItem key='0' value=''>　</MenuItem>)
    PermissionList.push(<MenuItem key='1' value='owner'>オーナー権限(家族情報へのアクセスを全て許可する)</MenuItem>)
    PermissionList.push(<MenuItem key='2' value='guest'>ゲスト権限(家族の指定メンバー情報へのアクセスを許可する)</MenuItem>)
    PermissionList.push(<MenuItem key='3' value='none'>家族情報へのアクセスを許可しない</MenuItem>)

    //メンバー情報
    const MemberSelectList = []
    MemberSelectList.push(<MenuItem key='0' value=''>　</MenuItem>)
    this.state.MemberList.forEach((member)=>{
      MemberSelectList.push(<MenuItem key={member.MemberID} value={member.MemberID}>{member.DisplayName}</MenuItem>)
    })
    */
    return (
      <React.Fragment>
        {
          /* uidに値がある場合のみヘッダー表示を行う */
          this.state.uid && <FamikarHeader uid={this.state.uid} FamilyID={this.state.FamilyID} isHome={true} />
        }
        {/* this.state.uid && <div>uid: {this.state.uid}</div> */}
        {/* this.state.FamilyID && <div>FamilyID: {this.state.FamilyID}</div> */}
        {/* this.state.Sender && <div>Sender: {this.state.Sender}</div>*/}
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={0} style={{textAlign: 'left'}}>
          <Grid item xs={12} style={{paddingLeft: '1em', paddingRight: '1em'}}>
            家族・関係者を招待しましょう
          </Grid>
          <Grid item xs={12} style={{paddingLeft: '2em', paddingRight: '1em'}}>
            招待した方に家族の１人の情報を見せるか、<br />
            家族全員の情報を見せるかを選択できます。
          </Grid>
          <Grid item xs={12} style={{padding: '1em'}}>
            招待する方のEメールアドレス（必須）
            <Input fullWidth id="inputEmail" placeholder="Eメールアドレスを入力" name="Email" value={this.state.Email} onChange={(e)=>this.handleChange(e)} />
          </Grid>
          <Grid item xs={12} style={{padding: '1em'}}>
            招待する方のお名前（任意）
            <Input fullWidth id="inputName" placeholder="お名前を入力" name="Name" value={this.state.Name} onChange={(e)=>this.handleChange(e)} />
          </Grid>
          <Grid item xs={12} style={{padding: '1em'}}>
            招待する方へのメッセージ（任意）
            <TextField fullWidth multiline rows='5' variant="outlined" id="inputMessage" placeholder="メッセージを入力" name="Message" value={this.state.Message} onChange={(e)=>this.handleChange(e)} />
          </Grid>
          {/* 当面の間、アクセス権はフルアクセスのみとする。 2022.12.18
          <Grid item xs={12} style={{padding: '1em'}}>
            招待する方のアクセス権を設定しましょう（必須）
            <TextField fullWidth select id="inputPermission" label="招待対象者のアクセス権限" name="Permission" value={this.state.Permission} onChange={(e)=>this.handleChange(e)}>
            {PermissionList}
            </TextField>
          </Grid>
          <Grid item xs={12} style={{padding: '1em'}}>
            ゲスト権限でアクセス許可するメンバーを設定しましょう（必須）
            <FormControl fullWidth>
              <InputLabel id="inputMemberPermission">対象メンバーを選択しましょう。</InputLabel>
              <Select
                id="inputMemberPermission"
                label="対象メンバーを選択しましょう。"
                name="MemberPermission"
                multiple
                value={this.state.MemberPermission}
                onChange={(e)=>this.handleChange(e)}
              >
                {MemberSelectList}
              </Select>
            </FormControl>
          </Grid>
          */}
        </Grid>
        {/* 画面下部のメニュー */}
        <AppBar position="fixed" style={{backgroundColor: '#FFFFFF', top: 'auto', bottom: '0', boxShadow: 'none'}}>
          <Grid container justifyContent="space-between" alignItems="flex-start" style={{padding: '16px 8px 8px', maxWidth: '480px'}} className="App">
            <Button variant="contained" color="primary" onClick={this.handleGoBack}>戻る</Button>
            <Button variant="contained" color="primary" onClick={this.handleInvitation}>招待する</Button>
          </Grid>
        </AppBar>
      </React.Fragment>
    )
  }
}

export default withRouter(Invitation)