import React           from "react";
import { withRouter }  from 'react-router-dom';
import { ListGroup }   from 'react-bootstrap';
import { withStyles }  from '@material-ui/core/styles';
import Grid            from '@material-ui/core/Grid';
//import GridList        from '@material-ui/core/GridList';  //2021.11.27 Material-UIのツール名称変更（GridList→ImageList）に伴い無効化
import ImageList       from '@material-ui/core/ImageList';   //2021.11.27 Material-UIのツール名称変更（GridList→ImageList）に伴い変更
//import Card            from '@material-ui/core/Card';
//import CardActionArea  from '@material-ui/core/CardActionArea';
//import CardContent     from '@material-ui/core/CardContent';
import Button          from '@material-ui/core/Button';
import Modal           from '@material-ui/core/Modal';
import Fade            from '@material-ui/core/Fade';
import AppBar          from '@material-ui/core/AppBar';
//import { fontWeight }  from "@material-ui/system";
import MedicalRecord   from '../components/MedicalRecord';
import FamikarHeader   from '../components/FamikarHeader';
import ModalFamilyView from '../components/ModalFamilyView';
import ModalInterview  from '../components/ModalInterview';
import InterviewImg    from '../img/clinical_f.svg'
//import queryString     from 'query-string'
import firebase        from 'firebase/app';
import 'firebase/auth';

///////////////////////////////
//// 2020.12.5 作成者：新井 ////
////   個人メンバー一覧画面  ////
//// memo : デバック用表示 console.log("好きな文字列");
///////////////////////////////

//メンバー一覧用
const SymptomsList = {
  list:[]
}

const useStyles = theme => ({
  root: {
    display:         'flex',
    flexWrap:        'wrap',
    justifyContent:  'space-around',
    overflow:        'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width:  500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  titleBar: {
    background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  modal: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  paper: {
    position: 'relative',
    display: 'block',
    top: '0',
    left: '50%',
    outline: 'none',
    transform: 'translate(calc(-50% - 0.5rem), 0)',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,.2)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 2, 3),
    margin: '1.75rem .5rem',
    maxWidth: '460px',
    width: 'calc(100% - 1rem)',
    borderRadius: '.3rem',
    overflowY: 'visible',
  },
});

var FamilyIDs   = [] // 家族ID(配列)
var FamilyID    = '' // 家族ID
var FirstName   = '' // 名前
var FamilyName  = '' // 名字
var uid         = '' // ログインユーザーuid
var DisplayName = '' // メンバーの表示名
var member_url  = '' // メンバーアイコン画像パス

class PersonalHome extends React.Component {

  /******************/
  // コンストラクタ
  /******************/
  constructor(props) {
    super(props);
    var {params} = this.props.match //クエリ文字列を取得する。
    
    this.state = {
      isLogin:     false,
      username:    '',
      ModalShows:  {
        'Interview':  false,
        'FamilyView': false
      },
      uid:         (this.props.location.state === undefined) ? '' : this.props.location.state.uid,         //ログインユーザーのuid
      FamilyID:    (this.props.location.state === undefined) ? '' : this.props.location.state.FamilyID,    //診療情報登録対象の家族ID
      DisplayName: (this.props.location.state === undefined) ? '' : this.props.location.state.DisplayName, //診療情報登録対象の表示名
      member_url:  (this.props.location.state === undefined) ? '' : this.props.location.state.member_url,  //診療情報登録対象のアイコン画像パス
      MemberID:    (params.MemberID) ? params.MemberID : '', // 診療情報登録対象のメンバーID
      symptomsList: [{     //症状カード情報
        uid:             '', // ログインユーザーuid
        MemberID:        '', // メンバーID
        FamilyID:        '', // 家族ID
        DisplayName:     '', // 表示名
        member_url:      '', // メンバー画像URL
        MedicalRecordId: '', // 症状カードID
        Interview_state: ''  // 問診票の取扱状態（'New'=新規作成, 'View'=表示, 'Edit'=編集）
      }]
    }
    //console.log(`PersonalHome props[${JSON.stringify(this.props,null,'\t')}]`);
    console.log(`PersonalHome uid[${this.state.uid}]`);
    console.log(`PersonalHome FamilyID[${this.state.FamilyID}]`);
    console.log(`PersonalHome MemberID[${this.state.MemberID}]`);
    console.log("PersonalHome member_url["+this.state.member_url+"]");
    console.log(`PersonalHome DisplayName[${this.state.DisplayName}]`);
  }

  /******************/
  // コンポーネント
  /******************/
  componentDidMount() {
    const db = firebase.firestore();
    this.getLoginInfo(db) //ログイン情報取得
  }

  componentWillUnmount() { //ページを離れる際に実行
    //document.title = 'ふぁみかるくん' //家族ホーム画面を離れる際、ページタイトルを初期値に戻す
    //document.title = `${(DisplayName) ? DisplayName : '◯◯'}の個人ホーム - ふぁみかるくん`
  }
  
  /******************/
  // ログイン情報取得
  /******************/
  getLoginInfo(db){
    firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
      if(user){ 
        uid = user.uid
        console.log(`getLoginInfo() uid: ${uid}`)
        this.getPersonInfo(db) //ユーザー情報取得
      }
    })
  }

  /******************/
  // ユーザー情報取得
  /******************/
  getPersonInfo(db){
    //console.log(`getPersonInfo() uid: ${uid}`)
    // ログインユーザー情報取得
    db.collection("Person").doc(uid).get().then((result)=>{
      var data = result.data()
      FamilyIDs  = [] // 家族ID(配列)
      FamilyIDs.push(data.FamilyID[0].id) //1件目の家族IDを取得する。
      FirstName  = data.FirstName  // 名前
      FamilyName = data.FamilyName // 名字
      FamilyID   = FamilyIDs[0]    // 家族ID
      //console.log(`FamilyIDs: ${FamilyIDs[0]}`)
      //console.log(`FamilyID: ${FamilyID}`)
      //console.log(`firstname: ${FirstName}`)
      //console.log(`familyname: ${FamilyName}`)
      if (this.state.MemberID !== '') {
        this.getMemberInfo(db) //メンバー情報取得
      } else {
        console.log("push URL: /FamilyHome/" + FamilyID)
        this.props.history.push('/FamilyHome/' + FamilyID) //ログイン中でメンバーIDが無い場合は、家族ホーム画面に移動する。
      }
      
    })
  }


  /******************/
  // メンバー情報取得
  /******************/
  getMemberInfo(db){
    db.collection("Family").doc(FamilyID).collection("Member").doc(this.state.MemberID)
    .get().then((result)=>{
      var data = result.data()
      DisplayName = data.DisplayName //メンバー表示名
      member_url  = data.image_url   //メンバーアイコン画像URL
      document.title = `${(DisplayName) ? DisplayName : '◯◯'}の個人ホーム - ふぁみかるくん`
      this.getSymptomsList(db) // 症状カード情報取得
    })
  }

  /******************/
  // 家族ホーム画面に戻る
  /******************/
  handleGoBack = (e) =>{
    this.UserStatusCheck() //ログイン状態チェック
  }

  // ログイン状態チェック
  UserStatusCheck=()=>{;
    firebase.auth().onAuthStateChanged((user)=>{
      if (user) {
        const db = firebase.firestore();
        var FamilyID = '';
        this.setState({isLogin: true}) 
        db.collection("Person").doc(user.uid).get().then((result)=>{
          FamilyID = result.data().FamilyID[0].id //1件目の家族IDを取得する。
          console.log("push URL: /FamilyHome/" + FamilyID)
          this.props.history.push('/FamilyHome/' + FamilyID) //ログイン中の場合は、家族ホーム画面に移動する。
        })
      } else {
        this.setState({isLogin: false})
        sessionStorage.clear() //ログインページのリロード管理用のローカルストレージを削除する。
        console.log("push URL: /Login")
        this.props.history.push('/Login') //ログイン中でなければ、ログイン画面に移動する。
      }
    })
  }

  /******************/
  // 症状情報取得リスト
  /******************/
  getSymptomsList(db){
    db.collection("MedicalRecords").where("FamilyID", "==", FamilyID).where("MemberID", "==", this.state.MemberID)
    .get().then((result)=>{
      SymptomsList.list = []
      //var members = []
      //var list = []
      result.forEach((doc)=> {
        var dataT = doc.data()
        var DeleteFlag = (dataT.DeleteFlag == null) ? false : dataT.DeleteFlag //家族情報の削除フラグがある場合は、非表示にする。(2020.08.29追加)

        if(DeleteFlag === false) {
          SymptomsList.list.push({
            uid:              uid,                    // ユーザID
            memberName:       DisplayName,            // 表示名
            member_url:       member_url,             // アイコン画像パス
            HospitalName:     dataT.HospitalName,     // 病院名
            Title:            dataT.Title,            // 病名
            Diagnosis:        dataT.Diagnosis,        // 診断結果
            MedicalSpecialty: dataT.MedicalSpecialty, // 診療科
            MemberID:         dataT.MemberID,         // メンバーID
            MedicalRecordId:  doc.id,                 // 症状カードIDを次画面に引継ぐ
            CreateTime:       ( dataT.CreateTime == null ) ? null : dataT.CreateTime.toDate(), // 登録日
          })
          //members.push(doc.id)                  //診療情報表示対象のメンバーID
        }
      })

      this.setState({
        uid:          uid,               // ログインユーザーのuid
        FamilyIDs:    FamilyIDs,         // 家族ID
        firstname:    FirstName,         // 名字
        familyname:   FamilyName,        // 名前
        FamilyID:     FamilyID,          // 診療情報登録対象の家族ID
        DisplayName:  DisplayName,       // 診療情報登録対象の表示名
        member_url:   member_url,        // 診療情報登録対象のアイコン画像パス
        symptomsList: SymptomsList.list, // 症状カード一覧
      })
    }).catch(function(error) {
      console.log("Error getting family member documents: ", error);
    });
  }
  
  // /******************/
  // // 新規追加（問診票登録）ボタン
  // // 個人ホーム画面なのでstateのデータを使うため、引数はなし
  // /******************/
  // handlerInterviewButtonClick = () =>{
  //   console.log(`え`);
  //   this.props.history.push({
  //     pathname: '/interview',
  //     state: {
  //       uid:             this.state.uid,        //ログインユーザーのuid
  //       FamilyID:        this.state.FamilyID,   //診療情報登録対象の家族ID
  //       MemberID:        this.state.MemberID,   //診療情報登録対象のメンバーID
  //       DisplayName:     this.state.DisplayName, //診療情報登録対象の表示名
  //       Interview_state: 'New'       //問診票の取扱状態（'New'=新規作成, 'View'=表示, 'Edit'=編集）
  //     }
  //   })
  // }

  /**
   * 更新・削除用のモーダルを展開
   * @param {*} view "FamilyView"：基本情報、"Interview"：問診
   * @param {*} docId 更新/削除対象データのDocID
   */
   // handleUpdateModalOpen = (view,docId) =>{
   handleNewModalOpen = (view) =>{
    switch(view) {
      case "FamilyView":
        //window.history.pushState(null, "経過情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        //document.title = "経過情報 - ふぁみかるくん"
        this.setState({
          ModalShows: {
            Interview:  false,
            FamilyView: true
          }
        })
        break
  
      case "Interview":
        //window.history.pushState(null, "問診登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        //document.title = "問診登録 - ふぁみかるくん"
        this.setState({
          ModalShows: {
            Interview:  true,
            FamilyView: false
          }
        })
        break
  
      default:
        break
    }
  };
  
  /**
   * 各種モーダルを閉じる
   */
   handleModalClose = () => {
    //window.history.pushState(null, `ふぁみかるくん`, "/PersonalHome/"+this.state.medicalrecordid)
    //document.title = `ふぁみかるくん`
    this.setState({
      ModalShows: { 
        Interview:  false,
        FamilyView: false
      }
    })
  }

  /**
   * 各種モーダルを閉じて、画面内データを再読み込みする。
   */
   handleReloadModalClose = () => {
    //window.history.pushState(null, `ふぁみかるくん`, "/PersonalHome/"+this.state.medicalrecordid)
    //document.title = `ふぁみかるくん`
    this.setState({
      ModalShows: { 
        Interview:  false,
        FamilyView: false
      }
    })
    this.getSymptomsList(firebase.firestore()) //症状カード情報の再読み込みを行う。
  }

  /******************/
  // 画面表示
  /******************/
  render(){

    var symptomsList = []
    this.state.symptomsList.forEach((symptoms)=>{
      if( symptoms.MemberID !== '' ){
        console.log('symptoms.MedicalRecordId: '+symptoms.MedicalRecordId);
        //console.log('symptoms.memberName: '+symptoms.memberName);
        //console.log('symptoms.uid: '+symptoms.uid);
        //console.log('symptoms.MemberID: '+symptoms.MemberID);
        symptomsList.push(
          <ListGroup.Item as="li" style={{height: '5.5em', width: 'calc(100% - 4px)', margin: '2px'}} className="justify-md-left align-left" key={symptoms.MedicalRecordId}>
            <MedicalRecord record={symptoms} FamilyID={this.state.FamilyID} />
          </ListGroup.Item>
        )
      }
    })

    return (
      <React.Fragment>
        <FamikarHeader uid={this.state.uid} FamilyID={this.state.FamilyID} isHome={false} />
        {/* 家族の情報をモーダル表示で対応する。 */}
        {console.log("DispName["+this.state.DisplayName+"] ")}
        {console.log("uid["+this.state.uid+"] ")}
        {console.log("FamilyID["+this.state.FamilyID+"] ")}
        {console.log("MemberID["+this.state.MemberID+"] ")}
        <Grid container alignItems="center" style={{padding: "10px 8px 10px"}}>
          <Grid item xs={9} style={{textAlign: "left"}}>
            <h5 style={{margin: "0px auto"}}>
              <span style={{textDecoration: "underline"}}>
              {(this.state.DisplayName !== '') ? `${this.state.DisplayName}` : ''}
              </span>
            </h5>
          </Grid>
          <Grid item xs={3} style={{textAlign: "right"}}>
            <Button style={{margin: "0px 0px", padding: "6px 12px"}} variant="outlined" color="primary" onClick={()=>this.handleNewModalOpen('FamilyView')}>基本情報</Button>
          </Grid>
        </Grid>
        <Grid style={{textAlign: "left", padding: '0 8px'}}><label style={{fontWeight:"bold"}}>受診一覧</label></Grid>
        {/* </div> */}

        {/* //問診票登録ボタンの差し替えのため、Cardを使用するボタンはButtonを使用する内容に置き換え 2021/08/14 高橋
        <Card>
          <CardActionArea onClick={()=>{this.handleNewModalOpen('Interview')}}>
            <CardContent className={this.props.classes.content}>
              <div className="text-left textTitle famikarColor">
                <Grid item xs={12}>
                  <div style={{ width: '100%'}}>
                    <div style={{ textAlign: 'center'}}>
                      ＋ 問診票を登録しましょう
                    </div>
                  </div>
                  </Grid>
              </div>
            </CardContent>
          </CardActionArea>
        </Card>
        */}

        <Grid container spacing={0} style={{margin: "8px 0 16px"}}>
          <Grid item xs={12}>
            <Button variant="contained" style={{width: "calc(100% - 16px)", padding: "10px 0px", backgroundColor: '#d9d2e9'}} onClick={()=>{this.handleNewModalOpen('Interview')}}>
              ＋<img src={InterviewImg} alt="問診票登録" width="35px" />&nbsp;問診票を登録しましょう
            </Button>
          </Grid>
        </Grid>
        
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
          <Grid item xs={12}>
            <ImageList cols = {1} as="ul">
              {symptomsList}
            </ImageList>
          </Grid>
          <Grid item xs={12} style={{height: '65px'}} />
        </Grid>

        {/* 画面下部のメニュー */}
        <AppBar position="fixed" style={{backgroundColor: '#FFFFFF', top: 'auto', bottom: '0', boxShadow: 'none'}}>
          <Grid container justifyContent="flex-end" alignItems="flex-end" style={{padding: '16px 8px 8px', maxWidth: '480px'}} className="App">
            <Button variant="contained" color="primary"   onClick={this.handleGoBack}>戻る</Button>
          </Grid>
        </AppBar>

        <Modal open={this.state.ModalShows.FamilyView} onClose={this.handleModalClose} className={this.props.classes.modal}>
          <Fade in={this.state.ModalShows.FamilyView}>
            <div className={this.props.classes.paper}>
              <ModalFamilyView handleModalClose={this.handleModalClose} DisplayName={this.state.DisplayName} image_src={this.state.member_url}
                uid={this.state.uid} FamilyID={this.state.FamilyID} MemberID={this.state.MemberID} />
            </div>
          </Fade>
        </Modal>

        <Modal open={this.state.ModalShows.Interview} onClose={this.handleModalClose} className={this.props.classes.modal}>
          <Fade in={this.state.ModalShows.Interview}>
            <div className={this.props.classes.paper}>
              <ModalInterview handleModalClose={this.handleModalClose} handleReloadModalClose={this.handleReloadModalClose}
                className={this.props.classes.float} uid={this.state.uid} MemberID={this.state.MemberID} FamilyID={this.state.FamilyID}
                DisplayName={this.state.DisplayName} member_url={this.state.member_url} Interview_state='New'
              />
            </div>
          </Fade>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(withRouter(PersonalHome))