import React             from 'react';
import { withRouter }    from 'react-router';
import logo              from '../img/logo.png';
import { withStyles }    from '@material-ui/core/styles';
import Link              from '@material-ui/core/Link';
import AppBar            from '@material-ui/core/AppBar';
import useScrollTrigger  from '@material-ui/core/useScrollTrigger';
import PropTypes         from 'prop-types';
import Toolbar           from '@material-ui/core/Toolbar';
import FamikarHeaderMenu from './FamikarHeaderMenu'
export function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });
  
  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
  
ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const useStyles = {
  button: {
    margin: 2,
    //width: 150
  },
  root: {
    height: 800,
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
};
  
class FamikarHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: false,
    }
  }

  render(){
    return (
      <React.Fragment>
        <div style={{background:'white', borderBottom: 'inset 2px'}}>
          <ElevationScroll >
            <AppBar  background="white" position="static">
              <Toolbar style={{background:'white'}}>
                <div style={{flexGrow:1,textAlign: 'left', whiteSpace: 'nowrap'}} >
                  <Link href='/'><img src={logo} alt="" style={{margin:3 }}/></Link> {/* ふぁみカルくんロゴ */}
                </div>
                <FamikarHeaderMenu history={this.props.history} uid={this.props.uid} FamilyID={this.props.FamilyID} isHome={this.props.isHome}/>
              </Toolbar>
            </AppBar>
          </ElevationScroll>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(withRouter(FamikarHeader))