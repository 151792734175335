import React          from "react";
import { withRouter } from 'react-router';
import Button         from '@material-ui/core/Button';
import TextField      from '@material-ui/core/TextField';
import Grid           from '@material-ui/core/Grid'
import queryString    from 'query-string'
import Resizer        from 'react-image-file-resizer';
import firebase       from'firebase/app';
import 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';

const MedicineImageList = {
  list:[
    //{
    //  number:       "(数値)",     //画像番号
    //  source_url:   "(文字列)",   //取り込み元画像
    //  image_url:    "(文字列)",   //お薬画像パス
    //  thum_url:     "(文字列)",   //お薬サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //  image_change: "(ブール値)", //お薬登録画像の変更有無（true=変更あり、false=変更なし）
    //}
  ],
  UploadList:[
    //{
    //  image_url:    "(文字列)",   //お薬画像パス
    //  thum_url:     "(文字列)",   //お薬サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //}
  ]
}

//画像リサイズ用定数
const resizer = {
  maxWidth:           1920,    //変換後画像の最大横幅
  maxHeight:          1920,    //変換後画像の最大縦幅
  maxThumbnailWidth:  320,     //変換後サムネイル画像の最大横幅
  maxThumbnailHeight: 320,     //変換後サムネイル画像の最大縦幅
  compressFormat:     "JPEG",  //圧縮フォーマット（JPEG, PNG or WEBP）
  quality:            80,      //画質（0 ~ 100 圧縮しない場合は100をセットする。）
  outputType:         "bolb"   //出力タイプ（base64, bolb, file）
}


/**
 * お薬登録・更新・削除モーダル用のコンポーネント
 */
class ModalMedicine extends React.Component {
  constructor(props) {
    super(props);

    // URL情報から更新・削除対象のDocIdを取得
    let docId = '';
    if(window.location.search){
      docId = queryString.parse(window.location.search).data||null;
    }
    //if(this.props.location.search){
    //  docId = queryString.parse(this.props.location.search).data||null;
    //}

    this.state = {
      //DisplayName:     this.props.location.state.DisplayName,     // 表示対象者の名前
      //medicalRecordId: this.props.location.state.MedicalRecordId, // 症状カードID
      DisplayName:         props.DisplayName, // 表示対象者の名前
      medicalRecordId:     props.MedicalRecordId, // 症状カードID
      medicineId:          docId,                                     // お薬情報ID(更新・削除用)
      receivedDate:        this.getDateDisplayFormat(),               // 入力日時(自動入力)
      pharmacy:            '', //調剤薬局名
      payment:             '', //支払い金額(将来の支払額管理用)
      Note:                '', //メモ
      image_url:           '', //お薬情報画像URL
      thum_url:            '', //お薬情報サムネイル画像URL
      CreateTime:          '', //作成日時
      UpdateTime:          '', //更新日時
      image_change:        false, //画像変更有無
      medicine_image_list: [], //お薬画像格納用リスト
    }
    MedicineImageList.list = []
    MedicineImageList.UploadList = []
  }

  /**
   * 登録・削除の場合、登録済みデータをfirestoreから取得
   * (コンポーネントマウント時に実行)
   */
  async componentDidMount() {
    await this.ReadMedicine() //データベースからお薬情報を読み出す。
  }

  //データベースに登録されている、お薬情報を読み出す。
  async ReadMedicine() {
    console.log("ReadMedicine() start!!")
    var db = firebase.firestore()

    // firestoreから現在の登録データを取得し、モーダル表示画面に反映
    if(this.state.medicineId){
      await db.collection("MedicalRecords").doc(this.state.medicalRecordId).collection("Medicine").doc(this.state.medicineId)
      .get().then((doc)=>{
        if(doc.data()){
          var data = doc.data()
          var source_url = ''                                          //お薬登録用ソースファイル格納用
          var image_url  = ("image_url" in data) ? data.image_url : '' //お薬登録用サムネイル画像
          var thum_url   = ("thum_url"  in data) ? data.thum_url  : '' //お薬登録用画像
          MedicineImageList.list = []
          MedicineImageList.UploadList = []
          
          //旧型式のお薬画像データに画像登録がある場合は、お薬画像リストに読み込む（2022/03/28 追加 高橋）
          if (image_url !== '' && thum_url !== '') {
            //ブラウザ上で一時格納する、画像リストに旧形式で参照データを格納した画像を0番目の画像として格納する
            MedicineImageList.list.push({
              number:       0,                                                                //画像番号
              source_url:   source_url,                                                       //取り込み元画像（旧型式の経過データは空欄として扱う。）
              image_url:    image_url,                                                        //お薬画像パス
              thum_url:     thum_url,                                                         //お薬サムネイル画像パス
              CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //お薬情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //お薬情報更新時刻
              ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //お薬情報画像作成時刻
              image_change: false,                                                            //お薬登録画像の変更有無（true=変更あり、false=変更なし）
            })

            //データベースにアップロードする画像リストに画像を登録する
            MedicineImageList.UploadList.push({
              image_url:    image_url,                                                        //お薬画像パス
              thum_url:     thum_url,                                                         //お薬サムネイル画像パス
              CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //お薬情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //お薬情報更新時刻
              ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //お薬情報画像作成時刻
            })
          }

          if("images" in data){
            //新形式で保存された画像情報読み込みを行う
            data.images.forEach((image, index)=>{
              
              //旧形式で保存された画像情報を、新形式用画像情報読み込みしないようにする
              if(index !== '0' && image_url !== image.image_url && thum_url !== image.thum_url){
                MedicineImageList.list.push({
                  number:       index,                                                                //画像番号
                  source_url:   '',                                                                   //取り込み元画像（旧型式の経過データは空欄として扱う。）
                  image_url:    image.image_url,                                                      //経過画像パス
                  thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
                  CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
                  UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
                  ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
                  image_change: false,                                                                //経過登録画像の変更有無（true=変更あり、false=変更なし）
                })
                
                MedicineImageList.UploadList.push({
                  image_url:    image.image_url,                                                      //経過画像パス
                  thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
                  CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
                  UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
                  ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
                })
              }
            })
          }

          this.setState({
            receivedDate:        data.receivedDate        || '', //入力日時
            pharmacy:            data.pharmacy            || '', //調剤薬局名
            payment:             data.payment             || '', //支払い金額
            Note:                data.Note                || '', //メモ
            image_url:           data.image_url           || '', //お薬情報画像URL
            thum_url:            data.thum_url            || '', //お薬情報サムネイル画像URL
            CreateTime:          data.CreateTime.toDate() || '', //作成日時
            UpdateTime:          data.UpdateTime.toDate() || '', //更新日時
            medicine_image_list: MedicineImageList.list          //propsにお薬画像リストを反映する。
          })
        }
      })
    }
  }

  //モーダルを非表示状態に切り替える
  handleModalClose = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // 症状カード画面のモーダルを閉じる
    }
  }

  //モーダルを非表示状態に切り替え、症状カード画面で再読み込みする
  handleReloadModalClose = () => {
    if(typeof this.props.handleReloadModalClose == 'function'){
      this.props.handleReloadModalClose() // 症状カード画面のモーダルを閉じ、画面内データを再読み込みする。
    }
  }

  /**
   * お薬情報登録モーダルの各選択肢の変更を制御する関数
   * (TextFieldのname要素とstateの名前を対応付けて処理を共通化)
   * @param {*} e 各選択肢のTextField要素
   */
  //
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  //画像添付時の処理
  handleMedicineReferenceButton = () => {
    var btn_input_file = document.getElementById("file_affectedPartImage")
    btn_input_file.click()
  }

  //画像添付時の処理
  handleMedicineImageFileChange = async(e) =>{
    // TODO 縦長のスマホなどで撮影した画像が横向きになる問題あり
    // https://qiita.com/mo49/items/a3d61d97f1883ead333b
    console.log('handleMedicineImageFileChange(')
    var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    var files = e.target.files;
    var fileCount = files.length //読み込んだファイル数
    var num = MedicineImageList.list.length
    for (var i = 0; i < fileCount; i++ ){
      var source_url     = createObjectURL(files[i])
      var image_url      = await this.resizeImage(files[i])
      var image_thum_url = await this.resizeThumbnailImage(files[i])

      MedicineImageList.list.push({
        number:       (num + i),                       //画像番号
        source_url:   source_url,                      //取り込み元画像
        image_url:    image_url,                       //経過画像パス
        thum_url:     image_thum_url,                  //お薬サムネイル画像パス
        CreateTime:   new Date(),                      //お薬情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //お薬情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //お薬画像撮影日時
        image_change: true,                            //お薬登録画像の変更有無（true=変更あり、false=変更なし）
      })

      MedicineImageList.UploadList.push({
        image_url:    image_url,                       //お薬画像パス
        thum_url:     image_thum_url,                  //お薬サムネイル画像パス
        CreateTime:   new Date(),                      //お薬情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //お薬情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //お薬画像撮影日時
      })
    }
    
    this.setState({
      image_url:           '',                    // 経過画像をブラウザメモリ上で参照するオブジェクトURLで取得する。javascriptのセキュリティエラー対策で参照URLを登録しない
      image_change:        true,                  // 画像変更ありとして記録する
      medicine_image_list: MedicineImageList.list //propsにお薬画像リストを反映する。
    })
  }
 
  /**
   * 現在日付を[YYYYMMDDhhmmss]形式で返す関数
   */
  getDateIdFormat = () => {
    var now = new Date();
    return "" + now.getFullYear()
      + (now.getMonth()+1).toString().padStart(2, '0')
      + now.getDate().toString().padStart(2, '0')
      + now.getHours().toString().padStart(2, '0')
      + now.getMinutes().toString().padStart(2, '0')
      + now.getSeconds().toString().padStart(2, '0');
  }

  /**
   * 現在日付を[MM月DD日（曜日）hh時mm分]形式で返す関数
   */
  getDateDisplayFormat = () => {
    var now = new Date();
    return (now.getMonth()+1).toString().padStart(2, '0') + "月"
      + now.getDate().toString().padStart(2, '0') + "日（"
      + [ "日", "月", "火", "水", "木", "金", "土" ][now.getDay()] + "）"
      + now.getHours().toString().padStart(2, '0') + "時"
      + now.getMinutes().toString().padStart(2, '0') + "分"
  }

  /**
   * 削除ボタン押下時の処理
   */
  handleDeleteButtonClick = () => {
    // 削除実行の確認用ポップアップ
    if (!window.confirm("お薬情報を削除します")) {
      alert("削除をキャンセルしました");
      this.props.history.goBack();
      return;
    }
    this.deleteMedicine();
  }
  
  async deleteMedicine(){
    let db = firebase.firestore()

    //削除処理は前画面からデータを引き継いでいる場合のみ実行可能
    if(this.state.medicineId){
      await db.collection("MedicalRecords").doc(this.state.medicalRecordId)
      .collection("Medicine").doc(this.state.medicineId).delete()
      .then((result)=>{
        alert("削除に成功しました")
      })
      .catch(function(error) {
        alert("削除に失敗しました")
        console.log("お薬情報の削除に失敗しました ", error);
      });
    }
    //this.props.history.goBack();
    this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
  }

  /**
   * 登録ボタン押下時の処理
   */
  handleRegistButtonClick = async() => {
    await this.registMedicine()
  }
  
  async registMedicine(){
    let db = firebase.firestore()
    let doc_id = this.getDateIdFormat()

    const setData = {  // NULLチェックは右記のショートハンドで実施([条件式]?[true]:[false] → ||)
      receivedDate:    this.state.receivedDate || '',   //入力日時
      pharmacy:        this.state.pharmacy     || '',   //調剤薬局名
      payment:         this.state.payment      || '',   //支払い金額
      Note:            this.state.Note         || '',   //メモ
      //image_url:       this.state.image_url    || '',   //お薬情報画像URL
      UpdateTime:      firebase.firestore.FieldValue.serverTimestamp(), //作製時刻（firebaseのサーバー時刻）
      CreateTime:      this.state.CreateTime   || firebase.firestore.FieldValue.serverTimestamp(),  //作製時刻（firebaseのサーバー時刻）
    }

    if(this.state.medicineId){ //更新時
      await this.registMedicineImage(this.state.medicineId) //お薬画像登録実施
      setData.images = MedicineImageList.UploadList
      await db.collection("MedicalRecords").doc(this.state.medicalRecordId)
      .collection("Medicine").doc(this.state.medicineId).set(setData).then(()=>{
        doc_id = this.state.medicineId
        alert("お薬情報を登録しました")
        this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
      })
      .catch(function(error) {
        alert("更新に失敗しました")
        console.log("お薬情報の更新に失敗しました ", error);
      });
    }
    else{ //新規登録時
      await this.registMedicineImage(doc_id) //お薬画像登録実施
      setData.images = MedicineImageList.UploadList
      await db.collection("MedicalRecords").doc(this.state.medicalRecordId)
      .collection("Medicine").doc(doc_id).set(setData).then(()=>{
        alert("お薬情報を登録しました")
        this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
      })
      .catch(function(error) {
        alert("登録に失敗しました")
        console.log("お薬情報の登録に失敗しました ", error);
      });
    }

    // await this.registMedicineImage(doc_id)
    // .then(()=>{
    //   alert("お薬情報を登録しました")
    //   //this.props.history.goBack();
    //   this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
    // })
    // .catch(function(error){
    //   alert("登録に失敗しました")
    //   console.log("お薬情報の登録に失敗しました ", error);
    // })
  }

  //画像リサイズ処理（サムネイル画像）
  resizeThumbnailImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxThumbnailWidth,  //変換後サムネイル画像の最大横幅
      resizer.maxThumbnailHeight, //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  //画像リサイズ処理
  resizeImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxWidth,           //変換後サムネイル画像の最大横幅
      resizer.maxHeight,          //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  async registMedicineImage(doc_id){
    console.log('RegistMedicineImage() doc_id:' + doc_id)
    if(this.state.image_change === true){
      await Promise.all(MedicineImageList.list.map(async(images)=>{
        var ShootingTime = `${images.ShootingTime.getFullYear()}/${('0' + (images.ShootingTime.getMonth() + 1)).slice(-2)}/${('0' + images.ShootingTime.getDate()).slice(-2)} ` +
                           ` ${('0' + images.ShootingTime.getHours()).slice(-2)}:${('0' + images.ShootingTime.getMinutes()).slice(-2)}:${('0' + images.ShootingTime.getSeconds()).slice(-2)}`
        //サムネイル画像アップロード
        if(images.thum_url.includes('data:image/jpeg;base64,') && images.image_url.includes('data:image/jpeg;base64,')){ //base64形式フォーマットとなっていることを確認する
          var thum_data        = images.thum_url
          var data             = images.image_url
          var thum_fileName    = images.number + "-thum.jpg"                                                        // サムネイル画像のファイル名を設定
          var fileName         = images.number + ".jpg"                                                             // 画像のファイル名を設定
          var storageRef       = firebase.storage().ref('member_medicine')                                          // Storageの参照先ディレクトリを「member_medicine」に設定する。
          var thum_ImagesRef   = storageRef.child(this.state.medicalRecordId + '/' + doc_id + '/' + thum_fileName) // サムネイル画像のStorageの参照先ファイル名を設定する。
          var ImagesRef        = storageRef.child(this.state.medicalRecordId + '/' + doc_id + '/' + fileName)      // 画像のStorageの参照先ファイル名を設定する。
          var update_thum_url  = ''                                                                                 // サムネイル画像のStorageアクセス用URLを格納する
          var update_image_url = ''                                                                                 // 画像のStorageアクセス用URLを格納する

          // サムネイル画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await thum_ImagesRef.putString(thum_data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // 画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await ImagesRef.putString(data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // firebase Storageへ保存したサムネイル画像への参照URLを取得
          await thum_ImagesRef.getDownloadURL().then((url)=>{
            update_thum_url = url
            MedicineImageList.list[images.number].thum_url       = update_thum_url
            MedicineImageList.UploadList[images.number].thum_url = update_thum_url
          })
          
          // firebase Storageへ保存した画像への参照URLを取得
          await ImagesRef.getDownloadURL().then((url)=>{
            update_image_url = url
            MedicineImageList.list[images.number].image_url       = update_image_url
            MedicineImageList.UploadList[images.number].image_url = update_image_url
          })
        }
      }))
    }
  }
  
  render(){
    //お薬画像リスト確認用
    const MedicineImgView = []
    this.state.medicine_image_list.forEach((images)=>{
      MedicineImgView.push(
        <Grid item xs={12} key={'MedicineImage_' + images.number}>
          <img style={{width: '100%', margin: '5px 0'}} src={images.image_url} alt={images.CreateTime.toLocaleDateString()} />
        </Grid>
      )
    })

    return (
      <React.Fragment>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            お薬：お薬シールや処方箋を残しておきましょう
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {this.state.DisplayName}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth required id="inputReceivedDate" label="日時" name="receivedDate" value={this.state.receivedDate} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="inputPharmacy" label="病院名または薬局名" name="pharmacy" value={this.state.pharmacy} onChange={(e)=>this.handleChange(e)} />
          </Grid>

          {/* お薬画像登録 */}
          <Grid item xs={12}>お薬の写真をご登録ください</Grid>
          <div id="MedicineImg">{MedicineImgView}</div>
          <Grid item xs={12} style={{display: 'block'}}>
            {/* 読み出したお薬画像を表示する */}
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" color="primary" onClick={this.handleMedicineReferenceButton}>
              画像登録
            </Button>
            <input type="file" multiple id="file_affectedPartImage" accept=".jpg,.jpeg,.png,.webp,.svg" style={{display: 'none'}} onChange={(e)=>this.handleMedicineImageFileChange(e)} />
          </Grid>
          {/* 
          <Grid item xs={12}>
            <div>
              <p>お薬の写真をご登録ください</p>
              <Button fullWidth variant="outlined" color="primary" onClick={this.handleRegistFamilyImage} >
                画像登録
                <canvas id="cv_image" width="0" height="0" style={{position:'absolute', textAlign:'center'}} src={this.state.image_url} />
              </Button>
            </div>
            <div>
              <input type="file" id="file_affectedPartImage" accept=".jpg,.jpeg,.png" style={{display:'none'}} onChange={this.handleImageFileChange} />
            </div>
          </Grid>
          */}

          <Grid item xs={12}>
            <TextField fullWidth id="inputNote" label="メモ(症状・様子等)" name="Note" value={this.state.Note} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
        </Grid>
        <p> </p>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <div style={{display: (this.state.medicineId ? 'block' : 'none')}}> {/* お薬情報が登録済みの場合のみ削除ボタンを表示する */}
            <Button variant="outlined" color="secondary" onClick={this.handleDeleteButtonClick}>削除</Button>
          </div>
          <Button variant="outlined" color="primary" onClick={this.handleModalClose}>キャンセル</Button>
          <Button variant="outlined" color="primary" onClick={this.handleRegistButtonClick}>登録</Button>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withRouter(ModalMedicine)