import React    from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Button   from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import MenuIcon from '@material-ui/icons/Menu'
import Menu     from '@material-ui/core/Menu'
//import { makeStyles } from '@material-ui/core/styles';

import firebase from 'firebase/app';
import 'firebase/auth';

//const useStyles = makeStyles((theme) => ({
//  root: {
//    display: 'flex',
//  },
//  paper: {
//    marginRight: theme.spacing(2),
//  },
//}));

export default function FamikarHeaderMenu(props) {
  console.log("FamikarHeaderMenu.js uid["+props.uid+"]")

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleGoHome = () => {
    setOpen(false)
    if( props.FamilyID !== null && props.FamilyID !== ''){
        props.history.push('/FamilyHome/'+props.FamilyID)
    }
  }

  //const handleGoFamilyEdit = (e) => {
  //  console.log("FamikarHeaderMenu.js handleGoFamilyEdit uid["+props.uid+"]")
  //  setOpen(false);
  //  props.history.push({
  //    pathname: '/familyedit',
  //    state: {
  //      uid:props.uid
  //    }
  //  })
  //};

  const questionnaire = () => {
    let UserName  = ''
    let UserEmail = ''
    if (props.uid) {
      Promise.resolve().then(()=>{
        return new Promise((resolve,reject)=>{
          firebase.firestore().collection("Person").doc(props.uid).get().then((result)=>{
            UserName  = ( (result.data().FamilyName) ? result.data().FamilyName : '' ) + 
                        ( (result.data().FirstName ) ? result.data().FirstName  : '' )
            UserEmail = ( (result.data().Email     ) ? result.data().Email      : '' )
            resolve()
          })
          .catch(()=>{
            reject()
          })
        })
      })
      .then(()=>{
        console.log("Push URL: " + 
          'https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform' +
          '?entry.1703054983=' + UserName + '&entry.97022607=' + UserEmail
        )
        window.open(
          window.encodeURI('https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform' +
            '?entry.1703054983=' + UserName + '&entry.97022607=' + UserEmail
          ))
      })
      .catch(()=>{
        console.log("Get Person info error.")
        console.log("Push URL: https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform")
        window.open('https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform')
      })
    } else {
      console.log("questionnaire(): props.uid undefined")
      console.log("Push URL: https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform")
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSdcQkQQ9P2jaxxhSjcFqK5sXIHmpb-5fzieQTdMs0blwkjvuA/viewform')
    }
  }
  
  const logout = () =>{
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        firebase.auth().signOut()
        sessionStorage.removeItem('LoginReload') //ログインページのリロード管理用のローカルストレージを削除する。
        resolve()
      })
    })
    .then(()=>{
      props.history.push('/')
    })
    .then(()=>{
      sessionStorage.setItem('LoginReload', 'true'); //セッションストレージにLoginReload=trueを保存して、ログイン画面が繰り返し再読み込みされないようにする
      window.location.reload()
    })
  }

  const isHome = ( props.isHome === true ) ? true : false

  return (
    <div>
      <Button
        color="primary"
        ref={anchorRef}
        aria-label="more"
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu open={open} anchorEl={anchorRef.current} keepMounted onClose={handleClose} >
        <MenuList autoFocusItem={open} id="menu-list-grow">
          <MenuItem onClick={handleGoHome} disabled={isHome}>ホーム</MenuItem>
          {/*<MenuItem onClick={handleGoFamilyEdit}>家族の登録・編集</MenuItem>*/}
          <MenuItem onClick={questionnaire}>アンケート</MenuItem>
          <MenuItem onClick={logout}>ログアウト</MenuItem>
        </MenuList>
      </Menu>
    </div>
  )
}