/**
 * @brief 症状カード画面 
 * @author S.Yamada
 * @date 2020.11.3
 */

import React            from "react";
import { withRouter }   from "react-router";
import { ListGroup }    from 'react-bootstrap';
import Button           from '@material-ui/core/Button';
import Grid             from '@material-ui/core/Grid'
import Toolbar          from '@material-ui/core/Toolbar';
//import IconButton       from '@material-ui/core/IconButton';
//import Tooltip          from '@material-ui/core/Tooltip';
//import KeyReturnIcon    from '@material-ui/icons/KeyboardReturn';
import { withStyles }   from '@material-ui/core/styles';
import Modal            from '@material-ui/core/Modal';
import Fade             from '@material-ui/core/Fade';
import AppBar           from '@material-ui/core/AppBar';
import queryString      from 'query-string'
import FamikarHeader    from '../components/FamikarHeader';
import ModalInterview   from '../components/ModalInterview';
import ModalFollowUp    from '../components/ModalFollowUp';
import ModalDiagnosis   from '../components/ModalDiagnosis';
import ModalMedicine    from '../components/ModalMedicine';
import VariousRecord    from "../components/VariousRecord";
import ModalFamilyView  from "../components/ModalFamilyView";
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase         from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import DiagnosisImg     from '../img/doctor.svg'
import FollowupImg      from '../img/chart_line.svg'
import MedicineImg      from '../img/medicines.svg'

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  container: {
    fontSize: 10,
  },
  float:{
    position: 'fixed',
    top:      'auto',
    left:     'auto',
    bottom:   50,
    right:    50,
    padding:  5,
    width:    50,
    height:   50
  },
  modal: {
    maxHeight: '100%',
    overflowY: 'auto',
  },
  paper: {
    position: 'relative',
    display: 'block',
    top: '0',
    left: '50%',
    outline: 'none',
    transform: 'translate(calc(-50% - 0.5rem), 0)',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(0,0,0,.2)',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 2, 3),
    margin: '1.75rem .5rem',
    maxWidth: '460px',
    width: 'calc(100% - 1rem)',
    borderRadius: '.3rem',
    overflowY: 'visible',
  },
});

//経過情報一覧
const FollowupList = {
  list:[
    //{
    //  id:               "(文字列)",   // ドキュメントID
    //  Appetite:         "(文字列)",   // 食欲 「普段通り食べれている」、「普段の半分くらい食べれている」、「全く食べれていない」
    //  BodyTemperature:  "(数値)",     // 体温
    //  Moisture:         "(文字列)",   // 水分摂取 「普段通り取れている」、「普段の半分くらい取れている」、「全く取れていない」
    //  Mood:             "(文字列)",   // 機嫌 「機嫌が良い」、「機嫌がやや悪い」、「機嫌が悪い」
    //  RunnyNose:        "(文字列)",   // 鼻水の有無 「鼻水なし」、「鼻水がやや出ている」、「鼻水が出ている」
    //  Headache:         "(文字列)",   // 頭痛の有無 「頭痛なし」、「頭痛がやや出ている」、「頭痛が出ている」
    //  Diarrhea:         "(文字列)",   // 下痢の有無 「下痢なし」、「下痢がやや出ている」、「下痢が出ている」
    //  Vomit:            "(文字列)",   // 嘔吐の有無 「嘔吐なし」、「嘔吐がやや出ている」、「嘔吐が出ている」
    //  Phlegm:           "(文字列)",   // 痰の有無 「痰なし」、「痰がやや出ている」、「痰が出ている」
    //  BreathSounds:     "(文字列)",   // 呼吸音の有無 「呼吸音の異常なし」、「呼吸音にやや異常が出ている」、「呼吸音にあり」
    //  Stomachache:      "(文字列)",   // 腹痛の有無 「腹痛なし」、「腹痛がやや出ている」、「腹痛がある」
    //  Eruption:         "(文字列)",   // 発疹の有無 「発疹なし」、「発疹がやや出ている」、「発疹がある」
    //  Spasm:            "(文字列)",   // けいれんの有無 「けいれん なし」、「けいれん がやや出ている」、「けいれんがある」
    //  Note:             "(文字列)",   // メモ
    //  image_url:        "(文字列)",   // 経過記録画像URL
    //  thum_url:         "(文字列)",   // 経過記録サムネイル画像URL
    //  FollowUpTime:     "(日時)",     // 経過記録時刻
    //  UpdateTime:       "(日時)",     // 更新時刻
    //  CreateTime:       "(日時)",     // 作成時刻
    //  DeleteFlag:       "(ブール値)", // 削除フラグ
    //}
  ]
}

//診察結果一覧
const MedicalResultList = {
  list:[
    //{
    //  id:               "(文字列)",   // ドキュメントID
    //  ConsultationTime: "(日時)",     // 受診時刻
    //  HospitalName:     "(文字列)",   // 病院名
    //  MedicalSpecialty: "(文字列)",   // 診療科目
    //  DoctorName:       "(文字列)",   // 医者の名前
    //  Diagnosis:        "(文字列)",   // 診断結果
    //  Instructions:     "(文字列)",   // 医者の指示
    //  Note:             "(文字列)",   // メモ
    //  UpdateTime:       "(日時)",     // 更新時刻
    //  CreateTime:       "(日時)",     // 作製時刻
    //  DeleteFlag:       "(ブール値)", // 削除フラグ
    //}
  ]
}

//お薬情報一覧
const MedicineList = {
  list:[
    //{
    //  id:               "(文字列)",   // ドキュメントID
    //  title:            "(文字列)",   // 画像のタイトル
    //  alt:              "(文字列)",   // 画像代替テキスト
    //  text:             "(文字列)",   // 詳細説明テキスト
    //  image_url:        "(文字列)",   // 経過記録画像URL
    //  thum_url:         "(文字列)",   // 経過記録サムネイル画像URL
    //  received_date:    "(日時)",     // お薬を貰った日
    //  pharmacy:         "(文字列)",   // 調剤薬局名
    //  payment:          "(文字列)",   // 支払い金額
    //  Note:             "(文字列)",   // メモ
    //  UpdateTime:       "(日時)",     // 更新時刻
    //  CreateTime:       "(日時)",     // 作成時刻
    //}
  ]
}

/**
 * @brief 症状カード画面クラス
 */
class SymptomCard extends React.Component {
  constructor(props) {
  
    super(props);
    
    // 症状カード画面で展開されるモーダルの種類を制御する処理
    let upModalShows = {
      'Interview':  false,
      'FollowUp':   false,
      'Diagnosis':  false,
      'Medicine':   false,
      'FamilyView': false,
    }
    if(this.props.location.search){
      const view = queryString.parse(this.props.location.search).view||null;
      upModalShows[view] = true;
    }

    let MedicalRecordID = '';
    if (this.props.location.state !== undefined && this.props.location.state.MedicalRecordId) {
      MedicalRecordID = this.props.location.state.MedicalRecordId
      //console.log(`this.props.location.state.MedicalRecordId[${this.props.location.state.MedicalRecordId}]`);
    } else if (MedicalRecordID === '' && this.props.match.params !== undefined && this.props.match.params.MedicalRecordID) {
      MedicalRecordID = this.props.match.params.MedicalRecordID
      //console.log(`this.props.match.params.MedicalRecordID[${this.props.match.params.MedicalRecordID}]`);
    }

    this.state = {
      isLogin:           false,
      username:          '',
      diseaseName:       "diseaseName",                             // 診断された病名(現在渡される病名がないため仮置き)
      uid:               (this.props.location.state === undefined) ? '' : this.props.location.state.uid,             //ログインユーザーのuid
      medicalrecordid:   (MedicalRecordID           === ''       ) ? '' : MedicalRecordID,                           //診療情報のレコードID
      FamilyID:          (this.props.location.state === undefined) ? '' : this.props.location.state.FamilyID,        //診療情報登録対象の家族ID
      MemberID:          (this.props.location.state === undefined) ? '' : this.props.location.state.MemberID,        //診療情報登録対象のメンバーID
      DisplayName:       (this.props.location.state === undefined) ? '' : this.props.location.state.DisplayName,     //診療情報登録対象の表示名
      member_url:        (this.props.location.state === undefined) ? '' : this.props.location.state.member_url,      //診療情報登録対象のアイコン画像パス
      Diagnosis:         (this.props.location.state === undefined) ? '' : this.props.location.state.Diagnosis,       //診断結果
      RecordTitle:       (this.props.location.state !== undefined && this.props.location.state.Title) ? this.props.location.state.Title : '', //診療情報のタイトル
      record:            (this.props.location.state === undefined) ? '' : this.props.location.state.record,
      hospitalName:      (this.props.location.state === undefined) ? '' : this.props.location.state.HospitalName,    // 病院名
      //CreateTime:        (this.props.location.state === undefined) ? '' : this.props.location.state.CreateTime,      // 作成日時
      CreateTime:        new Date(),      // 作成日時
      UpdateTime:        new Date(),      // 更新日時
      First_FollowUp:    '',              // 初回経過情報を格納したドキュメントパス
      Fever_Degrees:     0,               // 発熱時の体温
      Note:              '',              // フリーメモ
      modalShows:        upModalShows, //モーダル展開状況
      followupList:      [],                                        // 経過情報リスト
      medicalResultList: [],                                        // 診察結果リスト
      medicineList:      [],                                        // お薬情報リスト
    }
    //this.componentDidMount = this.componentDidMount.bind(this);
    //console.log(`SymptomCard modalShows[${JSON.stringify(this.state.modalShows,null,'\t')}]`);
    //console.log(`SymptomCard props[${this.props}]`);
    //console.log(`SymptomCard props[${JSON.stringify(this.props,null,'\t')}]`);
    //console.log(`SymptomCard uid[${this.state.uid}]`);
    //console.log(`SymptomCard FamilyID[${this.state.FamilyID}]`);
    //console.log(`SymptomCard MemberID[${this.state.MemberID}]`);
    //console.log(`SymptomCard medicalrecordid[${this.state.medicalrecordid}]`);
    //console.log("SymptomCard member_url["+this.state.member_url+"]");
    //console.log(`SymptomCard Diagnosis[${this.state.Diagnosis}]`);
    //console.log(`SymptomCard diseaseName[${this.state.diseaseName}]`);
    //console.log(`SymptomCard DisplayName[${this.state.DisplayName}]`);
    //console.log(`SymptomCard record[${JSON.stringify(this.state.record,null,'\t')}]`);
  }
  
  handleRegistButtonClick = (e) =>{
    //this.setState({modalShow:true})
  }

  /**
   * @brief Firebaseからの診療情報の取得
   */
  componentDidMount() {
    console.log("componentDidMount: this.state.uid["+this.state.uid+"]")
    window.addEventListener('beforeunload', ()=>{
      console.log(document.location)
      //Promise.resolve().then(setTimeout(10000))
    })
    
    window.addEventListener('popstate', ()=>{
      if (document.location.pathname.split('/')[1] === "SymptomCard") { //ブラウザの戻る・進むボタンでの移動時にページの再読み込み実施
        window.location.reload();
      } 
    })

    const db = firebase.firestore();
    // 経過・診療・お薬情報を取ってくる
    if(this.state.followupList.length === 0 || this.state.medicalResultList.length === 0 || this.state.medicineList.length === 0) {
      this.getMedicalRecord(db)
    }
    console.log("componentDidMount: finish getMedicalRecord");
  }
  
  componentWillUnmount() {
    window.removeEventListener('popstate', ()=>{})     //症状カード画面から離れる場合は、ブラウザの戻るボタンでの移動時のページ再読み込みは中止する
    window.removeEventListener('beforeunload', ()=>{}) //画面を抜ける際のイベント待機を終了する
  }

  /**
   * 指定の症状の経過情報・診察結果・お薬情報の取得
   * @param {in} db 
   */
  async getMedicalRecord(db) {
    console.log('getMedicalRecord() start!!')
    await this.getFollowupList(db) //経過情報取得
  }
  
  /**
   * 指定の経過情報取得
   * @param {in} db
   */
  async getFollowupList(db){
    // 経過情報の取得
    await db.collection("MedicalRecords").doc(this.state.medicalrecordid).collection("datas")
    .get().then(async(result) => {
      FollowupList.list = []
      console.log("SymptomCard.js get followupList");

      //  指定の症状の経過情報を保存
      result.forEach((doc)=>{
        const followup = doc.data();
        followup.id = doc.id //ドキュメントID追加
        FollowupList.list.push({
          id:              followup.id,              // ドキュメントID
          CreateTime:      followup.CreateTime,      // 作成日時
          UpdateTime:      followup.UpdateTime,      // 更新日時
          Note:            followup.Note,            // メモ
          BodyTemperature: followup.BodyTemperature, // 体温
          thum_url:        followup.thum_url,        // 経過記録サムネイル画像URL
          record:          followup.record,          // 診療情報
        })
        //console.log(`SymptomCard.js followup = ${JSON.stringify(followup,null,'\t')}`);
      })
      console.log(`SymptomCard.js FollowupList = ${JSON.stringify(FollowupList.list,null,'\t')}`);
      await this.getMedicalResultList(db) //診察結果取得
    }).catch((error) => {
      console.log("Error getting followup list: ", error);
    });
  }

  /**
   * 指定の診察結果取得
   * @param {in} db
   */
  async getMedicalResultList(db){
    // 診察結果の取得
    await db.collection("MedicalRecords").doc(this.state.medicalrecordid).collection("MedicalResults")
    .get().then(async(result) => {
      //console.log("SymptomCard.js get medicalResults");
      MedicalResultList.list = []
      //  指定の症状の診察結果を保存
      result.forEach((doc) => {
        const medicalResult = doc.data();
        medicalResult.id = doc.id //ドキュメントID追加
        MedicalResultList.list.push({
          id:               medicalResult.id,               // ドキュメントID
          Diagnosis:        medicalResult.Diagnosis,        // 診断結果
          Instructions:     medicalResult.Instructions,     // 医者の指示
          CreateTime:       medicalResult.CreateTime,       // 作成日時
          UpdateTime:       medicalResult.UpdateTime,       // 更新日時
          HospitalName:     medicalResult.HospitalName,     // 病院名
          MedicalSpecialty: medicalResult.MedicalSpecialty, // 診療科目
          Note:             medicalResult.Note,             // メモ
        })
        //console.log(`SymptomCard.js medicalResultList = ${JSON.stringify(medicalResult,null,'\t')}`);
      })
      //console.log(`SymptomCard.js medicalResultList = ${JSON.stringify(MedicalResultList.list,null,'\t')}`);
      await this.getMedicineList(db) //お薬情報取得
    }).catch((error) => {
      console.log("Error getting medicalResult list: ", error);
    });
  }
  
  /**
   * 指定の診察結果取得
   * @param {in} db
   */
  async getMedicineList(db){
    let uid            = '' // ログインユーザーuidを格納する
    let FamilyID       = '' // 家族ID
    let MemberID       = '' // メンバーID
    let DisplayName    = '' // メンバー表示名
    let member_url     = '' // メンバー画像
    let Diagnosis      = '' // 診断結果
    let RecordTitle    = '' // 診療情報のタイトル
    let hospitalName   = '' // 医療機関名
    let CreateTime     = '' // 作成日時
    let UpdateTime     = '' // 作成日時
    let Fever_Degrees  = 0  // 発熱時の体温
    let Note           = '' // フリーメモ
    let First_FollowUp = '' // 初回経過情報を格納したドキュメントパス
    await Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        // お薬情報の取得
        db.collection("MedicalRecords").doc(this.state.medicalrecordid).collection("Medicine")
        .get().then((result) => {
          //console.log("SymptomCard.js get Medicine");
          MedicineList.list = []
          result.forEach((doc) => {
            const medicine = doc.data();
            medicine.id = doc.id //ドキュメントIDを追加
            MedicineList.list.push(medicine);
            //console.log(`SymptomCard.js medicineList = ${JSON.stringify(medicine,null,'\t')}`);
            //console.log(`SymptomCard.js medicine : ${medicine}`);
            //console.log(`SymptomCard.js medicine doc.id : ${doc.id}`);
            //console.log(`SymptomCard.js medicine medicine.id : ${medicine.id}`);
            //console.log(`SymptomCard.js medicine.image_url : ${medicine.images[0].image_url}`);
          })
          resolve()
        })
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.uid === '') {
          firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
            if (user) {
              uid = user.uid
              resolve()
            } else {
              this.props.history.push({pathname: '/'}) //ログインユーザー情報が取得できない場合は、アプリトップページに移動する
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.FamilyID === '') {
          db.collection("Person").doc(uid).get().then((Person_result)=>{ //ログインユーザー情報から、家族IDの情報を取得する
            FamilyID = Person_result.data().FamilyID[0].id // 1件目の家族IDを取得する。
            resolve()
          })
        } else {
          resolve()
        }
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.MemberID === ''){
          if (this.state.medicalrecordid !== '') { // 診療情報のレコードIDが存在することを確認する。
            MemberID = this.state.medicalrecordid.split('_')[0] // 診療情報のレコードIDから、「_」の前のメンバーIDを取り出す。
            resolve()
          } else {
            this.props.history.push({pathname: '/'}) //診療情報のレコードIDが無い場合は、アプリトップページに移動する
            resolve()
          }
        } else {
          resolve()
        }
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.DisplayName === ''||this.state.member_url === '') {
          db.collection("Family").doc(FamilyID).collection("Member").doc(MemberID).get().then((Member_result)=>{
            DisplayName = ((Member_result.data().DisplayName) ? Member_result.data().DisplayName : '')
            member_url  = ((Member_result.data().image_url  ) ? Member_result.data().image_url   : '')
            resolve()
          })
        } else {
          resolve()
        }
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        db.collection("MedicalRecords").doc(this.state.medicalrecordid).get().then((Record_result)=>{
          Diagnosis      = ((Record_result.data().Diagnosis                   ) ? Record_result.data().Diagnosis                    : '')
          RecordTitle    = ((Record_result.data().Title                       ) ? Record_result.data().Title                        : '')
          hospitalName   = ((Record_result.data().HospitalName                ) ? Record_result.data().HospitalName                 : '')
          CreateTime     = ((Record_result.data().CreateTime                  ) ? Record_result.data().CreateTime.toDate()          : '')
          UpdateTime     = ((Record_result.data().UpdateTime                  ) ? Record_result.data().UpdateTime.toDate()          : '')
          First_FollowUp = ((Record_result.data().First_FollowUp              ) ? Record_result.data().First_FollowUp.path          : '')
          Fever_Degrees  = ((Record_result.data().InterviewSheet.Fever_Degrees) ? Record_result.data().InterviewSheet.Fever_Degrees : '')
          Note           = ((Record_result.data().InterviewSheet.Note         ) ? Record_result.data().InterviewSheet.Note          : '')
          //console.log('getMedicineList() get Fever_Degrees:' + Fever_Degrees)
          resolve()
        })
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        console.log('getMedicineList() 1 Fever_Degrees:' + Fever_Degrees + ', UpdateTime: ' + UpdateTime)
        this.setState({
          uid:               (this.state.uid         !== '') ? this.state.uid         : uid,                    // ログインユーザーID
          FamilyID:          (this.state.FamilyID    !== '') ? this.state.FamilyID    : FamilyID,               // 家族ID
          MemberID:          (this.state.MemberID    !== '') ? this.state.MemberID    : MemberID,               // メンバーID
          DisplayName:       (this.state.DisplayName !== '') ? this.state.DisplayName : DisplayName,            // メンバー表示名
          member_url:        (this.state.member_url  !== '') ? this.state.member_url  : member_url,             // メンバーアイコン画像パス
          Diagnosis:         Diagnosis,              // 診断結果
          RecordTitle:       RecordTitle,            // 診療情報のタイトル
          hospitalName:      hospitalName,           // 医療機関名
          CreateTime:        CreateTime,             // 作成日時
          UpdateTime:        UpdateTime,             // 更新日時
          First_FollowUp:    First_FollowUp,         // 初回経過情報を格納したドキュメントパス
          Fever_Degrees:     Fever_Degrees,          // 発熱時の体温
          Note:              Note,                   // フリーメモ
          followupList:      FollowupList.list,      // 経過情報リスト
          medicalResultList: MedicalResultList.list, // 診察結果リスト
          medicineList:      MedicineList.list,      // お薬情報リスト
        })
        resolve()
      })
    })
    .catch((error) => {
      console.log("Error getting medicine list: ", error);
    });
    //console.log(`SymptomCard.js medicineList = ${JSON.stringify(MedicineList.list,null,'\t')}`);
    //console.log(`SymptomCard.js followupList = ${followupList}`);
    //console.log(`SymptomCard.js medicalResultList = ${medicalResultList}`);
    //console.log(`SymptomCard.js medicineList = ${medicineList}`);
    //this.setState({followupList: followupList});
    //this.setState({medicalResultList: medicalResultList});
    //this.setState({medicineList: medicineList});
    
    //Storage内の経過情報画像を格納したフォルダ内のファイルとフォルダの一覧取得
    //firebase.storage().ref().child('member_data_image').listAll().then(function(res) {
    //  res.prefixes.forEach(function(folderRef) {
    //    // All the prefixes under listRef.
    //    console.log('folderRef: ' + folderRef)
    //  });
    //  res.items.forEach(function(itemRef) {
    //    // All the items under listRef.
    //    console.log('itemRef: ' + itemRef)
    //  });
    //})
  }

  /**
   * @brief 家族ホーム画面への移動 
   */ 
  handleViewFamilyHome = () => {
    this.props.history.push({ pathname: '/FamilyHome/' + this.state.FamilyID })
  }
  
  /** 
   * @brief 個人ホーム画面への移動
   */ 
  handleViewPersonalHome = () => {
    this.props.history.push({ pathname: '/PersonalHome/' + this.state.MemberID })
  }

  /* ***************************************************************
   * 症状カード画面から各種情報の登録,更新,削除モーダルを展開する関数群.
   * 
   * 挙動的にはsymptomcard画面にリダイレクトし,
   * 遷移時のURLに付与した「viwe=XXX」の情報によって展開するモーダルを制御
   * *************************************************************** */

  /**
   * 新規登録用のモーダルを展開
   * @param {*} view "FollowUp"：経過情報、"Diagnosis"：診療結果、"Medicine"：お薬情報
   */
  handleNewModalOpen = (view) =>{
    switch(view) {
      case "FollowUp":
        //window.history.pushState(null, "経過情報 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        window.history.replaceState(null, "経過情報 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        document.title = "経過情報新規登録 - ふぁみかるくん"
        //sessionStorage.setItem('Previous_page', "/SymptomCard/"+this.state.medicalrecordid)
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   true,
            Diagnosis:  false,
            Medicine:   false,
            FamilyView: false
          }
        })
        break

      case "Diagnosis":
        //window.history.pushState(null, "診察結果 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        window.history.replaceState(null, "診察結果 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        document.title = "診察結果 新規登録 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   false,
            Diagnosis:  true,
            Medicine:   false,
            FamilyView: false
          }
        })
        break

      case "Medicine":
        //window.history.pushState(null, "お薬情報 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        window.history.replaceState(null, "お薬情報 新規登録 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        document.title = "お薬情報 新規登録 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   false,
            Diagnosis:  false,
            Medicine:   true,
            FamilyView: false
          }
        })
        break

      case "FamilyView":
        //window.history.pushState(null, `${this.state.DisplayName}の情報 - ふぁみかるくん`, "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        window.history.replaceState(null, `${this.state.DisplayName}の情報 - ふぁみかるくん`, "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        document.title = `${this.state.DisplayName}の情報 - ふぁみかるくん`
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   false,
            Diagnosis:  false,
            Medicine:   false,
            FamilyView: true
          }
        })
        break

      default:
        break
    }
  }

  /**
   * 更新・削除用のモーダルを展開
   * @param {*} view "Interview"：問診情報、"FollowUp"：経過情報、"Diagnosis"：診療結果、"Medicine"：お薬情報
   * @param {*} docId 更新/削除対象データのDocID
   */
  handleUpdateModalOpen = (view,docId) =>{
    switch(view) {
      case "Interview":
        //window.history.pushState(null, "問診情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        window.history.replaceState(null, "問診情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view)
        document.title = "問診情報 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  true,
            FollowUp:   false,
            Diagnosis:  false,
            Medicine:   false,
            FamilyView: false
          }
        })
        break

      case "FollowUp":
        //window.history.pushState(null, "経過情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        window.history.replaceState(null, "経過情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        document.title = "経過情報 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   true,
            Diagnosis:  false,
            Medicine:   false,
            FamilyView: false
          }
        })
        break

      case "Diagnosis":
        //window.history.pushState(null, "診察結果 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        window.history.replaceState(null, "診察結果 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        document.title = "診察結果 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   false,
            Diagnosis:  true,
            Medicine:   false,
            FamilyView: false
          }
        })
        break

      case "Medicine":
        //window.history.pushState(null, "お薬情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        window.history.replaceState(null, "お薬情報 - ふぁみかるくん", "/SymptomCard/"+this.state.medicalrecordid+'?view='+view+'&data='+docId)
        document.title = "お薬情報 - ふぁみかるくん"
        this.setState({
          modalShows: {
            Interview:  false,
            FollowUp:   false,
            Diagnosis:  false,
            Medicine:   true,
            FamilyView: false
          }
        })
        break

      default:
        break
    }
  }

  /**
   * 各種モーダルを閉じる
   */
  handleModalClose = () => {
    //window.history.pushState(null, `ふぁみかるくん`, "/SymptomCard/"+this.state.medicalrecordid)
    window.history.replaceState(null, `ふぁみかるくん`, "/SymptomCard/"+this.state.medicalrecordid)
    document.title = `ふぁみかるくん`
    this.setState({
      modalShows: { 
        Interview:  false,
        FollowUp:   false,
        Diagnosis:  false,
        Medicine:   false,
        FamilyView: false
      }
    })
  }

  /**
   * モーダル画面を閉じて、画面データをリロードする。
   */
  handleReloadModalClose = async() => {
    console.log('handleReloadModalClose(): start')
    window.history.pushState(null, `ふぁみかるくん`, "/SymptomCard/"+this.state.medicalrecordid)
    document.title = `ふぁみかるくん`
    FollowupList.list = []
    MedicalResultList.list = []
    MedicineList.list = []
    this.setState({
      modalShows: { 
        Interview:  false,
        FollowUp:   false,
        Diagnosis:  false,
        Medicine:   false,
        FamilyView: false
      },
      followupList:      FollowupList.list,      // 経過情報リストを一度空の状態にする
      medicalResultList: MedicalResultList.list, // 診察結果リストを一度空の状態にする
      medicineList:      MedicineList.list,      // お薬情報リストを一度空の状態にする
    })
    await this.getMedicalRecord(firebase.firestore())
    console.log('handleReloadModalClose(): finish')
  }

  /**
   * 診療情報に削除フラグを付与して、個人ホーム画面に移動する
   */
  handleDeleteButtonClick = () => {
    let recode_Delete_check = window.confirm('受診・症状の記録を全て削除しますか？')
    if(recode_Delete_check) {
      firebase.firestore().collection("MedicalRecords").doc(this.state.medicalrecordid).update({
        UpdateTime: new Date(), //診療情報更新日時
        DeleteFlag: true,       //削除フラグを付与
      })
      .then(()=>{
        alert('受診・症状の記録を削除しました。')
        this.handleViewPersonalHome() //個人ホーム画面へ移動する。
      })
      .catch((error)=>{
        alert('受診・症状の記録削除に失敗しました。\n' + error)
      })
    } else {
      alert('削除をキャンセルしました。')
    }
  }

  /* モーダル制御用処理 ここまで *******************************************/

  render(){
    //console.log('render() start!!')
    const medicalRecordList = [];  // 症状カード画面に表示する診療情報一覧
    //console.log(`SymptomCard.js this.state.followupList = ${JSON.stringify(this.state.followupList,null,'\t')}`);
    this.state.followupList.forEach((followup) => {
      //console.log(`SymptomCard.js followup: ${followup}`);
      let CreateTime           = new Date(followup.CreateTime.toDate())
      let followup_CreateTime  = CreateTime.getFullYear() + ('0' + (CreateTime.getMonth() + 1)).slice(-2) + ('0' + CreateTime.getDate()).slice(-2) +
                                 ('0' + CreateTime.getHours()).slice(-2) + ('0' + CreateTime.getMinutes()).slice(-2) + ('0' + CreateTime.getSeconds()).slice(-2)
      let followup_CreateTime2 = `${CreateTime.getFullYear()}/${('0' + (CreateTime.getMonth() + 1)).slice(-2)}/${('0' + CreateTime.getDate()).slice(-2)} ` +
                                 ` (${["日", "月", "火", "水", "木", "金", "土"][CreateTime.getDay()]}) ${('0' + CreateTime.getHours()).slice(-2)}:${('0' + CreateTime.getMinutes()).slice(-2)}`
      let followupID           = (followup_CreateTime === followup.id) ? followup.id : followup_CreateTime
      //console.log("followup.id: " + followup.id + ", followupID: " + followupID)
      if (followup.id !== this.state.First_FollowUp.split('/')[3]) { //問診情報登録時に作製される初回の経過情報を症状カード画面で非表示にする。
        medicalRecordList.push(
          <ListGroup.Item as="li" style={{padding: '0 0', margin: '0.25rem 0'}} className="justify-md-left align-left" key={followupID}>
            <VariousRecord 
              symptomClass          = "FollowUp"
              registeredDate        = {followup_CreateTime2}
              symptomContent        = {'熱：' + Number(followup.BodyTemperature).toFixed(1) + "℃"}
              symptomNote           = {'メモ：' + followup.Note}
              MedicalRecordID       = {this.state.medicalrecordid}
              uid                   = {this.state.uid}
              FamilyID              = {this.state.FamilyID}
              MemberID              = {this.state.MemberID}
              member_url            = {this.state.member_url}
              thum_url              = {followup.thum_url}
              record                = {this.state.record}
              DocID                 = {followup.id}
              handleUpdateModalOpen = {this.handleUpdateModalOpen}
            />
          </ListGroup.Item>
        )
      }
    })
    //console.log(`add followupList to medicalRecordList : ${medicalRecordList}`);
    //console.dir(medicalRecordList)
    
    // 診断結果を診療情報一覧に追加
    //console.log(`SymptomCard.js this.state.medicalResultList = ${JSON.stringify(this.state.medicalResultList,null,'\t')}`);
    this.state.medicalResultList.forEach((medicalResult) => {
      let CreateTime                = new Date(medicalResult.CreateTime.toDate())
      let medicalResult_CreateTime  = CreateTime.getFullYear() + ('0' + (CreateTime.getMonth() + 1)).slice(-2) + ('0' + CreateTime.getDate()).slice(-2) +
                                      ('0' + CreateTime.getHours()).slice(-2) + ('0' + CreateTime.getMinutes()).slice(-2) + ('0' + CreateTime.getSeconds()).slice(-2)
      let medicalResult_CreateTime2 = `${CreateTime.getFullYear()}/${('0' + (CreateTime.getMonth() + 1)).slice(-2)}/${('0' + CreateTime.getDate()).slice(-2)} ` +
                                      ` (${["日", "月", "火", "水", "木", "金", "土"][CreateTime.getDay()]}) ${('0' + CreateTime.getHours()).slice(-2)}:${('0' + CreateTime.getMinutes()).slice(-2)}`
      let medicalResultID           = (medicalResult_CreateTime === medicalResult.id) ? medicalResult.id : medicalResult_CreateTime
      //console.log("medicalResult.id: " + medicalResult.id + ", medicalResultID: " + medicalResultID)
      medicalRecordList.push(
        <ListGroup.Item as="li" style={{padding: '0 0', margin: '0.25rem 0'}} className="justify-md-left align-left" key={medicalResultID}>
          <VariousRecord 
            symptomClass          = "Diagnosis"
            registeredDate        = {medicalResult_CreateTime2}
            /* symptomContent        = {medicalResult.Diagnosis} */
            symptomContent        = {'病院名：' + medicalResult.HospitalName + ' ' + medicalResult.MedicalSpecialty}
            /* symptomNote           = {'メモ：' + medicalResult.Instructions} */ /* 先生からの指示を出力 */
            symptomNote           = {'メモ：' + medicalResult.Note}
            MedicalRecordID       = {this.state.medicalrecordid}
            uid                   = {this.state.uid}
            FamilyID              = {this.state.FamilyID}
            MemberID              = {this.state.MemberID}
            member_url            = {this.state.member_url}
            thum_url              = {medicalResult.thum_url}
            record                = {this.state.record}
            DocID                 = {medicalResult.id}
            handleUpdateModalOpen = {this.handleUpdateModalOpen}
          />
        </ListGroup.Item>
      )
    })
    //console.log(`add medicalResult to medicalRecordList : ${medicalRecordList}`);

    // お薬情報を診療情報一覧に追加
    //console.log(`SymptomCard.js this.state.medicineList = ${JSON.stringify(this.state.medicineList,null,'\t')}`);
    this.state.medicineList.forEach((medicine) => {
      let CreateTime           = new Date(medicine.CreateTime.toDate())
      let medicine_CreateTime  = CreateTime.getFullYear() + ('0' + (CreateTime.getMonth() + 1)).slice(-2) + ('0' + CreateTime.getDate()).slice(-2) +
                                 ('0' + CreateTime.getHours()).slice(-2) + ('0' + CreateTime.getMinutes()).slice(-2) + ('0' + CreateTime.getSeconds()).slice(-2)
      let medicine_CreateTime2 = `${CreateTime.getFullYear()}/${('0' + (CreateTime.getMonth() + 1)).slice(-2)}/${('0' + CreateTime.getDate()).slice(-2)} ` +
                                 ` (${["日", "月", "火", "水", "木", "金", "土"][CreateTime.getDay()]}) ${('0' + CreateTime.getHours()).slice(-2)}:${('0' + CreateTime.getMinutes()).slice(-2)}`
      let medicineID           = (medicine_CreateTime === medicine.id) ? medicine.id : medicine_CreateTime
      //console.log("medicine.id: " + medicine.id + ", medicineID: " + medicineID)
      medicalRecordList.push(
        <ListGroup.Item as="li" style={{padding: '0 0', margin: '0.25rem 0'}} className="justify-md-left align-left" key={medicineID} >
          <VariousRecord 
            symptomClass          = "Medicine"
            registeredDate        = {medicine_CreateTime2}
            /* symptomContent        = {"医療機関/薬局："+medicine.pharmacy} */
            symptomContent        = {'メモ：' + medicine.Note}
            symptomNote           = {'　'}
            MedicalRecordID       = {this.state.medicalrecordid}
            uid                   = {this.state.uid}
            FamilyID              = {this.state.FamilyID}
            MemberID              = {this.state.MemberID}
            member_url            = {this.state.member_url}
            thum_url              = {medicine.thum_url}
            record                = {this.state.record}
            DocID                 = {medicine.id}
            handleUpdateModalOpen = {this.handleUpdateModalOpen}
          />
        </ListGroup.Item>
      )
    })
    //console.log(`add medicine to medicalRecordList : ${medicalRecordList}`);
    
    // 診療情報一覧をCreateTimeでソート（最新のデータが先頭にくる）
    medicalRecordList.sort((a,b) => {
      if( a.key > b.key ){
        return -1
      } else {
        return 1
      }
    })
    //console.log(`sort medicalRecordList by date : ${medicalRecordList}`);
    //console.log(`sort medicalRecordList by date : ${JSON.stringify(medicalRecordList,null,'\t')}`);
    
    let CreateTime            = this.state.CreateTime
    let UpdateTime            = this.state.UpdateTime
    let InterviewID           = this.state.medicalrecordid.split('_')[1]
    let interview_CreateTime  = CreateTime.getFullYear() + ('0' + (CreateTime.getMonth() + 1)).slice(-2) + ('0' + CreateTime.getDate()).slice(-2) +
                                ('0' + CreateTime.getHours()).slice(-2) + ('0' + CreateTime.getMinutes()).slice(-2) + ('0' + CreateTime.getSeconds()).slice(-2)
    let interview_CreateTime2 = `${CreateTime.getFullYear()}/${('0' + (CreateTime.getMonth() + 1)).slice(-2)}/${('0' + CreateTime.getDate()).slice(-2)} ` +
                                ` (${["日", "月", "火", "水", "木", "金", "土"][CreateTime.getDay()]}) ${('0' + CreateTime.getHours()).slice(-2)}:${('0' + CreateTime.getMinutes()).slice(-2)}`
    let interview_UpdateTime  = UpdateTime.getFullYear() + ('0' + (UpdateTime.getMonth() + 1)).slice(-2) + ('0' + UpdateTime.getDate()).slice(-2) +
                                ('0' + UpdateTime.getHours()).slice(-2) + ('0' + UpdateTime.getMinutes()).slice(-2) + ('0' + UpdateTime.getSeconds()).slice(-2)
    InterviewID               = (interview_CreateTime === InterviewID) ? InterviewID : interview_CreateTime
    InterviewID               = InterviewID + '_' + interview_UpdateTime
    
    //console.log("InterviewID: " + InterviewID)
    //console.log('this.state.Fever_Degrees: ' + Number(this.state.Fever_Degrees).toFixed(1))
    medicalRecordList.unshift(
      <ListGroup.Item as="li" style={{padding: '0 0', margin: '0.25rem 0'}} className="justify-md-left align-left" key={InterviewID}>
        <VariousRecord 
          symptomClass          = "Interview"
          registeredDate        = {interview_CreateTime2}
          symptomContent        = {'熱：' + Number(this.state.Fever_Degrees).toFixed(1) + '℃'}
          symptomNote           = {'メモ：' + this.state.Note}
          MedicalRecordID       = {this.state.medicalrecordid}
          uid                   = {this.state.uid}
          FamilyID              = {this.state.FamilyID}
          MemberID              = {this.state.MemberID}
          member_url            = {this.state.member_url}
          record                = {this.state.record}
          handleUpdateModalOpen = {this.handleUpdateModalOpen}
        />
      </ListGroup.Item>
    )
    //console.log('medicalRecordList add Interview this.state.Fever_Degrees: ' + Number(this.state.Fever_Degrees).toFixed(1))
  
    
    return (
      <React.Fragment>
        <div>
          <FamikarHeader uid={this.state.uid} FamilyID={this.state.FamilyID} />
          <Grid container alignItems="center" style={{padding: "10px 8px 10px"}}>
            <Grid item xs={9} style={{textAlign: "left"}}>
              <h5 style={{margin: "0px auto"}}>
                <span style={{textDecoration: "underline"}}>
                  {(this.state.DisplayName !== '') ? `${this.state.DisplayName}` : ''}
                </span>
              </h5>
            </Grid>
            <Grid item xs={3} style={{textAlign: "right"}}>
              <Button style={{margin: "0px 0px", padding: "6px 12px"}} variant="outlined" color="primary" onClick={()=>this.handleNewModalOpen('FamilyView')}>基本情報</Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-start" alignItems="baseline" style={{padding: "8px 8px 0", fontSize: '1.15em'}}>
            <Grid item style={{width: "10em", textAlign: "left", fontWeight: "bold"}}> {/* 問診票作成日時 */}
              { this.state.CreateTime.getFullYear() + '/' + (this.state.CreateTime.getMonth()+1).toString().padStart(2, '0') + '/' +
                this.state.CreateTime.getDate().toString().padStart(2, '0') + ' (' + ["日", "月", "火", "水", "木", "金", "土"][this.state.CreateTime.getDay()] + ')' }
            </Grid>
            <Grid item style={{minWidth: "2em", maxWidth: "100%", fontWeight: "bold"}}> {/* 受診理由 */}
              <Grid container justifyContent="center">
                <Grid item style={{textAlign: "left"}}>{this.state.RecordTitle}</Grid>
              </Grid>
            </Grid>
            {/* //受診医療機関 は非表示扱いにする 2021/08/14 高橋
            <Grid item sm style={{minWidth: "7.5em", maxWidth: "100%", fontWeight: "bold"}}> 
              <Grid container justifyContent={String(this.state.hospitalName + this.state.RecordTitle).length <= 12 ? 'flex-end' : 'flex-start'}>
                <Grid item style={{textAlign: "left"}}>{this.state.hospitalName}</Grid>
              </Grid>
            </Grid>
            */}
          </Grid>

          {/*<Grid item xs={12} justifyContent="space-between">*/}
          <Grid container spacing={0} justifyContent="space-between" style={{padding: "8px 8px 8px"}}>
            <Grid item>
              <Button style={{margin: "0px auto", padding: "4px 0.8rem", backgroundColor: "#cfe2f3"}} variant="contained" onClick={()=>this.handleNewModalOpen('Diagnosis')}>
                ＋<img src={DiagnosisImg} alt="診断" style={{height: "calc(1rem + 16px)"}} />&nbsp;診断
              </Button>
            </Grid>
            <Grid item>
              <Button style={{margin: "0px auto", padding: "4px 0.8rem", backgroundColor: "#fce5cd"}} variant="contained" onClick={()=>this.handleNewModalOpen('Medicine') }>
                ＋<img src={MedicineImg} alt="お薬" style={{height: "calc(1rem + 16px)"}} />&nbsp;お薬
              </Button>
            </Grid>
            <Grid item>
              <Button style={{margin: "0px auto", padding: "4px 0.8rem", backgroundColor: "#f4cccc"}} variant="contained" onClick={()=>this.handleNewModalOpen('FollowUp') }>
                ＋<img src={FollowupImg} alt="経過" style={{height: "calc(1rem + 16px)"}} />&nbsp;経過
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ListGroup as="ul">
              {medicalRecordList}
            </ListGroup>
            {/* {console.log(`medicalRecordList: [${medicalRecordList}]`)} */}
          </Grid>
          <Toolbar />
          <AppBar position="fixed" style={{backgroundColor: '#FFFFFF', top: 'auto', bottom: '0', boxShadow: 'none'}}>
            <Grid container justifyContent="space-between" alignItems="flex-start" style={{padding: '16px 8px 8px', maxWidth: '480px'}} className="App">
              <Button variant="contained" color="secondary" onClick={this.handleDeleteButtonClick}>削除</Button>
              <Button variant="contained" color="primary"   onClick={this.handleViewPersonalHome}>戻る</Button>
              {/*
              <Tooltip title="戻る">
                <IconButton variant="outline-primary" size="medium" onClick={this.handleGoBack}>
                  <KeyReturnIcon />
                </IconButton>
              </Tooltip>
              */}
            </Grid>
          </AppBar>
        </div>
        {/* Modal */}
        <div>
          {/* {console.log(`this.state.modalShows: ${JSON.stringify(this.state.modalShows,null,'\t')}`)} */}
          <Modal open={this.state.modalShows.Interview} onClose={this.handleModalClose} className={this.props.classes.modal}>
            <Fade in={this.state.modalShows.Interview}>
              <div className={this.props.classes.paper}>
                <ModalInterview handleModalClose={this.handleModalClose} handleReloadModalClose={this.handleReloadModalClose} className={this.props.classes.float}
                  MedicalRecord_ID={this.state.medicalrecordid} DisplayName={this.state.DisplayName} uid={this.state.uid} FamilyID={this.state.FamilyID}
                  MemberID={this.state.MemberID} member_url={this.state.member_url} Interview_state='Edit' />
              </div>
            </Fade>
          </Modal>
          <Modal open={this.state.modalShows.FollowUp} onClose={this.handleModalClose} className={this.props.classes.modal}>
            <Fade in={this.state.modalShows.FollowUp}>
              <div className={this.props.classes.paper}>
                <ModalFollowUp handleModalClose={this.handleModalClose} handleReloadModalClose={this.handleReloadModalClose}
                  className={this.props.classes.float} MedicalRecordId={this.state.medicalrecordid} DisplayName={this.state.DisplayName} />
              </div>
            </Fade>
          </Modal>
          <Modal open={this.state.modalShows.Diagnosis} onClose={this.handleModalClose} className={this.props.classes.modal}>
            <Fade in={this.state.modalShows.Diagnosis}>
              <div className={this.props.classes.paper}>
                <ModalDiagnosis handleModalClose={this.handleModalClose} handleReloadModalClose={this.handleReloadModalClose}
                  className={this.props.classes.float} MedicalRecordId={this.state.medicalrecordid} DisplayName={this.state.DisplayName} />
              </div>
            </Fade>
          </Modal>
          <Modal open={this.state.modalShows.Medicine} onClose={this.handleModalClose} className={this.props.classes.modal}>
            <Fade in={this.state.modalShows.Medicine}>
              <div className={this.props.classes.paper}>
                <ModalMedicine handleModalClose={this.handleModalClose} handleReloadModalClose={this.handleReloadModalClose}
                  className={this.props.classes.float} MedicalRecordId={this.state.medicalrecordid} DisplayName={this.state.DisplayName} />
              </div>
            </Fade>
          </Modal>
          <Modal open={this.state.modalShows.FamilyView} onClose={this.handleModalClose} className={this.props.classes.modal}>
            <Fade in={this.state.modalShows.FamilyView}>
              <div className={this.props.classes.paper}>
                <ModalFamilyView handleModalClose={this.handleModalClose} className={this.props.classes.float}
                  DisplayName={this.state.DisplayName} image_src={this.state.member_url} uid={this.state.uid} FamilyID={this.state.FamilyID}
                  MemberID={this.state.MemberID} MedicalRecordID={this.state.medicalrecordid} />
              </div>
            </Fade>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}
export default withStyles(useStyles)(withRouter(SymptomCard))