import React from "react";
import {withRouter} from "react-router-dom";
import firebase from'firebase/app';
import 'firebase/auth';

class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin:false, //ログイン状態
    }
  }

  // URLクエリを使用する
  useQuery(){
    return new URLSearchParams(this.props.location.search)
  }

  // ページ表示時の会員登録画面表示
  componentDidMount(){
    var query = this.useQuery()
    if(query.get("view") === "Regist"){ //URLが「?view=Regist」の場合は会員登録画面表示を行う。
      this.props.history.push('/Login?view=Regist') //ログイン画面上で、会員登録画面に移動する。
    } else {
      if (this.state.isLogin === false){
        this.UserStatusCheck() //ログイン状態チェック
      }
    }
  }

  //「ログイン状態取得」
  UserStatusCheck=()=>{
    let uid      = ''
    let FamilyID = ''
    Promise.resolve().then(()=>{
      return new Promise((resolve,reject)=>{
        firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
          if (user) {
            console.log("UserStatusCheck(): user.uid:"+user.uid)
            uid = user.uid
            resolve() //家族ID取得に移行する
          } else {
            reject() //ログイン画面に移行する
          }
        })
      })
    })
    .then(()=>{ //ログインユーザーの情報取得に成功した場合は、家族ID取得処理に移行する
      return new Promise((resolve)=>{
        firebase.firestore().collection("Person").doc(uid).get().then((result)=>{
          FamilyID = result.data().FamilyID[0].id //1件目の家族IDを取得する。
          console.log("FamilyID: " + FamilyID)
          resolve() //家族ホーム画面への移動処理に移行する
        })
      })
    })
    .then(()=>{ //家族ホーム画面への移動処理
      console.log("push URL: /FamilyHome/" + FamilyID)
      this.props.history.push({ //ログイン中の場合は、家族ホーム画面に移動する。
        pathname: '/FamilyHome/' + FamilyID,
        state: {
          uid: uid,  // ログインユーザーID
        }
      })
    })
    .catch(()=>{ //ログインユーザーの情報取得失敗の場合はログイン画面へ移動する
      //sessionStorage.clear() //ログインページのリロード管理用のローカルストレージを削除する。
      console.log("push URL: /Login")
      this.props.history.push('/Login') //ログイン中でなければ、ログイン画面に移動する。
    })
  }

  render(){
    return (
      <React.Fragment>
      </React.Fragment>
    )
  }
}

export default withRouter(Top)
