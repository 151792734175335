import React                      from "react";
import {withRouter}               from "react-router-dom";
import {ListGroup}                from 'react-bootstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button                     from '@material-ui/core/Button'
//import Typography                 from '@material-ui/core/Typography'
import Grid                       from '@material-ui/core/Grid'
//import GridList                   from '@material-ui/core/GridList'; //2021.11.20 Material-UIのツール名称変更（GridList→ImageList）に伴い無効化
import ImageList                   from '@material-ui/core/ImageList'; //2021.11.20 Material-UIのツール名称変更（GridList→ImageList）に伴い変更
//import GridListTile               from '@material-ui/core/GridListTile';
//import GridListTileBar            from '@material-ui/core/GridListTileBar';
import FamikarHeader              from '../components/FamikarHeader';
import MemberData                 from '../components/MemberData';
//import MedicalRecord              from '../components/MedicalRecord';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase from'firebase/app';
import 'firebase/auth';

/*
const liStyle = {
  display: 'inline',
  width: '100px'
}
*/

const MemberList = { //メンバー一覧用
  list:[
    // {
    //   FamilyID:    [],  //家族ID
    //   MemberID:    "",   //メンバーID
    //   DisplayName: '', //メンバー表示名
    //   image_url:   '' //アイコンURL
    // }
  ]
}

let uid = ''
let tmpFamilyIDs = [] //FamilyIDを格納
let members = []      //メンバーIDリスト
let FamilyName        //家族名一時格納

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  card: {
    display: 'flex',
    maxHeight: 450,
    padding: 0,
    margin: 0
  },
}));

class FamilyHome extends React.Component { 
  constructor(props) {
    super(props);
    var {params} = this.props.match //クエリ文字列を取得する。
    this.state = {
      tableTitle: '家族ホーム',
      modalShow:  false, 
      isLogin:    false, 
      uid:        '',              //ログインユーザーのuid ※uidの読み込みは一旦廃止 2021.03.06 takahashi
      FamilyID:   (params.FamilyID) ? params.FamilyID : '', //家族ID
      FamilyName: '',              //家族名
      //firstname:  '',    //名前
      //familyname: '',   //名字
      familyids:  [],    //家族IDを1件取得する
      memberList: [{ //メンバー一覧
        MemberID:    '',
        FamilyID:    '',
        DisplayName: '',
        BirtyDate:   null,
        image_url:   ''
      }],
      members: [], //表示対象のメンバー一覧
    }
    console.log(this.state.FamilyID)
  }

  componentDidMount() { //ページ表示時、情報更新時に実行
    console.log("compoonentDidMount: this.state.FamilyID["+this.state.FamilyID+"]")
    const db = firebase.firestore();
    this.getLoginInfo(db) // ログイン情報取得
  }

  componentWillUnmount() { //ページを離れる際に実行
    document.title = 'ふぁみかるくん' //家族ホーム画面を離れる際、ページタイトルを初期値に戻す
  }

  // ログイン情報取得
  getLoginInfo(db) {
    console.log("FamilyHome.js getLoginInfo()")
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
          if (user) {
            uid = user.uid //ログインユーザーのuidを取得する
            resolve()
          }
        })
      })
    })
    .then(()=>{
      return new Promise((resolve)=>{
        if (this.state.FamilyID === '') {
          db.collection("Person").doc(uid).get().then((Person_result)=>{ //ログインユーザー情報から、家族IDの情報を取得する
            tmpFamilyIDs.push(Person_result.data().FamilyID[0].id) //1件目の家族IDを取得する。
            window.history.replaceState(null, null, "/FamilyHome/" + tmpFamilyIDs[0]) //URLに家族IDが含まれない場合は、家族IDを含むURLに書き替えを行う。
            resolve()
          })
        } else {
          tmpFamilyIDs.push(this.state.FamilyID) //1件目の家族IDを取得する。
          resolve()
        }
      })
    })
    .then(()=>{
      db.collection("Family").doc(tmpFamilyIDs[0])
      .get().then((doc)=>{
        FamilyName = doc.data().FamilyName //家族名取得
        document.title = `${((FamilyName) ? FamilyName : '○○')}家のホーム - ふぁみかるくん`
        //console.log(`FamilyName: ${FamilyName}`)
        this.getMemberList(db) // 家族情報取得
      })
    })
  }

  // 家族情報取得
  getMemberList(db) {
    Promise.resolve().then(()=>{
      return new Promise((resolve)=>{
        db.collection("Family").doc(tmpFamilyIDs[0]).collection("Member").orderBy("DisplayName").get().then((result)=>{
          MemberList.list = []
          result.forEach((doc)=> {
            let dataT = doc.data()
            let DeleteFlag = (dataT.DeleteFlag == null) ? false : dataT.DeleteFlag //家族情報の削除フラグがある場合は、非表示にする。(2020.08.29追加)
            //console.log("FamilyHome.js family member FamilyID:["+this.state.familyids[0]+"]")
            //console.log("FamilyHome.js family member MemberID:["+doc.id+"]")
            //console.log("FamilyHome.js family member DisplayName:["+dataT.DisplayName+"]")
            //console.log("FamilyHome.js family member url:["+dataT.image_url+"]")
            if (DeleteFlag === false) {
              var _birthDate = (dataT.BirthDay == null) ? null : dataT.BirthDay.toDate()
              MemberList.list.push({
                FamilyID:          tmpFamilyIDs[0],    //家族ID
                MemberID:          doc.id,             //メンバーID
                DisplayName:       dataT.DisplayName,  //メンバー表示名
                BirthDate:         _birthDate,
                BirthYear:         (_birthDate === null) ? '' : _birthDate.getFullYear(),
                BirthMonth:        (_birthDate === null) ? '' : _birthDate.getMonth()+1,
                BirthDay:          (_birthDate === null) ? '' : _birthDate.getDate(),
                image_url:         dataT.image_url,
                //SymptomsCardCount: 0,
              })
              members.push(doc.id) //診療情報表示対象のメンバーID
            }
          })

          // 家族情報を誕生日でソートする（若い方が先にくる）
          // 誕生日が入ってない場合は後ろにソートされる
          // 誕生日が同じもしくは比較できない場合は名前でソートされる
          MemberList.list.sort( (a, b) =>{
            if　(a.BirthDate != null && b.BirthDate != null)　{
              if　(a.BirthDate < b.BirthDate) {
                return 1
              } else if　(a.BirthDate > b.BirthDate) {
                return -1
              } else {
                return a.DisplayName > b.DisplayName
              }
            } else if (a.BirthDate == null && b.BirthDate == null) {
              return a.DisplayName > b.DisplayName
            } else if (a.BirthDate != null) {
              return -1
            } else if (b.BirthDate != null) {
              return 1
            }
            return 1
          })
          resolve()
        }).catch(function(error) {
          console.log("Error getting family member documents: ", error)
          resolve()
        })
      })
    })
    .then(()=>{
      this.setState({
        uid:        uid,
        familyids:  tmpFamilyIDs,
        FamilyName: FamilyName,
        tableTitle: `${(FamilyName) ? FamilyName : '○○'}家`, 
        memberList: MemberList.list,
        members:    members,
      })
    })
  }



  // 新規登録ボタン
  handlerCreateButtonClick = () =>{
    console.log("handlerCreateButtonClick famiryid:" + this.state.familyids[0])
    this.props.history.push({
      pathname: '/familyregist',
      state: {
        uid: this.state.uid, //ログインユーザーuid
        familyID: this.state.FamilyID,
        isEdit: false
      }
    })
  }

  // 家族招待ボタン
  handlerInvitationButtonClick = () =>{
    console.log("handlerInvitationButtonClick famiryid:" + this.state.familyids[0])
    this.props.history.push({
      pathname: '/Invitation/' + this.state.FamilyID
    })
  }

  render(){
    /*
    const style={
      position: 'fixed',
      top: 'auto',
      left: 'auto',
      bottom: 50,
      right: 50,
      padding: 5,
      width: 50,
      height: 40
    };
    */

    var memberList = []
    //console.log("FamilyHome.js render called member.length["+this.state.memberList.length+"] medical.length["+this.state.itemList.length+"]")
    if (this.state.memberList.length > 0) {
      this.state.memberList.forEach((member)=>{
        //console.log(member)
        if (member.MemberID !== '') {
          var dispuser = this.state.memberList.find(mm => mm.MemberID === member.MemberID)
          var memberName = dispuser.DisplayName
          //var birthDate = dispuser.BirthYear + "年" + dispuser.BirthMonth + "月" + dispuser.BirthDay + "日"
          var url = dispuser.image_url
          console.log("memberName : " + memberName)
          memberList.push(
            <ListGroup.Item as="li" className="justify-md-left align-left" key={member.MemberID}>
              <MemberData memberName={memberName} member_url={url} memberID={member.MemberID} familyID={member.FamilyID} uid={this.state.uid} />
            </ListGroup.Item>
          )
        }
      })
    }

    console.log("FamilyHome.js render this.state.uid["+this.state.uid+"]")

    let InvitationButtonDisplay = 'none' //家族を招待しましょうのボタンを非表示にする
    let DevUidList = ['dzLY33LkJFYpds6roh4mnxzHSFg2', 'vkQebsVdKhgqFM9wtb9WUJV4TBg2', 'maTdHx3FtCT0rbqCcjTOohq8WzK2']
    if(DevUidList.indexOf(this.state.uid) !== -1){
      InvitationButtonDisplay = 'block'
      console.log("FamilyHome.js InvitationButtonDisplay "+InvitationButtonDisplay)
    }else{
      InvitationButtonDisplay = 'none'
      console.log("FamilyHome.js InvitationButtonDisplay "+InvitationButtonDisplay)
    }

    return (
      <React.Fragment>
        <div>
          <FamikarHeader uid={this.state.uid} FamilyID={this.state.FamilyID} isHome={true} />
          <Grid container spacing={0}>
            {/* ~ 家族ホーム画面の「家族ホーム」表記削除＋「新規追加」ボタンの削除 ~
            <Grid container justify="flex-start">
              <Grid item xs={8}>
                <Typography variant="h6" align='left'>
                  家族ホーム
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button variant="outlined" color="primary" onClick={this.handlerCreateButtonClick}>新規追加</Button>
              </Grid>
            </Grid>
            */}
            {/* メンバー一覧 */}
            <Grid container justifyContent="space-between" alignItems="stretch">
              <Grid item xs={12}>
                <ImageList cols={1}>
                  {memberList}
                </ImageList>
              </Grid>
              {console.log("FamilyHome.js return memberList.length[" + memberList.length + "]")}
            </Grid>
            {/* 新規追加ボタンは画面下部に移動 */}
            <Grid container spacing={0} style={{marginTop: "16px"}}>
              <Grid item xs={12}>
                <Button variant="contained" style={{width: "calc(100% - 16px)", margin: "0 auto", padding: "16px 0px", backgroundColor: '#faf0e6'}} onClick={this.handlerCreateButtonClick}>
                  ＋&nbsp;家族を追加しましょう
                </Button>
              </Grid>
            </Grid>
            {/* 家族招待画面へのリンクを表示する（開発者のみ） */}
            <Grid container spacing={0} style={{marginTop: "16px"}}>
              <Grid item xs={12}>
                <Button variant="contained" style={{width: "calc(100% - 16px)", margin: "0 auto", padding: "16px 0px", backgroundColor: '#d6ffd6', display: InvitationButtonDisplay}} onClick={this.handlerInvitationButtonClick}>
                  ＋&nbsp;家族を招待しましょう
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    )
  }
}

export default withStyles(useStyles)(withRouter(FamilyHome))
