/* jshint esversion: 6 */
import React from "react";
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';
import TextField      from '@material-ui/core/TextField';
import MenuItem       from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid           from '@material-ui/core/Grid'
import Typography     from '@material-ui/core/Typography';
import Toolbar        from '@material-ui/core/Toolbar';
import Resizer        from 'react-image-file-resizer';
import firebase       from 'firebase/app';
import 'firebase/auth';

/* 開発メモ
handleFollowUpReferenceButton() ⇒ 経過画像変更用参照ボタンを押したときに呼び出される。
 ⇒ handleFollowUpImageFileChange()が実行される。
handleFollowUpImageFileChange() 経過記録画像変更時の処理
FollowUp_Thumbnail_ImageDraw() 経過サムネイル画像を描画
FollowUpImageDraw() 経過画像描画
loadFollowUpImage(src) 経過記録画像読み込み処理
RegistFollowUpImage() 経過画像登録処理
 */

const useStyles = {
  button: {
    margin: 2,
    //width: 150,
  },
  root: {
    height: 800,
  },
  image: {
    width:  64,
    height: 64,
  },
  img: {
    margin:    'auto',
    display:   'block',
    maxWidth:  '100%',
    maxHeight: '100%',
  },
}

//経過画像一覧
const DataImageList = {
  list:[
    //{
    //  number:       "(数値)",     //画像番号
    //  source_url:   "(文字列)",   //取り込み元画像
    //  image_url:    "(文字列)",   //経過画像パス
    //  thum_url:     "(文字列)",   //経過サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //  image_change: "(ブール値)", //経過登録画像の変更有無（true=変更あり、false=変更なし）
    //}
  ],
  UploadList:[
    //{
    //  image_url:    "(文字列)",   //経過画像パス
    //  thum_url:     "(文字列)",   //経過サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //}
  ]
}

//画像リサイズ用定数
const resizer = {
  maxWidth:           1920,    //変換後画像の最大横幅
  maxHeight:          1920,    //変換後画像の最大縦幅
  maxThumbnailWidth:  320,     //変換後サムネイル画像の最大横幅
  maxThumbnailHeight: 320,     //変換後サムネイル画像の最大縦幅
  compressFormat:     "JPEG",  //圧縮フォーマット（JPEG, PNG or WEBP）
  quality:            80,      //画質（0 ~ 100 圧縮しない場合は100をセットする。）
  outputType:         "bolb" //出力タイプ（base64, bolb, file）
}
class Interview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uid:              this.props.uid,              //ログインユーザーのuid
      FamilyID:         this.props.FamilyID,         //診療情報登録対象の家族ID
      MemberID:         this.props.MemberID,         //診療情報登録対象のメンバーID
      DisplayName:      this.props.DisplayName,      //診療情報登録対象の表示名
      member_url:       this.props.member_url,       //診療情報登録対象のアイコン画像パス
      MedicalRecord_ID: this.props.MedicalRecord_ID, //診療情報のレコードID
      Interview_state:  this.props.Interview_state,  //問診票の取扱状態（'New'=新規作成, 'View'=表示, Edit'=編集）
      modalShow:               false,                //modalの表示状態
      isLogin:                 false,                //ログイン状態
      EditShow:                'none',               //問診票新規＋編集共通表示
      NewOnlyShow:             'none',               //新規問診票登録画面の表示設定 (block=ブロック表示、none=非表示)
      EditOnlyShow:            'none',               //問診票編集画面の表示設定 (block=ブロック表示、none=非表示)
      ViewShow:                'none',               //問診票表示画面の表示設定 (block=ブロック表示、none=非表示)
      CreateTime:              new Date(),           //診療情報作成日時
      UpdateTime:              new Date(),           //診療情報更新日時
      ConsultationTime:        new Date(),           //診療の受診日時（予定日を含む。2022年9月以降の必須項目。情報が無い場合は、診療情報作成日時を表示する）
      MedicalSpecialty:        '',                   //診療科目
      Title:                   '',                   //診療情報一覧に表示するタイトル
      HospitalName:            '',                   //病院名
      Diagnosis:               '',                   //診断結果
      Reason_for_Consultation: '',                   //受診理由（2021/08/13 追加 高橋）
      Fever_Symptoms:          false,      //発熱症状の有無
      Fever_Since:             '',         //発熱いつから 例：2021-08-17 or ''
      Fever_Degrees:           0,          //発熱時の体温
      RunnyNose_Symptoms:      false,      //鼻水の有無
      RunnyNose_Since:         '',         //鼻水いつから
      RunnyNose_Color:         '',         //鼻水の色
      Cough_Symptoms:          false,      //咳の有無
      Cough_Since:             '',         //咳いつから
      Cough_Type:              '',         //咳の種類
      Headache_Symptoms:       false,      //頭痛の有無
      Headache_Since:          '',         //頭痛いつから
      Phlegm_Symptoms:         false,      //痰の有無
      Phlegm_Since:            '',         //痰いつから
      Wheeze_Symptoms:         false,      //喘鳴の有無
      Wheeze_Since:            '',         //喘鳴いつから
      Vomit_Symptoms:          false,      //嘔吐の有無
      Vomit_Since:             '',         //嘔吐いつから
      Vomit_times_per_day:     0,          //1日の嘔吐回数
      Diarrhea_Symptoms:       false,      //下痢の有無
      Diarrhea_Since:          '',         //下痢いつから
      Diarrhea_times_per_day:  0,          //1日の下痢回数
      Stommachache_Symptoms:   false,      //腹痛の有無
      Stommachache_Since:      '',         //腹痛いつから
      Eruption_Symptoms:       false,      //発疹の有無
      Eruption_Since:          '',         //発疹いつから
      Spasm_Symptoms:          false,      //けいれんの有無
      Spasm_Since:             '',         //けいれん いつから
      Note:                    '',         //フリーメモ
      First_FollowUp:          '',         //初回経過情報参照先のドキュメントパス
      BodyTemperature:         '',         //体温(経過登録用)
      Appetite:                '',         //食欲(経過登録用)
      Moisture:                '',         //水分(経過登録用)
      Mood:                    '',         //機嫌(経過登録用)
      View_Appetite:           '',         //食欲(初回経過情報表示用)
      View_Moisture:           '',         //水分(初回経過情報表示用)
      View_Mood:               '',         //機嫌(初回経過情報表示用)
      FollowUpNote:            '',         //メモ(経過登録用)
      FollowUp_image_url:      '',         //経過登録用画像
      FollowUp_thum_url:       '',         //経過登録用サムネイル画像
      FollowUp_image_change:   false,      //経過登録画像の変更有無（true=変更あり、false=変更なし）
      FollowUpImageBox_width:  0,          //経過画像表示領域の横幅（初期値）
      FollowUpImageBox_height: 0,          //経過画像表示領域の高さ（初期値）
      FollowUp_CreateTime:     new Date(), //経過情報が初めて記録された時刻
      FollowUp_UpdateTIme:     new Date(), //経過情報更新時刻
      FollowUpTime:            new Date(), //経過情報を設定した時刻
      data_image_list:         [],         //経過画像格納用リスト
    }
    //console.dir(props)
    DataImageList.list = []
    DataImageList.UploadList = []
    //console.log('Class: Interview, constructor()')
  }

  //受診日
  handleEditConsultationTime(e){
    this.setState({ConsultationTime: new Date(e.target.value)})
  }

  //診断結果
  handleEditDiagnosis(e){
    this.setState({Diagnosis: e.target.value})
  }

  //受診病院名
  handleEditHospitalName(e){
    this.setState({HospitalName: e.target.value})
  }

  //診療科目
  handleEditMedicalSpecialty(e){
    this.setState({MedicalSpecialty: e.target.value})
  }

  //受診理由
  Edit_Reason_for_Consultation(e){
    this.setState({Reason_for_Consultation: e.target.value})
  }

  //発熱いつから
  handleFeverSinceChange(e){
    this.setState({Fever_Since: e.target.value})
    if(e.target.value !== '' || this.state.Fever_Degrees !== 0){
      this.setState({Fever_Symptoms: true})  //発症時期が選択されているか、体温の入力がある場合は、「発熱」にチェックを入れる。
    } else {
      this.setState({Fever_Symptoms: false}) //発症時期が選択されていないかつ、体温の入力が無い場合は、「発熱」のチェックを外す。
    }
  }

  //発熱時の体温
  handleFeverDegreesChange(e){
    if(e.target.value !== '0.0'){
      this.setState({Fever_Degrees: Number(e.target.value)}) //体温は数値型で格納する。
      if(this.state.Interview_state === 'New'){ //問診票の新規登録時の体温入力は、経過登録にも使用する。
        this.setState({BodyTemperature: Number(e.target.value)})
      }
    } else {
      this.setState({Fever_Degrees: 0}) //体温の登録が無い場合は、0を入れる。
      if(this.state.Interview_state === 'New'){ //問診票の新規登録時の体温入力は、経過登録にも使用する。
        this.setState({BodyTemperature: 0})
      }
    }
    if(e.target.value !== '0.0' || this.state.Fever_Since !== ''){
      this.setState({Fever_Symptoms: true})  //発症時期が選択されているか、体温の入力がある場合は、「発熱」にチェックを入れる。
    } else {
      this.setState({Fever_Symptoms: false}) //発症時期が選択されていないかつ、体温の入力が無い場合は、「発熱」のチェックを外す。
    }
  }

  //鼻水いつから
  handleRunnyNoseSinceChange(e){
    this.setState({RunnyNose_Since: e.target.value})
    if(e.target.value !== '' || this.state.RunnyNose_Color !== ''){
      this.setState({RunnyNose_Symptoms: true})  //発症時期が選択されているか、鼻水の色が選択されている場合は、「鼻水」にチェックを入れる。
    } else {
      this.setState({RunnyNose_Symptoms: false}) //発症時期が選択されていないかつ、鼻水の色が選択されていない場合は、「鼻水」のチェックを外す。
    }
  }

  //鼻水の色
  handleRunnyNoseColorChange(e){
    this.setState({RunnyNose_Color: e.target.value})
    if(e.target.value !== '' || this.state.RunnyNose_Since !== ''){
      this.setState({RunnyNose_Symptoms: true})  //発症時期が選択されているか、鼻水の色が選択されている場合は、「鼻水」にチェックを入れる。
    } else {
      this.setState({RunnyNose_Symptoms: false}) //発症時期が選択されていないかつ、鼻水の色が選択されていない場合は、「鼻水」のチェックを外す。
    }
  }

  //咳いつから
  handleCoughSinceChange(e){
    this.setState({Cough_Since: e.target.value})
    if(e.target.value !== '' || this.state.Cough_Type !== ''){
      this.setState({Cough_Symptoms: true})  //発症時期が選択されているか、咳の種類が選択されている場合は、「咳」にチェックを入れる。
    } else {
      this.setState({Cough_Symptoms: false}) //発症時期が選択されていないかつ、咳の種類が選択されていない場合は、「咳」のチェックを外す。
    }
  }

  //咳の種類
  handleCoughTypeChange(e){
    this.setState({Cough_Type: e.target.value})
    if(e.target.value !== '' || this.state.Cough_Since !== ''){
      this.setState({Cough_Symptoms: true})  //発症時期が選択されているか、咳の種類が選択されている場合は、「咳」にチェックを入れる。
    } else {
      this.setState({Cough_Symptoms: false}) //発症時期が選択されていないかつ、咳の種類が選択されていない場合は、「咳」のチェックを外す。
    }
  }

  //頭痛いつから
  handleHeadacheSinceChange(e){
    this.setState({Headache_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Headache_Symptoms: true})  //発症時期が選択されている場合は、「頭痛」にチェックを入れる。
    } else {
      this.setState({Headache_Symptoms: false}) //発症時期が選択されていない場合は、「頭痛」のチェックを外す。
    }
  }

  //痰いつから
  handlePhlegmSinceChange(e){
    this.setState({Phlegm_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Phlegm_Symptoms: true})   //発症時期が選択されている場合は、「痰」にチェックを入れる。
    } else {
      this.setState({Phlegm_Symptoms: false})  //発症時期が選択されていない場合は、「痰」のチェックを外す。
    }
  }

  //喘鳴（ゼーゼーとした息）いつから
  handleWheezeSinceChange(e){
    this.setState({Wheeze_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Wheeze_Symptoms: true})  //発症時期が選択されている場合は、「喘鳴」にチェックを入れる。
    } else {
      this.setState({Wheeze_Symptoms: false}) //発症時期が選択されていない場合は、「喘鳴」のチェックを外す。
    }
  }

  //嘔吐いつから
  handleVomitSinceChange(e){
    this.setState({Vomit_Since: e.target.value})
    if(e.target.value !== '' || this.state.Vomit_times_per_day !== 0){
      this.setState({Vomit_Symptoms: true})  //発症時期が選択されているか、嘔吐の回数が選択されている場合は、「嘔吐」にチェックを入れる。
    } else {
      this.setState({Vomit_Symptoms: false}) //発症時期が選択されていないかつ、嘔吐の回数が選択されていない場合は、「嘔吐」のチェックを外す。
    }
  }

  //嘔吐の回数
  handleVomitTimesChange(e){
    if(e.target.value !== '0'){
      this.setState({Vomit_times_per_day: Number(e.target.value)}) //1回以上の場合は回数を記録する。
    } else {
      this.setState({Vomit_times_per_day: 0})
    }
    if(e.target.value !== '0' || this.state.Vomit_Since !== ''){
      this.setState({Vomit_Symptoms: true})  //発症時期が選択されているか、嘔吐の回数が選択されている場合は、「嘔吐」にチェックを入れる。
    } else {
      this.setState({Vomit_Symptoms: false}) //発症時期が選択されていないかつ、嘔吐の回数が選択されていない場合は、「嘔吐」のチェックを外す。
    }
  }

  //下痢いつから
  handleDiarrheaSinceChange(e){
    this.setState({Diarrhea_Since: e.target.value})
    if(e.target.value !== '' || this.state.Diarrhea_times_per_day !== 0){
      this.setState({Diarrhea_Symptoms: true})  //発症時期が選択されているか、下痢の回数が選択されている場合は、「下痢」にチェックを入れる。
    } else {
      this.setState({Diarrhea_Symptoms: false}) //発症時期が選択されていないかつ、下痢の回数が選択されていない場合は、「下痢」のチェックを外す。
    }
  }

  //下痢の回数
  handleDiarrheaTimesChange(e){
    if(e.target.value !== '0'){
      this.setState({Diarrhea_times_per_day: Number(e.target.value)}) //1回以上の場合は回数を記録する。
    } else {
      this.setState({Diarrhea_times_per_day: 0})
    }
    if(e.target.value !== '0' || this.state.Diarrhea_Since !== ''){
      this.setState({Diarrhea_Symptoms: true})  //発症時期が選択されているか、下痢の回数が選択されている場合は、「下痢」にチェックを入れる。
    } else {
      this.setState({Diarrhea_Symptoms: false}) //発症時期が選択されていないかつ、下痢の回数が選択されていない場合は、「下痢」のチェックを外す。
    }
  }

  //腹痛いつから
  handleStommachacheSinceChange(e){
    this.setState({Stommachache_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Stommachache_Symptoms: true})  //発症時期が選択されている場合は、「腹痛」にチェックを入れる。
    } else {
      this.setState({Stommachache_Symptoms: false}) //発症時期が選択されていない場合は、「腹痛」のチェックを外す。
    }
  }

  //発疹いつから
  handleEruptionSinceChange(e){
    this.setState({Eruption_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Eruption_Symptoms: true})  //発症時期が選択されている場合は、「発疹」にチェックを入れる。
    } else {
      this.setState({Eruption_Symptoms: false}) //発症時期が選択されていない場合は、「発疹」のチェックを外す。
    }
  }

  //けいれん いつから
  handleSpasmSinceChange(e){
    this.setState({Spasm_Since: e.target.value})
    if(e.target.value !== ''){
      this.setState({Spasm_Symptoms: true})  //発症時期が選択されている場合は、「けいれん」にチェックを入れる。
    } else {
      this.setState({Spasm_Symptoms: false}) //発症時期が選択されていない場合は、「けいれん」のチェックを外す。
    }
  }

  //食欲変更時の処理
  handleAppetiteChange(e){
    this.setState({Appetite: e.target.value})
  }

  //水分変更時の処理
  handleMoistureChange(e){
    this.setState({Moisture: e.target.value})
  }

  //機嫌変更時の処理
  handleMoodChange(e){
    this.setState({Mood: e.target.value})
  }

  //フリーメモ変更時の処理
  handleNote(e){
    this.setState({
      Note:         e.target.value,
      FollowUpNote: e.target.value
    })
  }

  //前のページに戻る（「戻る」ボタン）
  handleGoBack = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // PersonalHome,SymptomCardよりロードされた場合は、モーダルを閉じる
    }
  }

  //モーダルを非表示状態に切り替える
  handleModalClose = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // 症状カード画面のモーダルを閉じる
    }
  }

  //モーダルを非表示状態に切り替え、症状カード画面で再読み込みする
  handleReloadModalClose = async () => {
    if(typeof this.props.handleReloadModalClose == 'function'){
      await this.props.handleReloadModalClose() // 個人ホーム画面または、症状カード画面のモーダルを閉じ、画面内データを再読み込みする。
    }
  }

  //問診票登録（「登録」ボタンを押した際のアクション）
  handleRegist = async () => {
    // 問診票登録時の医療機関名チェックを廃止（問診票登録時に医療機関名の登録を行わない為）（2021/08/13 高橋）
    //if ( this.state.HospitalName === '' ){
    //  alert("受診する医療機関名を入れてください")
    //  return
    //}

    //作成時刻と更新時刻を登録時に更新する。
    this.setState({
      UpdateTime: new Date(), //診療情報更新時間
      CreateTime: new Date()  //診療情報作成日時
    })

    //データベースに問診票の登録を実施する。
    await this.RegistInterview()

    var create_time = this.state.CreateTime;
    var Doc_ID = create_time.getFullYear() + ('0' + (create_time.getMonth()+1)).slice(-2) + ('0' + create_time.getDate()).slice(-2) + 
                 ('0' + create_time.getHours()).slice(-2) + ('0' + create_time.getMinutes()).slice(-2) + ('0' + create_time.getSeconds()).slice(-2);

    //経過画像登録処理
    await this.RegistFollowUpImage(Doc_ID)

    //データベースに経過情報の登録を実施する。
    await this.RegistFollowUp()

    //個人ホーム画面の問診票登録モーダル表示を閉じ、画面内データの再読み込みを行う。
    await this.handleReloadModalClose()
  }

  //問診票更新（「保存」ボタンを押した際のアクション）
  handleUpdate = async() => {
    //更新時刻を更新時に更新する。
    this.setState({
      UpdateTime:          new Date(),  //診療情報更新時間
      FollowUp_UpdateTIme: new Date(),  //経過情報更新時間
    })

    var create_time = this.state.CreateTime;
    var Doc_ID = create_time.getFullYear() + ('0' + (create_time.getMonth()+1)).slice(-2) + ('0' + create_time.getDate()).slice(-2) + 
                 ('0' + create_time.getHours()).slice(-2) + ('0' + create_time.getMinutes()).slice(-2) + ('0' + create_time.getSeconds()).slice(-2);

    //経過画像更新処理
    await this.RegistFollowUpImage(Doc_ID)

    if(this.state.First_FollowUp === ''){
      //初回経過情報が未登録の場合は登録を行う。
      //データベースに経過情報の登録を実施する。
      await this.RegistFollowUp()
    } else {
      //初回経過情報が登録済みの場合は更新を行う。
      await this.UpdateFollowUp()
    }

    //データベースの内容を更新する。
    await this.UpdateInterView()

    //症状カード画面の問診票モーダル表示を閉じ、画面内データの再読み込みを行う。
    this.handleReloadModalClose()
  }

  //問診票編集画面へ移動
  handleEdit = async() => {
    this.setState({
      Interview_state: 'Edit',  //問診票編集モード
      EditShow:        'block', //問診票 新規登録＋編集共通表示【表示】
      NewOnlyShow:     'none',  //問診票 新規登録画面　　　　　【非表示】
      EditOnlyShow:    'block', //問診票 編集画面　　　　　　　【表示】
      ViewShow:        'none',  //問診票 表示画面　　　　　　　【非表示】
    })
    this.ReadInterview()       //データベースから登録済みの問診票データを読み出す。
    if(this.state.FollowUp_image_url !== ''){
      //初回経過画像が登録されている場合は描画する。
      this.setState({
        FollowUpImageBox_width:  128, // 経過画像表示領域の横幅を128pxで設定する。
        FollowUpImageBox_height: 128, // 経過画像表示領域の高さを128pxで設定する。
      })
    }
  }

  //問診票表示画面へ移動
  handleView = async() => {
    this.setState({
      Interview_state:    'View',  //問診票編集モード
      EditShow:           'none',  //問診票 新規登録＋編集共通表示【非表示】
      NewOnlyShow:        'none',  //問診票 新規登録画面　　　　　【非表示】
      EditOnlyShow:       'none',  //問診票 編集画面　　　　　　　【非表示】
      ViewShow:           'block', //問診票 表示画面　　　　　　　【表示】
      FollowUp_image_url: '',      //経過画像表示をリセットする。
    })
    await this.ReadInterview()     //データベースから登録済みの問診票データを読み出す。
    if(this.state.First_FollowUp !== ''){
      //初回経過情報が登録されている場合は読み出す。
      await this.ReadFollowUp()    //データベースから登録済みの初回経過情報を読み出す。
    }
  }

  //「いつから」を日付（yyyy-mm-dd）に変換する。
  getSince(Since, CreateTime) {
    if (Object.prototype.toString.call(Since) === '[object String]') {
      switch(Since) {
        case '':
          Since = ''
          break
        
        case '今日から':
          Since = CreateTime
          Since.setDate(Since.getDate())
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break
        
        case '昨日から':
          Since = CreateTime
          Since.setDate(Since.getDate() - 1)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case '２、３日前から':
          Since = CreateTime
          Since.setDate(Since.getDate() - 2)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case '４、５日前から':
          Since = CreateTime
          Since.setDate(Since.getDate() - 4)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case '１週間前から':
          Since = CreateTime
          Since.setDate(Since.getDate() - 7)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case '２週間前から':
          Since = CreateTime
          Since.setDate(Since.getDate() - 14)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case '１カ月前から':
          Since = CreateTime
          Since.setMonth(Since.getMonth() - 1)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        case 'もっと前から':
          Since = CreateTime
          Since.setMonth(Since.getMonth() - 2)
          Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)
          break

        default:
          Since = ''
          break
      }
      
    } else if (Object.prototype.toString.call(Since) === '[object Object]') {
      Since = Since.toDate()
      Since = Since.getFullYear() + '-' + ('0' + (Since.getMonth() + 1)).slice(-2) + '-' + ('0' + Since.getDate()).slice(-2)

    } else if (Object.prototype.toString.call(Since) === '[object Null]') {
      Since = ''
    }
    //console.log('Since: ' + Since)
    return Since
  }

  //「いつから」の部分をDate型で取得する。
  getSinceDate(SinceString) {
    if (SinceString !== ''){
      //console.log('Date: ' + new Date(SinceString.replace(/-/g,"/") + ' 00:00:00')) //日付の変換の際、iOS Safari対策で「-」を「/」に変換
      return new Date(SinceString.replace(/-/g,"/") + ' 00:00:00') //日付の変換の際、iOS Safari対策で「-」を「/」に変換
    } else {
      return ''
    }
  }

  //データベースに登録されている問診票の情報を読み込む
  async ReadInterview() {
    console.log("ReadInterview() start!!")
    var db = firebase.firestore()

    await db.collection("MedicalRecords").doc(this.state.MedicalRecord_ID).get().then((result)=>{
      var data = result.data()
      //console.dir(data)
      //console.log('ConsultationTime:' + (("ConsultationTime"        in data) ? data.ConsultationTime.toDate() : ("CreateTime" in data) ? data.CreateTime.toDate() : new Date()))
      this.setState({
        FamilyID:                ("FamilyID"                in data) ? data.FamilyID                  : '',         //診療情報登録対象の家族ID
        MemberID:                ("FamilyID"                in data) ? data.MemberID                  : '',         //診療情報登録対象のメンバーID
        CreateTime:              ("CreateTime"              in data) ? data.CreateTime.toDate()       : new Date(), //診療情報作成日時
        UpdateTime:              ("UpdateTime"              in data) ? data.UpdateTime.toDate()       : new Date(), //診療情報更新日時
        ConsultationTime:        ("ConsultationTime"        in data) ? data.ConsultationTime.toDate() : ("CreateTime" in data) ? data.CreateTime.toDate() : new Date(), //受診日 無い場合は問診票登録日 そのデータもない場合は問診票作成日時
        MedicalSpecialty:        ("MedicalSpecialty"        in data) ? data.MedicalSpecialty          : '',         //診療科目
        Title:                   ("Title"                   in data) ? data.Title                     : '',         //診療情報一覧に表示するタイトル
        HospitalName:            ("HospitalName"            in data) ? data.HospitalName              : '',         //病院名
        Diagnosis:               ("Diagnosis"               in data) ? data.Diagnosis                 : '',         //診断結果
        Reason_for_Consultation: ("Reason_for_Consultation" in data) ? data.Reason_for_Consultation   : '',         //受診理由
        First_FollowUp:          ("First_FollowUp"          in data) ? data.First_FollowUp.path       : '',         //初回経過情報参照先のドキュメントパス
      })

      if ("InterviewSheet" in data) {
        //let Fever_Since     = ("Fever_Since"            in data.InterviewSheet) ? data.CreateTime           : null
        let Fever_Since        = ("Fever_Since"        in data.InterviewSheet) ? data.InterviewSheet.Fever_Since        : null
        let RunnyNose_Since    = ("RunnyNose_Since"    in data.InterviewSheet) ? data.InterviewSheet.RunnyNose_Since    : null
        let Cough_Since        = ("Cough_Since"        in data.InterviewSheet) ? data.InterviewSheet.Cough_Since        : null
        let Headache_Since     = ("Headache_Since"     in data.InterviewSheet) ? data.InterviewSheet.Headache_Since     : null
        let Phlegm_Since       = ("Phlegm_Since"       in data.InterviewSheet) ? data.InterviewSheet.Phlegm_Since       : null
        let Wheeze_Since       = ("Wheeze_Since"       in data.InterviewSheet) ? data.InterviewSheet.Wheeze_Since       : null
        let Vomit_Since        = ("Vomit_Since"        in data.InterviewSheet) ? data.InterviewSheet.Vomit_Since        : null
        let Diarrhea_Since     = ("Diarrhea_Since"     in data.InterviewSheet) ? data.InterviewSheet.Diarrhea_Since     : null
        let Stommachache_Since = ("Stommachache_Since" in data.InterviewSheet) ? data.InterviewSheet.Stommachache_Since : null
        let Eruption_Since     = ("Eruption_Since"     in data.InterviewSheet) ? data.InterviewSheet.Eruption_Since     : null
        let Spasm_Since        = ("Spasm_Since"        in data.InterviewSheet) ? data.InterviewSheet.Spasm_Since        : null
        //console.log('Fever_Since: '     + Object.prototype.toString.call(Fever_Since)    ) // [object String] or [object Object] or [object Null]
        //console.log('RunnyNose_Since: ' + Object.prototype.toString.call(RunnyNose_Since)) // [object String] or [object Object] or [object Null]
        //console.log('Cough_Since: '     + Object.prototype.toString.call(Cough_Since)    ) // [object String] or [object Object] or [object Null]
        //console.log('String: '          + Object.prototype.toString.call(String())       ) // [object String]
        //console.log('Object: '          + Object.prototype.toString.call(new Object())   ) // [object Object]
        //console.log('Date: '            + Object.prototype.toString.call(new Date())     ) // [object Date]
        //console.log('null: '            + Object.prototype.toString.call(null)           ) // [object Null]
        Fever_Since        = this.getSince(Fever_Since,        data.CreateTime.toDate())
        RunnyNose_Since    = this.getSince(RunnyNose_Since,    data.CreateTime.toDate())
        Cough_Since        = this.getSince(Cough_Since,        data.CreateTime.toDate())
        Headache_Since     = this.getSince(Headache_Since,     data.CreateTime.toDate())
        Phlegm_Since       = this.getSince(Phlegm_Since,       data.CreateTime.toDate())
        Wheeze_Since       = this.getSince(Wheeze_Since,       data.CreateTime.toDate())
        Vomit_Since        = this.getSince(Vomit_Since,        data.CreateTime.toDate())
        Diarrhea_Since     = this.getSince(Diarrhea_Since,     data.CreateTime.toDate())
        Stommachache_Since = this.getSince(Stommachache_Since, data.CreateTime.toDate())
        Eruption_Since     = this.getSince(Eruption_Since,     data.CreateTime.toDate())
        Spasm_Since        = this.getSince(Spasm_Since,        data.CreateTime.toDate())

        this.setState({
          Fever_Symptoms:         ("Fever_Symptoms"         in data.InterviewSheet) ? data.InterviewSheet.Fever_Symptoms         : false, //発熱症状の有無
          Fever_Since:            Fever_Since,                                                                                            //発熱いつから
          Fever_Degrees:          ("Fever_Degrees"          in data.InterviewSheet) ? data.InterviewSheet.Fever_Degrees          : 0,     //発熱時の体温
          RunnyNose_Symptoms:     ("RunnyNose_Symptoms"     in data.InterviewSheet) ? data.InterviewSheet.RunnyNose_Symptoms     : false, //鼻水の有無
          RunnyNose_Since:        RunnyNose_Since,                                                                                        //鼻水いつから
          RunnyNose_Color:        ("RunnyNose_Color"        in data.InterviewSheet) ? data.InterviewSheet.RunnyNose_Color        : 0,     //鼻水の色
          Cough_Symptoms:         ("Cough_Symptoms"         in data.InterviewSheet) ? data.InterviewSheet.Cough_Symptoms         : false, //咳の有無
          Cough_Since:            Cough_Since,                                                                                            //咳いつから
          Cough_Type:             ("Cough_Type"             in data.InterviewSheet) ? data.InterviewSheet.Cough_Type             : 0,     //咳の種類
          Headache_Symptoms:      ("Headache_Symptoms"      in data.InterviewSheet) ? data.InterviewSheet.Headache_Symptoms      : false, //頭痛の有無
          Headache_Since:         Headache_Since,                                                                                         //頭痛いつから
          Phlegm_Symptoms:        ("Phlegm_Symptoms"        in data.InterviewSheet) ? data.InterviewSheet.Phlegm_Symptoms        : false, //痰の有無
          Phlegm_Since:           Phlegm_Since,                                                                                           //痰いつから
          Wheeze_Symptoms:        ("Wheeze_Symptoms"        in data.InterviewSheet) ? data.InterviewSheet.Wheeze_Symptoms        : false, //喘鳴（呼吸音異常）の有無
          Wheeze_Since:           Wheeze_Since,                                                                                           //喘鳴（呼吸音異常）いつから
          Vomit_Symptoms:         ("Vomit_Symptoms"         in data.InterviewSheet) ? data.InterviewSheet.Vomit_Symptoms         : false, //嘔吐の有無
          Vomit_Since:            Vomit_Since,                                                                                            //嘔吐いつから
          Vomit_times_per_day:    ("Vomit_times_per_day"    in data.InterviewSheet) ? data.InterviewSheet.Vomit_times_per_day    : 0,     //1日の嘔吐回数
          Diarrhea_Symptoms:      ("Diarrhea_Symptoms"      in data.InterviewSheet) ? data.InterviewSheet.Diarrhea_Symptoms      : false, //下痢の有無
          Diarrhea_Since:         Diarrhea_Since,                                                                                         //下痢いつから
          Diarrhea_times_per_day: ("Diarrhea_times_per_day" in data.InterviewSheet) ? data.InterviewSheet.Diarrhea_times_per_day : 0,     //1日の下痢回数
          Stommachache_Symptoms:  ("Stommachache_Symptoms"  in data.InterviewSheet) ? data.InterviewSheet.Stommachache_Symptoms  : false, //腹痛の有無
          Stommachache_Since:     Stommachache_Since,                                                                                     //腹痛いつから
          Eruption_Symptoms:      ("Eruption_Symptoms"      in data.InterviewSheet) ? data.InterviewSheet.Eruption_Symptoms      : false, //発疹の有無
          Eruption_Since:         Eruption_Since,                                                                                         //発疹いつから
          Spasm_Symptoms:         ("Spasm_Symptoms"         in data.InterviewSheet) ? data.InterviewSheet.Spasm_Symptoms         : false, //けいれんの有無
          Spasm_Since:            Spasm_Since,                                                                                            //けいれん いつから
          Note:                   ("Note"                   in data.InterviewSheet) ? data.InterviewSheet.Note                   : ''     //フリーメモ
        })
      }
    })
  }

  //データベースに登録されている、初回経過情報を読み出す。
  async ReadFollowUp() {
    console.log("ReadFollowUp() start!!")
    var db = firebase.firestore()

    await db.doc(this.state.First_FollowUp).get().then(async(result)=>{
      var data = result.data()
      var View_Appetite       = ("Appetite"  in data) ? data.Appetite  : '' //食欲の値が無い場合は空白にする。
      var View_Moisture       = ("Moisture"  in data) ? data.Moisture  : '' //水分の値が無い場合は空白にする。
      var View_Mood           = ("Mood"      in data) ? data.Mood      : '' //機嫌の値が無い場合は空白にする。
      var FollowUp_source_url = ''                                          //経過登録用
      var FollowUp_image_url  = ("image_url" in data) ? data.image_url : '' //経過登録用画像
      var FollowUp_thum_url   = ("thum_url"  in data) ? data.thum_url  : '' //経過登録用サムネイル画像
      DataImageList.list = []
      DataImageList.UploadList = []

      //食欲について
      switch(View_Appetite) {
        case '◯':
        case 'ok':
          View_Appetite = '◯(普段通り食べれている)'
          break
        case '△':
          View_Appetite = '△(普段の半分くらい食べれている)'
          break
        case '✕':
        case 'no':
          View_Appetite = '✕(全く食べれていない)'
          break
        case '':
        default:
          View_Appetite = ''
          break
      }

      //水分について
      switch(View_Moisture) {
        case '◯':
        case 'ok':
          View_Moisture = '◯(普段通り取れている)'
          break
        case '△':
          View_Moisture = '△(普段の半分くらい取れている)'
          break;
        case '✕':
        case 'no':
          View_Moisture = '✕(全く取れていない)'
          break
        case '':
        default:
          View_Moisture = ''
          break
      }
      
      //機嫌について
      switch(View_Mood) {
        case '◯':
        case 'ok':
          View_Mood = '◯(機嫌が良い)'
          break
        case '△':
          View_Mood = '△(機嫌がやや悪い)'
          break
        case '✕':
        case 'no':
          View_Mood = '✕(機嫌が悪い)'
          break
        case '':
        default:
          View_Mood = ''
          break
      }

      //旧型式の経過画像データに画像登録がある場合は、経過画像リストに読み込む（2021/08/29 追加 高橋）
      if (FollowUp_image_url !== '' && FollowUp_thum_url !== '') {
        //ブラウザ上で一時格納する、画像リストに旧形式で参照データを格納した画像を0番目の画像として格納する
        DataImageList.list.push({
          number:       0,                                                                //画像番号
          source_url:   FollowUp_source_url,                                              //取り込み元画像（旧型式の経過データは空欄として扱う。）
          image_url:    FollowUp_image_url,                                               //経過画像パス
          thum_url:     FollowUp_thum_url,                                                //経過サムネイル画像パス
          CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報が初めて記録された時刻
          UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //経過情報更新時刻
          ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報画像作成時刻
          image_change: false,                                                            //経過登録画像の変更有無（true=変更あり、false=変更なし）
        })

        //データベースにアップロードする画像リストに画像を登録する
        DataImageList.UploadList.push({
          image_url:    FollowUp_image_url,                                               //経過画像パス
          thum_url:     FollowUp_thum_url,                                                //経過サムネイル画像パス
          CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報が初めて記録された時刻
          UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //経過情報更新時刻
          ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報画像作成時刻
        })
      }

      if("images" in data){
        //新形式で保存された画像情報読み込みを行う
        data.images.forEach((image, index)=>{

          //旧形式で保存された画像情報を、新形式用画像情報読み込みしないようにする
          if(index !== '0' && FollowUp_image_url !== image.image_url && FollowUp_thum_url !== image.thum_url){
            DataImageList.list.push({
              number:       index,                                                                //画像番号
              source_url:   '',                                                                   //取り込み元画像（旧型式の経過データは空欄として扱う。）
              image_url:    image.image_url,                                                      //経過画像パス
              thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
              CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
              ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
              image_change: false,                                                                //経過登録画像の変更有無（true=変更あり、false=変更なし）
            })

            DataImageList.UploadList.push({
              image_url:    image.image_url,                                                      //経過画像パス
              thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
              CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
              ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
            })
          }
        })
      }

      this.setState({
        BodyTemperature:     isFinite(data.BodyTemperature) ? data.BodyTemperature : 0, //体温(経過登録用)
        Appetite:            ("Appetite" in data) ? data.Appetite : '', //食欲(経過登録用)
        Moisture:            ("Moisture" in data) ? data.Moisture : '', //水分(経過登録用)
        Mood:                ("Mood"     in data) ? data.Mood     : '', //機嫌(経過登録用)
        View_Appetite:       View_Appetite, //
        View_Moisture:       View_Moisture,
        View_Mood:           View_Mood,
        //FollowUpNote:      '', //メモ(経過登録用)
        FollowUp_image_url:  FollowUp_image_url, //経過登録用画像
        FollowUp_thum_url:   FollowUp_thum_url,  //経過登録用サムネイル画像
        FollowUp_CreateTime: ("CreateTime"   in data) ? data.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
        FollowUp_UpdateTIme: ("UpdateTime"   in data) ? data.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
        FollowUpTime:        ("FollowUpTime" in data) ? data.FollowUpTime.toDate() : new Date(), //経過情報を設定した時刻
        data_image_list:     DataImageList.list,                                                 //propsに経過画像リストを反映する。
      })
    })
  }
  
  // 経過画像変更用「参照」ボタンを押した際の処理
  handleFollowUpReferenceButton = () => {
    var btn_input_file = document.getElementById("file_affectedPartImage");
    btn_input_file.click();
  }

  //経過記録画像変更時の処理
  handleFollowUpImageFileChange = async(e) => {
    console.log('handleFollowUpImageFileChange()')
    var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    var files = e.target.files;
    var fileCount = files.length //読み込んだファイル数
    var num = DataImageList.list.length
    for (var i = 0; i < fileCount; i++ ) {
      var source_url     = createObjectURL(files[i])
      var image_url      = await this.resizeImage(files[i])
      var image_thum_url = await this.resizeThumbnailImage(files[i])
      
      DataImageList.list.push({
        number:       (num + i),                       //画像番号
        source_url:   source_url,                      //取り込み元画像
        image_url:    image_url,                       //経過画像パス
        thum_url:     image_thum_url,                  //経過サムネイル画像パス
        CreateTime:   new Date(),                      //経過情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //経過情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //経過画像撮影日時
        image_change: true,                            //経過登録画像の変更有無（true=変更あり、false=変更なし）
      })

      DataImageList.UploadList.push({
        image_url:    image_url,                       //経過画像パス
        thum_url:     image_thum_url,                  //経過サムネイル画像パス
        CreateTime:   new Date(),                      //経過情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //経過情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //経過画像撮影日時
      })
    }

    this.setState({
      //FollowUp_image_url:      image_url, // 経過画像をブラウザメモリ上で参照するオブジェクトURLで取得する。
      FollowUp_image_url:      '',   // 経過画像をブラウザメモリ上で参照するオブジェクトURLで取得する。javascriptのセキュリティエラー対策で参照URLを登録しない
      FollowUp_image_change:   true, // 経過登録画像の変更有りとして記録する。
      FollowUpImageBox_width:  320,  // 経過画像表示領域の横幅を320pxで設定する。
      FollowUpImageBox_height: 320,  // 経過画像表示領域の高さを320pxで設定する。
      data_image_list: DataImageList.list //propsに経過画像リストを反映する。
    }) 
  }

  //画像リサイズ処理（サムネイル画像）
  resizeThumbnailImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxThumbnailWidth,  //変換後サムネイル画像の最大横幅
      resizer.maxThumbnailHeight, //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  //画像リサイズ処理
  resizeImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxWidth,           //変換後サムネイル画像の最大横幅
      resizer.maxHeight,          //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  //経過画像登録処理
  async RegistFollowUpImage(doc_id){
    console.log('RegistFollowUpImage() doc_id:' + doc_id)
    if(this.state.FollowUp_image_change === true){
      //DataImageList.list.forEach((images)=>{
      await Promise.all(DataImageList.list.map(async(images)=>{
      //for (const images of DataImageList.list) {
        var ShootingTime = `${images.ShootingTime.getFullYear()}/${('0' + (images.ShootingTime.getMonth() + 1)).slice(-2)}/${('0' + images.ShootingTime.getDate()).slice(-2)} ` +
                          ` ${('0' + images.ShootingTime.getHours()).slice(-2)}:${('0' + images.ShootingTime.getMinutes()).slice(-2)}:${('0' + images.ShootingTime.getSeconds()).slice(-2)}`
        //サムネイル画像アップロード
        if(images.thum_url.includes('data:image/jpeg;base64,') && images.image_url.includes('data:image/jpeg;base64,')){ //base64形式フォーマットとなっていることを確認する
          var thum_data        = images.thum_url
          var data             = images.image_url
          var thum_fileName    = images.number + "-thum.jpg"                                                        // サムネイル画像のファイル名を設定
          var fileName         = images.number + ".jpg"                                                             // 画像のファイル名を設定
          var storageRef       = firebase.storage().ref('member_data_image')                                        // Storageの参照先ディレクトリを「member_data_image」に設定する。
          var thum_ImagesRef   = storageRef.child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + thum_fileName) // サムネイル画像のStorageの参照先ファイル名を設定する。
          var ImagesRef        = storageRef.child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + fileName)      // 画像のStorageの参照先ファイル名を設定する。
          var update_thum_url  = ''                                                                                 // サムネイル画像のStorageアクセス用URLを格納する
          var update_image_url = ''                                                                                 // 画像のStorageアクセス用URLを格納する
          
          // サムネイル画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await thum_ImagesRef.putString(thum_data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // 画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await ImagesRef.putString(data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // firebase Storageへ保存したサムネイル画像への参照URLを取得
          await firebase.storage().ref('member_data_image').child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + thum_fileName)
          .getDownloadURL().then((url)=>{
            update_thum_url = url
            DataImageList.list[images.number].thum_url = update_thum_url
            DataImageList.UploadList[images.number].thum_url = update_thum_url
          })
          
          // firebase Storageへ保存した画像への参照URLを取得
          await firebase.storage().ref('member_data_image').child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + fileName)
          .getDownloadURL().then((url)=>{
            update_image_url = url
            DataImageList.list[images.number].image_url = update_image_url
            DataImageList.UploadList[images.number].image_url = update_image_url
          })
        }
      }))
    }
    console.log('RegistFollowUpImage() doc_id:' + doc_id + ' finish !')
  }

  // 経過記録画像読み込み処理
  //loadFollowUpImage(src){
  //  return new Promise((resolve, reject) => {
  //    const img = new Image()
  //    img.onload =() => resolve(img)
  //    img.onerror = (e) => reject(e)
  //    img.src = src
  //  })
  //}

  //問診票の入力データをデータベースに新規登録する。
  async RegistInterview() {
    console.log("RegistInterview() start!!")
    var db = firebase.firestore()

    //作成するドキュメント名（メンバーID + 作成時刻）を準備する。
    var create_time = this.state.CreateTime;
    var MedicalRecord_ID = this.state.MemberID + '_' + 
                           create_time.getFullYear() + ('0' + (create_time.getMonth()+1)).slice(-2) + ('0' + create_time.getDate()).slice(-2) + 
                           ('0' + create_time.getHours()).slice(-2) + ('0' + create_time.getMinutes()).slice(-2) + ('0' + create_time.getSeconds()).slice(-2);

    //問診票登録時に診療情報一覧に表示するタイトルを準備する。
    var MedicalRecordTitle = '';
    if ( this.state.Fever_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '発熱 ' }
    if ( this.state.RunnyNose_Symptoms    === true ) { MedicalRecordTitle = MedicalRecordTitle + '鼻水 ' }
    if ( this.state.Cough_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '咳 ' }
    if ( this.state.Headache_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '頭痛 ' }
    if ( this.state.Phlegm_Symptoms       === true ) { MedicalRecordTitle = MedicalRecordTitle + '痰 ' }
    if ( this.state.Wheeze_Symptoms       === true ) { MedicalRecordTitle = MedicalRecordTitle + 'ゼーゼー息 ' }
    if ( this.state.Vomit_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '嘔吐 ' }
    if ( this.state.Diarrhea_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '下痢 ' }
    if ( this.state.Stommachache_Symptoms === true ) { MedicalRecordTitle = MedicalRecordTitle + '腹痛 ' }
    if ( this.state.Eruption_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '発疹 ' }
    if ( this.state.Spasm_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + 'けいれん ' }

    //新規受診時の場合のみ登録する。
    if ( this.state.Interview_state === 'New' ){
      await db.collection("MedicalRecords").doc(MedicalRecord_ID).set({
        FamilyID:                this.state.FamilyID,                //診療情報登録対象の家族ID
        MemberID:                this.state.MemberID,                //診療情報登録対象のメンバーID
        CreateTime:              this.state.CreateTime,              //診療情報作成日時
        UpdateTime:              this.state.UpdateTime,              //診療情報更新日時
        ConsultationTime:        this.state.ConsultationTime,        //受診日
        MedicalSpecialty:        this.state.MedicalSpecialty,        //診療科目
        Title:                   MedicalRecordTitle,                 //診療情報一覧に表示するタイトル
        HospitalName:            this.state.HospitalName,            //病院名
        Diagnosis:               '',                                 //診断結果
        Reason_for_Consultation: this.state.Reason_for_Consultation, //受診理由
        InterviewSheet:{
          Fever_Symptoms:         this.state.Fever_Symptoms,                        //発熱症状の有無
          Fever_Since:            this.getSinceDate(this.state.Fever_Since),        //発熱いつから
          Fever_Degrees:          this.state.Fever_Degrees,                         //発熱時の体温
          RunnyNose_Symptoms:     this.state.RunnyNose_Symptoms,                    //鼻水の有無
          RunnyNose_Since:        this.getSinceDate(this.state.RunnyNose_Since),    //鼻水いつから
          RunnyNose_Color:        this.state.RunnyNose_Color,                       //鼻水の色
          Cough_Symptoms:         this.state.Cough_Symptoms,                        //咳の有無
          Cough_Since:            this.getSinceDate(this.state.Cough_Since),        //咳いつから
          Cough_Type:             this.state.Cough_Type,                            //咳の種類
          Headache_Symptoms:      this.state.Headache_Symptoms,                     //頭痛の有無
          Headache_Since:         this.getSinceDate(this.state.Headache_Since),     //頭痛いつから
          Phlegm_Symptoms:        this.state.Phlegm_Symptoms,                       //痰の有無
          Phlegm_Since:           this.getSinceDate(this.state.Phlegm_Since),       //痰いつから
          Wheeze_Symptoms:        this.state.Wheeze_Symptoms,                       //喘鳴の有無
          Wheeze_Since:           this.getSinceDate(this.state.Wheeze_Since),       //喘鳴いつから
          Vomit_Symptoms:         this.state.Vomit_Symptoms,                        //嘔吐の有無
          Vomit_Since:            this.getSinceDate(this.state.Vomit_Since),        //嘔吐いつから
          Vomit_times_per_day:    this.state.Vomit_times_per_day,                   //1日の嘔吐回数
          Diarrhea_Symptoms:      this.state.Diarrhea_Symptoms,                     //下痢の有無
          Diarrhea_Since:         this.getSinceDate(this.state.Diarrhea_Since),     //下痢いつから
          Diarrhea_times_per_day: this.state.Diarrhea_times_per_day,                //1日の下痢回数
          Stommachache_Symptoms:  this.state.Stommachache_Symptoms,                 //腹痛の有無
          Stommachache_Since:     this.getSinceDate(this.state.Stommachache_Since), //腹痛いつから
          Eruption_Symptoms:      this.state.Eruption_Symptoms,                     //発疹の有無
          Eruption_Since:         this.getSinceDate(this.state.Eruption_Since),     //発疹いつから
          Spasm_Symptoms:         this.state.Spasm_Symptoms,                        //けいれんの有無
          Spasm_Since:            this.getSinceDate(this.state.Spasm_Since),        //けいれん いつから
          Note:                   this.state.Note                                   //フリーメモ
        }
      })
      this.setState({MedicalRecord_ID: MedicalRecord_ID}) //MedicalRecord_IDをstateで保持する。
    }
  }

  //データベースに診療情報を保存する。（更新）
  async UpdateInterView() {
    console.log("UpdateInterview() start!!")
    var db = firebase.firestore()

    //問診票編集モードの場合
    if(this.state.Interview_state === 'Edit'){
      var MedicalRecordTitle = '';
      //診断結果及び受診理由が未入力の場合は、入力した症状をタイトルにする。
      if(this.state.Diagnosis === '' && this.state.Reason_for_Consultation === ''){
        //問診票更新時に診療情報一覧に表示するタイトルを準備する。
        if ( this.state.Fever_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '発熱 ' }
        if ( this.state.RunnyNose_Symptoms    === true ) { MedicalRecordTitle = MedicalRecordTitle + '鼻水 ' }
        if ( this.state.Cough_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '咳 ' }
        if ( this.state.Headache_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '頭痛 ' }
        if ( this.state.Phlegm_Symptoms       === true ) { MedicalRecordTitle = MedicalRecordTitle + '痰 ' }
        if ( this.state.Wheeze_Symptoms       === true ) { MedicalRecordTitle = MedicalRecordTitle + '呼吸音異常 ' }
        if ( this.state.Vomit_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + '嘔吐 ' }
        if ( this.state.Diarrhea_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '下痢 ' }
        if ( this.state.Stommachache_Symptoms === true ) { MedicalRecordTitle = MedicalRecordTitle + '腹痛 ' }
        if ( this.state.Eruption_Symptoms     === true ) { MedicalRecordTitle = MedicalRecordTitle + '発疹 ' }
        if ( this.state.Spasm_Symptoms        === true ) { MedicalRecordTitle = MedicalRecordTitle + 'けいれん ' }
      } else if(this.state.Diagnosis !== '') {
        //診断結果が入力済みの場合は、診断結果をタイトルとする。
        MedicalRecordTitle = this.state.Diagnosis
      } else if(this.state.Reason_for_Consultation !== ''){
        //診断結果が登録されておらず、受診理由のみ登録されている場合は、受診理由をタイトルにする
        MedicalRecordTitle = this.state.Reason_for_Consultation
      }
      //console.log('this.state.Fever_Since: ' + this.state.Fever_Since)

      await db.collection("MedicalRecords").doc(this.state.MedicalRecord_ID).update({
        FamilyID:                this.state.FamilyID,                //診療情報登録対象の家族ID
        MemberID:                this.state.MemberID,                //診療情報登録対象のメンバーID
        CreateTime:              this.state.CreateTime,              //診療情報作成日時
        UpdateTime:              new Date(),                         //診療情報更新日時
        ConsultationTime:        this.state.ConsultationTime,        //受診日
        MedicalSpecialty:        this.state.MedicalSpecialty,        //診療科目
        Title:                   MedicalRecordTitle,                 //診療情報一覧に表示するタイトル
        HospitalName:            this.state.HospitalName,            //病院名
        Diagnosis:               this.state.Diagnosis,               //診断結果
        Reason_for_Consultation: this.state.Reason_for_Consultation, //受診理由
        First_FollowUp:          firebase.firestore().doc(this.state.First_FollowUp), //初回経過情報参照先パス
        InterviewSheet:{
          Fever_Symptoms:         this.state.Fever_Symptoms,                        //発熱症状の有無
          Fever_Since:            this.getSinceDate(this.state.Fever_Since),        //発熱いつから
          Fever_Degrees:          this.state.Fever_Degrees,                         //発熱時の体温
          RunnyNose_Symptoms:     this.state.RunnyNose_Symptoms,                    //鼻水の有無
          RunnyNose_Since:        this.getSinceDate(this.state.RunnyNose_Since),    //鼻水いつから
          RunnyNose_Color:        this.state.RunnyNose_Color,                       //鼻水の色
          Cough_Symptoms:         this.state.Cough_Symptoms,                        //咳の有無
          Cough_Since:            this.getSinceDate(this.state.Cough_Since),        //咳いつから
          Cough_Type:             this.state.Cough_Type,                            //咳の種類
          Headache_Symptoms:      this.state.Headache_Symptoms,                     //頭痛の有無
          Headache_Since:         this.getSinceDate(this.state.Headache_Since),     //頭痛いつから
          Phlegm_Symptoms:        this.state.Phlegm_Symptoms,                       //痰の有無
          Phlegm_Since:           this.getSinceDate(this.state.Phlegm_Since),       //痰いつから
          Wheeze_Symptoms:        this.state.Wheeze_Symptoms,                       //喘鳴の有無
          Wheeze_Since:           this.getSinceDate(this.state.Wheeze_Since),       //喘鳴いつから
          Vomit_Symptoms:         this.state.Vomit_Symptoms,                        //嘔吐の有無
          Vomit_Since:            this.getSinceDate(this.state.Vomit_Since),        //嘔吐いつから
          Vomit_times_per_day:    this.state.Vomit_times_per_day,                   //1日の嘔吐回数
          Diarrhea_Symptoms:      this.state.Diarrhea_Symptoms,                     //下痢の有無
          Diarrhea_Since:         this.getSinceDate(this.state.Diarrhea_Since),     //下痢いつから
          Diarrhea_times_per_day: this.state.Diarrhea_times_per_day,                //1日の下痢回数
          Stommachache_Symptoms:  this.state.Stommachache_Symptoms,                 //腹痛の有無
          Stommachache_Since:     this.getSinceDate(this.state.Stommachache_Since), //腹痛いつから
          Eruption_Symptoms:      this.state.Eruption_Symptoms,                     //発疹の有無
          Eruption_Since:         this.getSinceDate(this.state.Eruption_Since),     //発疹いつから
          Spasm_Symptoms:         this.state.Spasm_Symptoms,                        //けいれんの有無
          Spasm_Since:            this.getSinceDate(this.state.Spasm_Since),        //けいれん いつから
          Note:                   this.state.Note                                   //フリーメモ
        }
      })
    }
  }

  //経過情報登録
  async RegistFollowUp() {
    console.log("RegistFollowUp() start!!")
    var db = firebase.firestore()

    //作製するドキュメント名（登録時刻）を準備する。
    var create_time = this.state.CreateTime
    var datas_ID = create_time.getFullYear() + ('0' + (create_time.getMonth()+1)).slice(-2) + ('0' + create_time.getDate()).slice(-2) + 
                   ('0' + create_time.getHours()).slice(-2) + ('0' + create_time.getMinutes()).slice(-2) + ('0' + create_time.getSeconds()).slice(-2);
    
    //新規受診時の場合のみ登録する
    if ( this.state.Interview_state === 'New' ){
      await db.collection("MedicalRecords").doc(this.state.MedicalRecord_ID).collection("datas").doc(datas_ID).set({
        BodyTemperature: Number(this.state.BodyTemperature), //体温（数値型）
        Appetite:        this.state.Appetite,                //食欲
        Moisture:        this.state.Moisture,                //水分
        Mood:            this.state.Mood,                    //機嫌
        Note:            this.state.FollowUpNote,            //メモ
        FollowUpTime:    this.state.FollowUpTime,            //経過記録時刻
        UpdateTime:      this.state.FollowUp_UpdateTIme,     //更新時刻
        CreateTime:      this.state.FollowUp_CreateTime,     //作製時刻
        images:          DataImageList.UploadList,           //登録画像リスト
        //thum_url:        this.state.FollowUp_thum_url,       //経過記録サムネイル画像
        //image_url:       this.state.FollowUp_image_url,      //経過記録画像URL
      })

      //初回経過情報登録先のドキュメントパスをstateに格納する。
      this.setState({First_FollowUp: 'MedicalRecords/' + this.state.MedicalRecord_ID + '/datas/' + datas_ID})

      //初回経過情報登録先を医療情報のドキュメントに追記する。
      await db.collection("MedicalRecords").doc(this.state.MedicalRecord_ID).update({
        First_FollowUp: firebase.firestore().doc(this.state.First_FollowUp),
      })
    }
  }

  //経過情報更新
  async UpdateFollowUp() {
    console.log("UpdateFollowUp() start!!")
    var db = firebase.firestore()

    //編集モードの場合のみ更新する
    if (this.state.Interview_state === 'Edit' && this.state.First_FollowUp !== ''){
      await db.doc(this.state.First_FollowUp).update({
        BodyTemperature: Number(this.state.BodyTemperature), //体温（数値型）
        Appetite:        this.state.Appetite,                //食欲
        Moisture:        this.state.Moisture,                //水分
        Mood:            this.state.Mood,                    //機嫌
        Note:            this.state.FollowUpNote,            //メモ
        FollowUpTime:    this.state.FollowUpTime,            //経過記録時刻
        UpdateTime:      this.state.FollowUp_UpdateTIme,     //更新時刻
        CreateTime:      this.state.FollowUp_CreateTime,     //作製時刻
        images:          DataImageList.UploadList,           //登録画像リスト
        //thum_url:        this.state.FollowUp_thum_url,       //経過記録サムネイル画像
        //image_url:       this.state.FollowUp_image_url,      //経過記録画像URL
      })
    }
  }

  //画面表示時に読み出される。
  async componentDidMount(){
    //問診票新規登録モードの場合
    if(this.state.Interview_state === 'New'){
      this.setState({
        EditShow:     'block', //問診票 新規登録＋編集共通表示【表示】
        NewOnlyShow:  'block', //問診票 新規登録画面　　　　　【表示】
        EditOnlyShow: 'none',  //問診票 編集画面　　　　　　　【非表示】
        ViewShow:     'none'   //問診票 表示画面　　　　　　　【非表示】
      })
    }

    //問診票表示モードの場合
    if(this.state.Interview_state === 'View'){
      this.setState({
        EditShow:     'none',  //問診票 新規登録＋編集共通表示【非表示】
        NewOnlyShow:  'none',  //問診票 新規登録画面　　　　　【非表示】
        EditOnlyShow: 'none',  //問診票 編集画面　　　　　　　【非表示】
        ViewShow:     'block'  //問診票 表示画面　　　　　　　【表示】
      })
      await this.ReadInterview()     //データベースから登録済みの問診票データを読み出す。
      if(this.state.First_FollowUp !== ''){
        //初回経過情報が登録されている場合は読み出す。
        await this.ReadFollowUp()    //データベースから登録済みの初回経過情報を読み出す。
      }
    }

    //問診票編集モードの場合
    if(this.state.Interview_state === 'Edit'){
      this.setState({
        EditShow:     'block', //問診票 新規登録＋編集共通表示【表示】
        NewOnlyShow:  'none',  //問診票 新規登録画面　　　　　【非表示】
        EditOnlyShow: 'block', //問診票 編集画面　　　　　　　【表示】
        ViewShow:     'none',  //問診票 表示画面　　　　　　　【非表示】
      })
      await this.ReadInterview()     //データベースから登録済みの問診票データを読み出す。
      if(this.state.First_FollowUp !== ''){
        //初回経過情報が登録されている場合は読み出す。
        await this.ReadFollowUp()    //データベースから登録済みの初回経過情報を読み出す。
        if(this.state.FollowUp_image_url !== ''){
          //初回経過画像が登録されている場合は描画する。
          this.setState({
            FollowUpImageBox_width:  320, // 経過画像表示領域の横幅を320pxで設定する。
            FollowUpImageBox_height: 320, // 経過画像表示領域の高さを320pxで設定する。
          })
        }
      }
    }
  }

  render(){
    //問診票登録日時表示用
    let CreateTime            = this.state.CreateTime
    let interview_CreateTime2 = `${CreateTime.getFullYear()}/${('0' + (CreateTime.getMonth() + 1)).slice(-2)}/${('0' + CreateTime.getDate()).slice(-2)} ` +
                                  ` (${["日", "月", "火", "水", "木", "金", "土"][CreateTime.getDay()]}) ${('0' + CreateTime.getHours()).slice(-2)}:${('0' + CreateTime.getMinutes()).slice(-2)}`

    let ConsultationTime      = this.state.ConsultationTime  //受診日時
    let ConsultationTime2     = `${ConsultationTime.getFullYear()}-${('0' + (ConsultationTime.getMonth() + 1)).slice(-2)}-${('0' + ConsultationTime.getDate()).slice(-2)}T${('0' + ConsultationTime.getHours()).slice(-2)}:${('0' + ConsultationTime.getMinutes()).slice(-2)}`
    //症状いつからのリスト
    /*
    const Since_List = [];
    Since_List.push(<MenuItem key='0' value=''>　</MenuItem>)
    Since_List.push(<MenuItem key='1' value='今日から'>今日から</MenuItem>)
    Since_List.push(<MenuItem key='2' value='昨日から'>昨日から</MenuItem>)
    Since_List.push(<MenuItem key='3' value='２、３日前から'>２、３日前から</MenuItem>)
    Since_List.push(<MenuItem key='4' value='４、５日前から'>４、５日前から</MenuItem>)
    Since_List.push(<MenuItem key='5' value='１週間前から'>１週間前から</MenuItem>)
    Since_List.push(<MenuItem key='6' value='２週間前から'>２週間前から</MenuItem>)
    Since_List.push(<MenuItem key='7' value='１カ月前から'>１カ月前から</MenuItem>)
    Since_List.push(<MenuItem key='8' value='もっと前から'>もっと前から</MenuItem>)
    */

    //発熱時の体温の選択肢
    const FeverDegreesList = [];
    FeverDegreesList.push(<MenuItem key='0.0' value='0.0'>　</MenuItem>)
    for(let temp = Number(35.5).toFixed(1); temp <= 42.0; temp = Number(Number(temp) + 0.1).toFixed(1)){ //通常時の体温を記録する為、35.5℃から
      FeverDegreesList.push(<MenuItem key={temp} value={temp}>{temp}</MenuItem>)
    }

    //鼻水の色の選択肢
    const RunnyNoseColorList = [];
    RunnyNoseColorList.push(<MenuItem key='0' value=''>　</MenuItem>)
    RunnyNoseColorList.push(<MenuItem key='1' value='水っぱな'>水っぱな</MenuItem>)
    RunnyNoseColorList.push(<MenuItem key='2' value='黄色'>黄色</MenuItem>)
    RunnyNoseColorList.push(<MenuItem key='3' value='青っぱな'>青っぱな</MenuItem>)
    
    //咳の種類
    const CoughTypeList = []
    CoughTypeList.push(<MenuItem key='0' value=''>　</MenuItem>)
    CoughTypeList.push(<MenuItem key='1' value='コンコン'>コンコン</MenuItem>)
    CoughTypeList.push(<MenuItem key='2' value='ゲホゲホ'>ゲホゲホ</MenuItem>)
    CoughTypeList.push(<MenuItem key='3' value='ケンケン'>ケンケン</MenuItem>)

    //1日の嘔吐の回数の選択肢
    const VomitTimesList = [];
    VomitTimesList.push(<MenuItem key={0} value={0}>　</MenuItem>)
    for(let i=1; i<=10; i++ ){
      VomitTimesList.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    //1日の下痢の回数の選択肢
    const DiarrheaTimesList = [];
    DiarrheaTimesList.push(<MenuItem key={0} value={0}>　</MenuItem>)
    for(let i=1; i<=10; i++ ){
      DiarrheaTimesList.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }

    //食欲（経過登録用）の選択肢
    const AppetiteList = []
    AppetiteList.push(<MenuItem key='0' value=''>　</MenuItem>)
    AppetiteList.push(<MenuItem key='1' value='◯'>◯(普段通り食べれている)</MenuItem>)
    AppetiteList.push(<MenuItem key='2' value='△'>△(普段の半分くらい食べれている)</MenuItem>)
    AppetiteList.push(<MenuItem key='3' value='✕'>✕(全く食べれていない)</MenuItem>)

    //水分（経過登録用）の選択肢
    const MoistureList = []
    MoistureList.push(<MenuItem key='0' value=''>　</MenuItem>)
    MoistureList.push(<MenuItem key='1' value='◯'>◯(普段通り取れている)</MenuItem>)
    MoistureList.push(<MenuItem key='2' value='△'>△(普段の半分くらい取れている)</MenuItem>)
    MoistureList.push(<MenuItem key='3' value='✕'>✕(全く取れていない)</MenuItem>)

    //機嫌（経過登録用）の選択肢
    const MoodList = []
    MoodList.push(<MenuItem key='0' value=''>　</MenuItem>)
    MoodList.push(<MenuItem key='1' value='◯'>◯(機嫌が良い)</MenuItem>)
    MoodList.push(<MenuItem key='2' value='△'>△(機嫌がやや悪い)</MenuItem>)
    MoodList.push(<MenuItem key='3' value='✕'>✕(機嫌が悪い)</MenuItem>)

    //経過画像リスト確認用
    const FollowUpImgView = []
    this.state.data_image_list.forEach((images)=>{
      FollowUpImgView.push(
        <Grid item xs={12} key={'DataImage_' + images.number}>
          <img style={{width: '100%', margin: '5px 0'}} src={images.image_url} alt={images.CreateTime.toLocaleDateString()} />
        </Grid>
      )
    })

    return (
      /*<Modal.Body>*/
      <React.Fragment>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <div style={{display: this.state.NewOnlyShow}}>
            <Grid item xs={12}> {/* 登録する家族の名前を表示する。 */}
              <Typography variant="h6">
                問診票登録：　{this.state.DisplayName}
              </Typography>
            </Grid>
          </div>

          {/* 編集時のみの表示 */}
          <div style={{display: this.state.EditOnlyShow}}>
            <Grid item xs={12}> {/* 家族の名前を表示する。 */}
              <Typography variant="h6">
                問診票：　{this.state.DisplayName}
              </Typography>
            </Grid>
          </div>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          {/* 診断結果 */}
          {/* 診断結果は2021/08/13に問診票編集画面で非表示化
          <Grid item xs={12} style={{display: this.state.EditOnlyShow}}>
            <TextField id="input_Diagnosis" className="w-100" label="診断結果"
              value={this.state.Diagnosis} onChange={(e)=>this.handleEditDiagnosis(e)} />
          </Grid>
          */}

          {/* 受診医療機関 */}
          {/*
          <Grid item xs={12}>
            <TextField id="input_Hospital" className="w-100" label="受診医療機関"
              value={this.state.HospitalName} onChange={(e)=>this.handleEditHospitalName(e)} />
          </Grid>
          */}

          {/* 診療科 */}
          {/*
          <Grid item xs={12}>
            <TextField id="input_MedicalSpecialty" className="w-100" label="診療科"
              value={this.state.MedicalSpecialty} onChange={(e)=>this.handleEditMedicalSpecialty(e)} />
          </Grid>
          */}

          {/* 問診票登録日時 */}
          <Grid item xs={12}>
            {interview_CreateTime2}
          </Grid>

          {/* 受診(予定)日 */}
          <Grid item xs={12} style={{padding: '1em 8px 0px', fontSize: '1.1rem'}}>受診日</Grid>
          <Grid item xs={12}>
            <TextField id="datetime-local" label="受診日" type="datetime-local" value={ConsultationTime2} 
              InputProps={{inputProps: {step: 300,}}} InputLabelProps={{shrink: true,}} onChange={(e)=>this.handleEditConsultationTime(e)} />
          </Grid>

          {/* 体温の入力 */}
          <Grid item xs={6}>
            <TextField select id="sel_Fever_Degrees" label="体温" className="w-100" value={Number(this.state.Fever_Degrees).toFixed(1)}
              onChange={(e)=>this.handleFeverDegreesChange(e)} InputProps={{startAdornment:<InputAdornment position="start" >℃</InputAdornment>}}>
              {FeverDegreesList}
            </TextField>
          </Grid>
          <Grid item xs={6} />

          {/* 受診理由 */}
          <Grid item xs={12}>
            <TextField id="Reason_for_Consultation" label="受診理由" className="w-100" value={this.state.Reason_for_Consultation}
              onChange={(e)=>this.Edit_Reason_for_Consultation(e)} />
          </Grid>
          

          {/* 発熱 */}
          <Grid item xs={12} style={{padding: '1em 8px 0px', fontSize: '1.1rem'}}>発熱</Grid>
          {/* 発熱開始時期を選択 */}
          {/*
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}>
            <TextField select id="sel_Fever_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Fever_Since} onChange={(e)=>this.handleFeverSinceChange(e)}>
              {Since_List}
            </TextField>
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />
          */}

          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 発熱開始時期を選択 */}
            <TextField type="date" id="sel_Fever_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Fever_Since} onChange={(e)=>this.handleFeverSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />

          {/* 鼻水 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>鼻水はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 鼻水開始時期を選択 */}
            <TextField type="date" id="sel_RunnyNose_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.RunnyNose_Since} onChange={(e)=>this.handleRunnyNoseSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 鼻水の色 */}
            <TextField select id="sel_RunnyNose_Color" label="種類" className="w-100"
              value={this.state.RunnyNose_Color} onChange={(e)=>this.handleRunnyNoseColorChange(e)}>
              {RunnyNoseColorList}
            </TextField>
          </Grid>

          {/* 咳 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>咳はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 咳開始時期を選択 */}
            <TextField type="date" id="sel_Cough_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Cough_Since} onChange={(e)=>this.handleCoughSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 咳の種類 */}
            <TextField select id="sel_Cough_Type" label="種類" className="w-100"
              value={this.state.Cough_Type} onChange={(e)=>this.handleCoughTypeChange(e)}>
              {CoughTypeList}
            </TextField>
          </Grid>

          {/* 頭痛 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>頭痛はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 頭痛開始時期を選択 */}
            <TextField type="date" id="sel_Headache_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Headache_Since} onChange={(e)=>this.handleHeadacheSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />

          {/* 痰 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>痰（たん）は出てますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 痰開始時期を選択 */}
            <TextField type="date" id="sel_Phlegm_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Phlegm_Since} onChange={(e)=>this.handlePhlegmSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} /> {/* 痰の種類（透明、黄、茶、緑）を選べるようにする */}

          {/* 喘鳴（ゼーゼー息）⇒呼吸音異常 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>呼吸音に異変はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 呼吸音異常の開始時期を選択 */}
            <TextField type="date" id="sel_Wheeze_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Wheeze_Since} onChange={(e)=>this.handleWheezeSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />

          {/* 嘔吐 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>嘔吐（おうと）はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 嘔吐開始時期を選択 */}
            <TextField type="date" id="sel_Vomit_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Vomit_Since} onChange={(e)=>this.handleVomitSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 1日の嘔吐の回数 */}
            <TextField select id="sel_Vomit_Times" label="1日の回数" className="w-100" 
              value={this.state.Vomit_times_per_day} onChange={(e)=>this.handleVomitTimesChange(e)}
              InputProps={{endAdornment:<InputAdornment position="start" style={{paddingRight: '1em'}}>回</InputAdornment>}}
            >
              {VomitTimesList}
            </TextField>
          </Grid>

          {/* 下痢 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>下痢（げり）はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 下痢開始時期を選択 */}
            <TextField type="date" id="sel_Diarrhea_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Diarrhea_Since} onChange={(e)=>this.handleDiarrheaSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 1日の下痢の回数 */}
            <TextField select id="sel_Diarrhea_Times" label="1日の回数" className="w-100" 
              value={this.state.Diarrhea_times_per_day} onChange={(e)=>this.handleDiarrheaTimesChange(e)}
              InputProps={{endAdornment:<InputAdornment position="start" style={{paddingRight: '1em'}}>回</InputAdornment>}}
            >
              {DiarrheaTimesList}
            </TextField>
          </Grid>

          {/* 腹痛 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>腹痛はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 腹痛開始時期を選択 */}
            <TextField type="date" id="sel_Stommachache_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Stommachache_Since} onChange={(e)=>this.handleStommachacheSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />

          {/* 発疹 */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>発疹（ほっしん）はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* 発疹開始時期を選択 */}
            <TextField type="date" id="sel_Eruption_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Eruption_Since} onChange={(e)=>this.handleEruptionSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />

          {/* けいれん */}
          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1.1rem'}}>痙攣（けいれん）はありますか？</Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}}> {/* けいれん開始時期を選択 */}
            <TextField type="date" id="sel_Spasm_Since" label="いつから" style={{marginLeft: '1em', width: 'calc(100% - 1em)'}}
              value={this.state.Spasm_Since} onChange={(e)=>this.handleSpasmSinceChange(e)} InputLabelProps={{shrink: true}} />
          </Grid>
          <Grid item xs={6} style={{padding: '0px 8px 8px'}} />


          <Grid item xs={12} style={{padding: '1.7em 8px 0px', fontSize: '1rem'}}>食欲・水分摂取・機嫌はいかがでしょうか？</Grid>
          {/* 食欲 */}
          <Grid item xs={4} style={{padding: '0px 8px 8px'}}> {/* 食欲の状態を選択する */}
            <TextField select id="sel_Appetite" label="食欲" className="w-100"
              value={this.state.Appetite} onChange={(e)=>this.handleAppetiteChange(e)}>
                {AppetiteList}
              </TextField>
          </Grid>

          {/* 水分 */}
          <Grid item xs={4} style={{padding: '0px 8px 8px'}}> {/* 水分の状態を選択する */}
            <TextField select id="sel_Moisture" label="水分摂取" className="w-100"
              value={this.state.Moisture} onChange={(e)=>this.handleMoistureChange(e)}>
              {MoistureList}
            </TextField>
          </Grid>

          {/* 機嫌 */}
          <Grid item xs={4} style={{padding: '0px 8px 8px'}}> {/* 機嫌を選択する */}
            <TextField select id="sel_Mood" label="機嫌" className="w-100"
              value={this.state.Mood} onChange={(e)=>this.handleMoodChange(e)}>
              {MoodList}
            </TextField>
          </Grid>
          {/* 経過画像登録 */}
          <Grid item xs={12}>症状がわかる画像があれば登録しましょう</Grid>
          <div id="FollowUpImg">{FollowUpImgView}</div>
          <Grid item xs={12} style={{display: 'block'}}>
            {/* 読み込みした経過画像を表示する。 */}
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" color="primary" onClick={this.handleFollowUpReferenceButton}>
              参照
            </Button>
            <input type="file" multiple id="file_affectedPartImage" accept=".jpg,.jpeg,.png,.webp,.svg" style={{display: 'none'}} onChange={(e)=>this.handleFollowUpImageFileChange(e)} />
          </Grid>
          {/* フリーメモ */}
          <Grid item xs={12}> {/* 症状などの詳細をメモとして入力する */}
            <TextField id="Free_Note" label="メモ（症状・様子等）" className="w-100"
              value={this.state.Note} onChange={(e)=>this.handleNote(e)} 
              multiline rows={5} variant="outlined" /> {/* 5行分のスペースを表示領域とする */}
          </Grid>
        </Grid>

        <Toolbar />

        <Grid container justifyContent="space-between" alignItems="flex-start">
          {/* 新規登録時のみ、「戻る」ボタンを表示する。 */}
          <div style={{display: this.state.NewOnlyShow}}>
            <Button variant="outlined" color="primary" onClick={this.handleModalClose}>戻る</Button>
          </div>

          {/* 編集時のみ、「キャンセル」ボタン（モーダルを閉じ、症状カード画面に戻る）を表示する。 */}
          <div style={{display: this.state.EditOnlyShow}}>
            {/* <Button variant="outlined" color="primary" onClick={this.handleView}>キャンセル</Button> */}
            <Button variant="outlined" color="primary" onClick={this.handleModalClose}>キャンセル</Button>
          </div>
          
          {/* 新規登録時のみ、「登録」ボタンを表示する。 */}
          <div style={{display: this.state.NewOnlyShow}}>
            <Button variant="outlined" color="primary" onClick={this.handleRegist}>登録</Button>
          </div>

          {/* 編集時のみ、「保存」ボタンを表示する。 */}
          <div style={{display: this.state.EditOnlyShow}}>
            <Button variant="outlined" color="primary" onClick={this.handleUpdate}>保存</Button>
          </div>
        </Grid>
      </React.Fragment>
      /* </Modal.Body> */
    )
  }
}

export default withStyles(useStyles)(withRouter(Interview))