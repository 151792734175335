import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyA4L0L4cUZWDxvWCaf0iP1QBpVRhqpa1nE",
  authDomain: "famikar-app.firebaseapp.com",
  databaseURL: "https://famikar-app.firebaseio.com",
  projectId: "famikar-app",
  storageBucket: "famikar-app.appspot.com",
  messagingSenderId: "748448731",
  appId: "1:748448731:web:38f81b0d726e9994b295fe",
  measurementId: "G-489RZD6GH7"
};

firebase.initializeApp(firebaseConfig);
//firebase.analytics();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
