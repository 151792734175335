import React          from "react";
import { withRouter } from 'react-router';
import Button         from '@material-ui/core/Button';
//import Typography     from '@material-ui/core/Typography'; //2021.11.27 Typography無効化に伴い、コメントアウト
import TextField      from '@material-ui/core/TextField';
import Grid           from '@material-ui/core/Grid'
import MenuItem       from '@material-ui/core/MenuItem';
import queryString    from 'query-string'
//import {Modal}        from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase       from'firebase/app';
import 'firebase/auth';

/**
 * 診療結果登録・更新・削除モーダル用のコンポーネント
 */
class ModalDiagnosis extends React.Component {
  constructor(props) {
    super(props);

    // URL情報から更新・削除対象のDocIdを取得
    let docId = '';
    if(window.location.search){
      docId = queryString.parse(window.location.search).data||null;
    }
    //if(this.props.location.search){
    //  docId = queryString.parse(this.props.location.search).data||null;
    //}
    
    this.state = {
      //DisplayName:      this.props.location.state.DisplayName,     // 表示対象者の名前
      //MedicalRecordId:  this.props.location.state.MedicalRecordId, // 症状カードID
      DisplayName:      props.DisplayName, // 表示対象者の名前
      MedicalRecordId:  props.MedicalRecordId, // 症状カードID
      ResultId:         docId,                                     // 診療結果ID(更新・削除用)
      ConsultationTime: this.getDateDisplayFormat(),               // 受診時刻(自動入力)
      HospitalName:     '', //病院名
      MedicalSpecialty: '', //診療科目
      DoctorName:       '', //先生の名前
      Diagnosis:        '', //診断結果
      Instructions:     '', //医者の指示
      Note:             '', //メモ
      CreateTime:       '', //作成日時
      UpdateTime:       '', //更新日時
    }
  }

  /**
   * 画面上に表示される各要素の選択肢一覧
   * (下記の設定を変更すれば、render()部の修正は不要)
   */
  choicesMap = {
    MedicalSpecialty: [ // 診療科目
      "内科",
      "外科",
      "小児科",
      "呼吸器科",
      "消化器科",
      "循環器科",
      "眼科",
      "耳鼻咽喉科",
      "皮膚科"],
    Diagnosis: [ // 診断結果
      "経過観察",
      "要治療",
      "1週間以内に再来院",
      "1ヶ月以内に再来院",
      "処方箋のみ"],
    x: ["-"]
  }

  /**
   * 登録・削除の場合、登録済みデータをfirestoreから取得
   * (コンポーネントマウント時に実行)
   */
  async componentDidMount() {

    // firestoreから現在の登録データを取得し、モーダル表示画面に反映
    if(this.state.ResultId){
      const db = firebase.firestore();
      const modalComponent = this;  // componentDidMount内でthis.setStateを直接呼べないため変数を挟む

      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
        .collection("MedicalResults").doc(this.state.ResultId)
        .get().then(function(doc) {
          if(doc.data()){
            modalComponent.setState({
              ConsultationTime: doc.data().ConsultationTime  || '', //受診時刻
              HospitalName:     doc.data().HospitalName      || '', //病院名
              MedicalSpecialty: doc.data().MedicalSpecialty  || '', //診療科目
              DoctorName:       doc.data().DoctorName        || '', //先生の名前
              Diagnosis:        doc.data().Diagnosis         || '', //診断結果
              Instructions:     doc.data().Instructions      || '', //医者の指示
              Note:             doc.data().Note              || '', //メモ
              CreateTime:       doc.data().CreateTime.toDate()    || '', //作成日時
              UpdateTime:       doc.data().UpdateTime.toDate()    || '', //更新日時
              });
          }
        });
    }
  }

  /**
   * キャンセルボタン用関数
   */
  handleGoBack = () =>{
    this.props.history.goBack();
  }

  //モーダルを非表示状態に切り替える
  handleModalClose = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // 症状カード画面のモーダルを閉じる
    }
  }

  //モーダルを非表示状態に切り替え、症状カード画面で再読み込みする
  handleReloadModalClose = () => {
    if(typeof this.props.handleReloadModalClose == 'function'){
      this.props.handleReloadModalClose() // 症状カード画面のモーダルを閉じ、画面内データを再読み込みする。
    }
  }

  /**
   * 経過情報登録モーダルの各選択肢の変更を制御する関数
   * (TextFieldのname要素とstateの名前を対応付けて処理を共通化)
   * @param {*} e 各選択肢のTextField要素
   */
  //
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  /**
   * 現在日付を[YYYYMMDDhhmmss]形式で返す関数
   */
  getDateIdFormat = () => {
    var now = new Date();
    return "" + now.getFullYear()
      + (now.getMonth()+1).toString().padStart(2, '0')
      + now.getDate().toString().padStart(2, '0')
      + now.getHours().toString().padStart(2, '0')
      + now.getMinutes().toString().padStart(2, '0')
      + now.getSeconds().toString().padStart(2, '0');
  }

  /**
   * 現在日付を[MM月DD日（曜日）hh時mm分]形式で返す関数
   */
  getDateDisplayFormat = () => {
    var now = new Date();
    return (now.getMonth()+1).toString().padStart(2, '0') + "月"
      + now.getDate().toString().padStart(2, '0') + "日（"
      + [ "日", "月", "火", "水", "木", "金", "土" ][now.getDay()] + "）"
      + now.getHours().toString().padStart(2, '0') + "時"
      + now.getMinutes().toString().padStart(2, '0') + "分"
  }

  /**
   * 削除ボタン押下時の処理
   */
  handleDeleteButtonClick = () => {
    // 削除実行の確認用ポップアップ
    if (!window.confirm("診断結果を削除します")) {
      alert("削除をキャンセルしました");
      this.props.history.goBack();
      return;
    }

    this.deleteResult();
  }
  async deleteResult(){
    let db = firebase.firestore()

    //削除処理は前画面からデータを引き継いでいる場合のみ実行可能
    if(this.state.ResultId){
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("MedicalResults").doc(this.state.ResultId).delete()
      .then(()=>{
        alert("診察結果を削除しました")
      })
      .catch(function(error) {
        alert("削除に失敗しました")
        console.log("診察結果の削除に失敗しました ", error);
      });
    }
    this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
    //this.props.history.goBack();
  }

  /**
   * 登録ボタン押下時の処理
   */
  handleRegistButtonClick = () => {
    this.registResult();
  }
  async registResult(){
    let db = firebase.firestore()
    let doc_id = this.getDateIdFormat()

    const setData = {  // NULLチェックは右記のショートハンドで実施([条件式]?[true]:[false] → ||)
      ConsultationTime: this.state.ConsultationTime || '', //受診時刻
      BodyTemperature:  this.state.BodyTemperature  || '', //体温（数値型）
      HospitalName:     this.state.HospitalName     || '', //病院名
      MedicalSpecialty: this.state.MedicalSpecialty || '', //診療科目
      DoctorName:       this.state.DoctorName       || '', //先生の名前
      Diagnosis:        this.state.Diagnosis        || '', //診断結果
      Instructions:     this.state.Instructions     || '', //医者の指示
      Note:             this.state.Note             || '', //メモ
      UpdateTime:       firebase.firestore.FieldValue.serverTimestamp(), //作製時刻（firebaseのサーバー時刻）
      CreateTime:      this.state.CreateTime        || firebase.firestore.FieldValue.serverTimestamp(),  //作製時刻（firebaseのサーバー時刻）
    }

    if(this.state.ResultId){ //更新時
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("MedicalResults").doc(this.state.ResultId)
      .set(setData)
      .then((result)=>{
        alert("診察結果を更新しました")
        doc_id = this.state.ResultId
        //this.props.history.goBack();
        this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
    })
      .catch(function(error) {
        alert("更新に失敗しました")
        console.log("診察結果の更新に失敗しました ", error);
      });
    }
    else{ //新規登録時
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("MedicalResults").doc(doc_id)
      .set(setData).then((result)=>{
          alert("診察結果を登録しました")
          //this.props.history.goBack();
          this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
      })
      .catch(function(error) {
        alert("登録に失敗しました")
        console.log("診察結果の登録に失敗しました ", error);
      });
    }
  }

  render(){
    return (
      <React.Fragment>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            診察：先生に言われたことを残しておきましょう
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {this.state.DisplayName}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth required id="ConsultationTime" label="受診日時" name="ConsultationTime" value={this.state.ConsultationTime} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
        
          <Grid item xs={12}>
            <TextField fullWidth id="inputHospitalName" label="受診医療機関" name="HospitalName" value={this.state.HospitalName} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField select fullWidth id="selMedicalSpecialty" label="診療科" name="MedicalSpecialty" value={this.state.MedicalSpecialty} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.MedicalSpecialty.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="inputDoctorName" label="先生の名前" name="DoctorName" value={this.state.DoctorName} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="selDiagnosis" label="診察結果" name="Diagnosis" value={this.state.Diagnosis} onChange={(e)=>this.handleChange(e)}/>
            {/* <TextField select fullWidth id="selDiagnosis" label="診察結果" name="Diagnosis" value={this.state.Diagnosis} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Diagnosis.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField> */}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth id="inputInstructions" label="先生からの指示" name="Instructions" value={this.state.Instructions} onChange={(e)=>this.handleChange(e)}/>
          </Grid>

          <Grid item xs={12}>
            <TextField fullWidth id="inputNote" label="メモ" name="Note" value={this.state.Note} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
        </Grid>
        <p> </p>
        <Grid container justifyContent="space-between" alignItems="flex-start">
          <Button variant="outlined" color="secondary" onClick={this.handleDeleteButtonClick}>削除</Button>
          <Button variant="outlined" color="primary" onClick={this.handleModalClose}>キャンセル</Button>
          <Button variant="outlined" color="primary" onClick={this.handleRegistButtonClick}>登録</Button>
        </Grid>
      </React.Fragment>
    );
  }
}
export default withRouter(ModalDiagnosis)