import React          from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Card           from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent    from '@material-ui/core/CardContent';
import CardMedia      from '@material-ui/core/CardMedia';
import Typography     from '@material-ui/core/Typography'
import 'firebase/storage';
//import { Grid } from '@material-ui/core';
//import CardActions from '@material-ui/core/CardActions';
//import Button from '@material-ui/core/Button';

const useStyles = {
  root: {
    display: 'flex',
    maxHeight: 150,
    padding: 0,
    margin: 0
  },
};

class MemberData extends React.Component { 
  
  constructor(props) {
    super(props);
    this.state = {
      Name: props.memberName,
      ImageUrl:        props.member_url,
      //Birthday:        props.birthday,
      //SymptomsCardNum: props.symptomsCardNum,
      memberID:        props.memberID,
      familyID:        props.familyID,
      uid:             props.uid
    }
    console.log("MemberData.js Name: ", this.state.Name)
    //console.log("MemberData.js SymptomsCardNum: ", this.state.SymptomsCardNum)
  }
  handlerUserButtonClick = () =>{
    console.log("2 MemberData handlerUserButtonClick memberID["+this.state.memberID+"]")
    this.props.history.push('/PersonalHome/' + this.state.memberID)
  }

  handlerEditButtonClick = (memberID, familyID) =>{
    console.log("2 MemberData handlerEditButtonClick memberID["+this.state.memberID+"]")
    //this.props.history.push('/person/'+this.state.uid)
    this.props.history.push({
      pathname: '/familyregist',
      state: {
        uid: this.state.uid, //ログインユーザーuid
        familyID:        familyID,        //診療情報登録対象の家族ID
        memberID:        memberID,        //診療情報登録対象のメンバーID
        isEdit: true
      }
    })
  }

  render(){
    return (
      <Card className={this.props.classes.root}>
        <CardMedia className={this.props.classes.cover}
          component="img"
          alt={this.state.Name}
          image={this.state.ImageUrl}
          title={this.state.Name}
          style={{maxHeight:200, minHeight:200, maxWidth:200, minWidth:100, margin: 3}}
        />
        <CardActionArea onClick={this.handlerUserButtonClick}>
          <CardContent className={this.props.classes.content}>
            <div className="text-left textTitle famikarColor">
              <Typography variant="subtitle1" className="border-bottom" component="div">
                <div>
                  {this.state.Name}
                </div>
              </Typography>
              <Typography component="div">
                受診一覧
                {/*生年月日: {this.state.Birthday}<br />*/}
                {/*症状カード数： {this.state.SymptomsCardNum}枚<br />*/}
              </Typography>
            </div>
          </ CardContent>
        </CardActionArea>
        <CardActionArea color="primary" onClick={()=>{this.handlerEditButtonClick(this.state.memberID, this.state.familyID)}}
          style={{maxWidth:80, minWidth:80, margin: 3}}>
          <div>基本情報</div>
        </CardActionArea>
      </Card>
    )
  }
}
export default withStyles(useStyles)(withRouter(MemberData))