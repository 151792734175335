import React          from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Button         from '@material-ui/core/Button';
import TextField      from '@material-ui/core/TextField';
import MenuItem       from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid           from '@material-ui/core/Grid'
//import Toolbar        from '@material-ui/core/Toolbar';
//import IconButton     from '@material-ui/core/IconButton';
//import Tooltip        from '@material-ui/core/Tooltip';
//import KeyReturnIcon  from '@material-ui/icons/KeyboardReturn';
import FamikarHeader  from '../components/FamikarHeader';
import firebase       from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
//import {BrowserRouter as Router, Route} from "react-router-dom";
//import logo from '../img/logo.png';
//import { makeStyles, withStyles } from '@material-ui/core/styles';
//import Link from '@material-ui/core/Link';
//import { Typography, Modal } from '@material-ui/core';
//import { Typography } from '@material-ui/core';

const useStyles = {
  button: {
    margin: 2,
    //width: 150
  },
  root: {
    height: 800,
  },
  image: {
    width: 64,
    height: 64,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
};

class FamilyRegist extends React.Component {
  constructor(props) {
    super(props);
    //props.onInit(this)
    var now = new Date(2015, 6, 1);
    this.state = {
      modalShow: false,
      newRegist:true,
      memberName:this.props.location.state.memberName,
      memberUrl:this.props.location.state.memberUrl,
      memberID:this.props.location.state.memberID,
      familyID:this.props.location.state.familyID,
      uid:this.props.location.state.uid,
      displayName:'',
      selectedGender:'選択してください',
      birthYear:now.getFullYear(),
      birthMonth:now.getMonth()+1,
      birthDay:now.getDate(),
      allergy:'',
      history1:'',
      regularDrug:'', // 2020.7.28 arai 飲んでるお薬　追加
      normal_tmp1:'',
      Height:'',
      Weight:'10',
      bloodType:'選択してください',
      image_src:'',
      image_change:false
    }

    console.log("state.memberName:"+props.location.state.memberName)
    console.log("state.memberUrl:"+props.location.state.memberUrl)
    console.log("state.memberID:"+props.location.state.memberID)
    console.log("state.familyID:"+props.location.state.familyID)
    console.log("state.uid:"+props.location.state.uid)
  }

  componentDidMount=()=>{
    if( this.props.location.state.isEdit === true ){
      this.showEditModal(
        this.props.location.state.memberID,
        this.props.location.state.familyID,
        true
      )
    }
  }

  //表示名
  handleDisplayNameChange =(e) =>{
    this.setState({displayName: e.target.value})
  }
  //性別
  handleGenderChange =(e) =>{
    this.setState({selectedGender: e.target.value})
  }
  //生年月日　年
  handleBirthYearChange =(e) =>{
    this.setState({birthYear: e.target.value})
  }
  //生年月日　月
  handleBirthMonthChange =(e) =>{
    this.setState({birthMonth: e.target.value})
  }
  //生年月日　日
  handleBirthDayChange =(e) =>{
    this.setState({birthDay: e.target.value})
  }
  //アレルギー
  handleAllergyChange =(e) =>{
    this.setState({allergy: e.target.value})
  }
  //病歴1
  handleHistory1Change =(e) =>{
    this.setState({history1: e.target.value})
  }
  //飲んでるお薬　2020.7.28 arai 追加
  handleRegularDrugChange =(e) =>{
    this.setState({regularDrug: e.target.value})
  }
  //平熱
  handleNormalTmp1Change =(e) =>{
    this.setState({normal_tmp1: e.target.value})
  }
  //身長
  handleHeightChange =(e) =>{
    this.setState({Height: e.target.value})
  }
  //体重
  handleWeightChange =(e) =>{
    this.setState({Weight: e.target.value})
  }
  //血液型
  handleBloodTypeChange =(e) =>{
    this.setState({bloodType: e.target.value})
  }

  handleModalShowClick = (e) =>{
    var now = new Date(2015, 6, 1);
    this.setState({
      displayName:'',
      selectedGender:'選択してください',
      birthYear:now.getFullYear(),
      birthMonth:now.getMonth()+1,
      birthDay:now.getDate(),
      allergy:'',
      history1:'',
      regularDrug:'', // 飲んでるお薬　追加　2020.7.28 arai
      normal_tmp1:'',
      Height:'',
      Weight:'10',
      bloodType:'選択してください',
      image_src:'',
      image_change:false,
      memberID:'',
      newRegist:true,
      modalShow:true
    })
  }

  //家族登録アクション
  handleRegistClick = (e) => {
    console.log("MemberRegistCard.js handleRegistClick check")

    // 必須項目チェック
    if (this.state.displayName === null || this.state.displayName === '') {
      alert("名前を入れてください")
      return
    }
    if (this.state.birthYear === null || this.state.birthYear === '') {
      alert("生年月日を入れてください")
      return
    }
    if (this.state.birthMonth === null || this.state.birthMonth === '') {
      alert("生年月日を入れてください")
      return
    }
    if (this.state.birthDay === null || this.state.birthDay === '') {
      alert("生年月日を入れてください")
      return
    }
    // if (this.state.selectedGender === null || this.state.selectedGender === '' || this.state.selectedGender === '選択してください') {
    //   alert("性別を選択してください")
    //   return
    // }
    // 空文字チェック
    if (this.state.allergy === null) {
      //this.state.allergy = ''
      this.setState({allergy: ''})
    }
    if (this.state.history1 === null) {
      //this.state.history1 = ''
      this.setState({history1: ''})
    }
    if (this.state.regularDrug === null) {
      //this.state.regularDrug = ''
      this.setState({regularDrug: ''})
    }

    console.log("MemberRegistCard.js handleRegistClick start")
    console.log("MemberRegistCard.js handleRegistClick registMemberCall")
    this.registMember(false).then((result)=>{
      console.log("MemberRegistCard.js handleRegistClick onEventCallback")
      alert("登録しました")
      this.props.history.goBack();
    });
  }

  //家族登録処理
  async registMember(isDelete) {
    console.log("registMember() start!!")
    var db = firebase.firestore()   
    console.log("registMember() get db object uid:"+this.state.uid)
    var docObj = await db.collection("Person").doc(this.state.uid).get()
    var familyID = ''
    var data = docObj.data()
    console.log("get data :"+data)
    familyID = data.FamilyID[0].id
    console.log("get family id success:"+familyID)

    this.setState({familyID: familyID}) //1件目の家族IDを取得する。

    console.log("member db create start")
    console.log("  familyID:" + familyID)
    console.log("  memberID:" + this.state.memberID)
    console.log("  displayName:" + this.state.displayName)
    console.log("  selectedGender:" + this.state.selectedGender)
    console.log("  allergy:" + this.state.allergy)
    console.log("  history1:" + this.state.history1)
    console.log("  regularDrug:" + this.state.regularDrug)
    console.log("  birthYear:" + this.state.birthYear)
    console.log("  birthMonth:" + this.state.birthMonth)
    console.log("  birthDay:" + this.state.birthDay)
    console.log("  normal_tmp1:" + this.state.normal_tmp1)
    console.log("  bloodType:" + this.state.bloodType)
    console.log("  Height:" + this.state.Height)
    console.log("  Weight:" + this.state.Weight)
    var memberID = this.state.memberID

    if (this.props.location.state.isEdit === true) {
      console.log("Edit mode")
      await db.collection("Family").doc(familyID).collection("Member").doc(this.state.memberID)
      .set({
        DisplayName:       this.state.displayName,
        Gender:            this.state.selectedGender,
        Allergy:           this.state.allergy,
        MedicalHistory:    this.state.history1,
        RegularDrug:       this.state.regularDrug, // 飲んでるお薬　追加　2020.7.28 arai
        BirthDay:          new Date( this.state.birthYear, this.state.birthMonth - 1 , this.state.birthDay ),
        NormalTemperature: Number(this.state.normal_tmp1),
        BloodType:         this.state.bloodType,
        Height:            Number(this.state.Height),
        Weight:            Number(this.state.Weight),
        image_url:         this.state.image_src,
        UpdateTime:        firebase.firestore.FieldValue.serverTimestamp(),
        DeleteFlag:        isDelete
      })
      .then((result)=>{
        console.log("member update successful")
      })
      .catch(function(error) {
        console.log("member update failed: ", error);
      });
  
    } else {
      console.log("Create mode")
      await db.collection("Family").doc(familyID).collection("Member")
      .add({
        DisplayName:       this.state.displayName,
        Gender:            this.state.selectedGender,
        Allergy:           this.state.allergy,
        MedicalHistory:    this.state.history1,
        BirthDay:          new Date( this.state.birthYear, this.state.birthMonth - 1 , this.state.birthDay ),
        RegularDrug:       this.state.regularDrug, // 飲んでるお薬　追加　2020.7.28 arai
        NormalTemperature: Number(this.state.normal_tmp1),
        BloodType:         this.state.bloodType,
        Height:            Number(this.state.Height),
        Weight:            Number(this.state.Weight),
        image_url:         this.state.image_src,
        UpdateTime:        firebase.firestore.FieldValue.serverTimestamp(),
        CreateTime:        firebase.firestore.FieldValue.serverTimestamp()
      })
      .then((result)=>{
        console.log("member added successful")
        console.log("memberID:"+result.id)
        this.setState({memberID: result.id})
        memberID = result.id
      })
      .catch(function(error) {
        console.log("member add failed: ", error);
      });
    }

    if (this.state.image_src !== "" && this.state.image_change === true) {
      console.log("image upload start:"+this.state.image_src)
      var cv_image_obj = document.getElementById('cv_image');

      // canvasからbase64で取得
      //var data = cv_image_obj.toDataURL("image/jpeg");
      data = cv_image_obj.toDataURL("image/jpeg");

      console.log("base64["+data+"]")

      // firestorageへの参照を取得
      var storageRef = firebase.storage().ref('member_icon');
      var mountainImagesRef = storageRef.child(memberID);

      // firestorageへ保存
      await mountainImagesRef.putString(data, 'data_url')
      .catch(function(er){
        console.log("image upload error: ",er)
      })
      // firestorageへ保存したファイルへの参照URLを取得
      await firebase.storage().ref('member_icon').child(memberID).getDownloadURL().then((url)=>{
        this.setState({image_src:url})
        console.log(url)
      })

      // firestoreへ参照URLを保存
      console.log("url update")
      await db.collection("Family").doc(familyID).collection("Member").doc(memberID).update({image_url:this.state.image_src}, )
      console.log("url update complete")
    }
    this.setState({modalShow:false});
  }

  handleImageFileChange = (e)=>{
    // TODO 縦長のスマホなどで撮影した画像が横向きになる問題あり
    // https://qiita.com/mo49/items/a3d61d97f1883ead333b
    var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;

    var files = e.target.files;
    var image_url = files.length===0 ? "" : createObjectURL(files[0]);
    this.setState({image_src: image_url, image_change: true});

    this.draw(image_url);
  }

  async showEditModal(member, familyID, isShow) {

    console.log("MemberRegistCard.js showEditModal called member["+member+"] isShow["+isShow+"] familyID["+familyID+"]")
    if (typeof member === 'undefined') {
      console.log("新規登録では起動時のfirestoreアクセスしない")
      return
    }
    var db = firebase.firestore()
    this.setState({
    modalShow: isShow,
    memberID: member,
    familyID: familyID,
    newRegist: false,
    image_change: false
    })

    await db.collection("Family").doc(familyID).collection("Member").doc(member)
    .get()
    .then((result)=>{
      var data = result.data()
      var birthDate = (data.BirthDay === null) ? null : data.BirthDay.toDate()
      this.setState({
        displayName:    (data.DisplayName    === null) ? '' : data.DisplayName,
        selectedGender: (data.Gender         === null) ? '' : data.Gender,
        allergy:        (data.Allergy        === null) ? '' : data.Allergy,
        history1:       (data.MedicalHistory === null) ? '' : data.MedicalHistory,
        regularDrug:    (data.RegularDrug    === null) ? '' : data.RegularDrug, // 飲んでるお薬　追加　2020.7.28 arai
        normal_tmp1:    data.NormalTemperature,
        bloodType:      (data.BloodType      === null) ? '' : data.BloodType,
        birthYear:      (birthDate           === null) ? '' : birthDate.getFullYear(),
        birthMonth:     (birthDate           === null) ? '' : birthDate.getMonth()+1,
        birthDay:       (birthDate           === null) ? '' : birthDate.getDate(),
        Height:         data.Height,
        Weight:         data.Weight,
        image_src:      data.image_url
      })
    })
    .catch(function(error) {
      console.log("Error getting family member add: ", error);
    });
    await this.draw(this.state.image_src)
  }

  handleRegistButtonClick = (e) =>{
    this.setState({modalShow:true})
  }
  
  async draw(imagePath){
    const canvas = document.getElementById("cv_image");
    if( canvas == null ) return;

    const ctx = canvas.getContext("2d");
    ctx.clearRect(0,0,canvas.width,canvas.height);

    if(imagePath !== '')
    {
      const image = new Image();
      image.addEventListener("load",function (){
      //var file = null; // 選択ファイルが格納される変数
      //var blob = null; // 画像(BLOBデータ)が格納される変数
      const THUMBNAIL_MAX_WIDTH = 124; // 画像がヨコ長の場合、横サイズがこの値になるように縮小される
      const THUMBNAIL_MAX_HEIGHT = 124; // 画像がタテ長の場合、縦サイズがこの値になるように縮小される
      
      // 縮小後のサイズを計算する
      var width, height;
      if(image.width > image.height){
        // ヨコ長の画像は横サイズを定数にあわせる
        var ratio = image.height/image.width;
        console.log("横長"+ratio)
        width = THUMBNAIL_MAX_WIDTH;
        height = THUMBNAIL_MAX_WIDTH * ratio;
      } else {
        // タテ長の画像は縦のサイズを定数にあわせる
        //var ratio = image.width/image.height;
        ratio = image.width/image.height;
        console.log("縦長"+ratio)
        width = THUMBNAIL_MAX_HEIGHT * ratio;
        height = THUMBNAIL_MAX_HEIGHT;
      }
      console.log("width["+width+"] height["+height+"]")
  
      canvas.width = width;
      canvas.height = height;
  
      ctx.drawImage(image,
        0, 0, image.width, image.height,
        0, 0, width, height
      );
      });
      image.src = imagePath;
    }

  }

  handleRegistFamilyImage = (e) =>{
    var btn_input_file = document.getElementById('file_familyImage');
    btn_input_file.click();
  }

  handleGoBack = (e) =>{
    this.props.history.goBack();
  }
  handleDelete = (e) =>{
    // 削除実行の確認用ポップアップ
    if (!window.confirm("削除します")) {
      alert("削除をキャンセルしました");
      this.props.history.goBack();
      return;
    }

    // 削除ボタンは新規登録時に表示しないので入力チェックはしない
    console.log("MemberRegistCard.js handleDelete start")

    console.log("MemberRegistCard.js handleDelete registMemberCall")
    this.registMember(true).then((result)=>{
      console.log("MemberRegistCard.js handleDelete onEventCallback")

      alert("削除しました")
      this.props.history.goBack();
    });
  }

  render(){
    var now = new Date();
    const yearList = [];
    const monthList = [];
    const dayList = [];
    for( let i = 1900; i <= now.getFullYear(); i++ ){
      yearList.push(<MenuItem key={i} value={i}>{i}</MenuItem>)
    }
    for( let m = 1; m <= 12; m++ ){
      monthList.push(<MenuItem key={m} value={m}>{m}</MenuItem>)
    }
    for( let n = 1; n <= 31; n++ ){
      dayList.push(<MenuItem key={n} value={n}>{n}</MenuItem>)
    }
  
    const tmp1List = [];
    for ( let k=35.0; k < 42.1; k=k+0.1 ){
      var tmperatureStr = k.toFixed(1)
      tmp1List.push(<MenuItem key={tmperatureStr} value={tmperatureStr}>{tmperatureStr}</MenuItem>)
    }
    // const tmp2List = [];
    // for ( let l=0; l <= 9; l++ ){
    //   tmp2List.push(<MenuItem key={l} value={l}>{l}</MenuItem>)
    // }
  
    const heightList = [];
    for( let h=30; h <= 200; h++ ){
      heightList.push(<MenuItem key={h} value={h}>{h}</MenuItem>)
    }
    const weightList = [];
    for( let w=0; w <= 200; w++ ){
      weightList.push(<MenuItem key={w} value={w}>{w}</MenuItem>)
    }

    // 新規登録時は削除ボタンを表示しない
    let deleteButton
    if (this.props.location.state.isEdit === true) {
      // 編集
      deleteButton = (
        <Grid item>
          <Button variant="outlined" style={{margin: '0 8px', padding: '5px 20px', color: '#FFF', backgroundColor: '#F66', border: '1px solid #F00'}} onClick={this.handleDelete}>削除</Button>
        </Grid>
      )
    } else {
      // 新規
      deleteButton = null
    }

    return (
      <React.Fragment>
        <FamikarHeader uid={this.state.uid} FamilyID={this.state.FamilyID} />
        <div style={{display: 'block',background: 'white', textAlign:"left", margin:10}}>
          <form className={this.props.classes.root} noValidate autoComplete="off" >
            <Grid container spacing={2} >
              {/* 「登録する家族を登録してね」の表記を無効化する 2020/07/11
              <Grid item xs={12}>
                <Typography variant="h6">
                  登録する家族を登録してね
                </Typography>
              </Grid>
              */}
              <Grid container spacing={3} style={{padding: '8px'}}>
                <Grid item xs={4}>
                  <div>
                    <Button variant="outlined" color="primary" style={{ width: 130, height: 130, position: 'relative', textAlign: 'center' }} onClick={this.handleRegistFamilyImage} >
                      画像を選ぶ
                      <canvas id="cv_image" width="0" height="0" style={{ position: 'absolute', textAlign: 'center' }}
                        src={this.state.image_src} />
                    </Button>
                  </div>
                  <div>
                    <input type="file" id="file_familyImage" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} onChange={this.handleImageFileChange} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <TextField id="tf_display_name" className="w-100" label="表示するお名前" value={this.state.displayName} onChange={(e) => this.handleDisplayNameChange(e)} />
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={3}>
                  <TextField select id="sel_gender" label="性別" className="w-100"
                    value={this.state.selectedGender} onChange={(e) => this.handleGenderChange(e)}>
                    {/* <MenuItem key='0' value='選択してください' >選択してください</MenuItem> */}
                    <MenuItem key='0' value='選択してください' ></MenuItem>
                    <MenuItem key='1' value='男性' >男性</MenuItem>
                    <MenuItem key='2' value='女性' >女性</MenuItem>
                    <MenuItem key='9' value='その他' >その他</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item xs={3}>
                    <TextField select id="sel_birth_year" label="生年月日" className="w-100px" value={this.state.birthYear}
                      onChange={(e) => this.handleBirthYearChange(e)} InputProps={{startAdornment: <InputAdornment position="start" >年</InputAdornment>}}>
                      {yearList}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField select id="sel_birth_month" label=" " className="w-100px" value={this.state.birthMonth}
                      onChange={(e) => this.handleBirthMonthChange(e)} InputProps={{startAdornment: <InputAdornment position="start" >月</InputAdornment>}}>
                      {monthList}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField select id="sel_birth_day" label=" " className="w-100px" value={this.state.birthDay}
                      onChange={(e) => this.handleBirthDayChange(e)} InputProps={{startAdornment: <InputAdornment position="start" >日</InputAdornment>}}>
                      {dayList}
                    </TextField>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField select id="sel_bloodType" label="血液型" className="w-100" value={this.state.bloodType} onChange={(e) => this.handleBloodTypeChange(e)}>
                      <MenuItem key='0' value='選択してください' ></MenuItem>
                      <MenuItem key='A' value='A' >A型</MenuItem>
                      <MenuItem key='O' value='O' >O型</MenuItem>
                      <MenuItem key='B' value='B' >B型</MenuItem>
                      <MenuItem key='AB' value='AB' >AB型</MenuItem>
                      <MenuItem key='A-' value='A-' >A型(Rh-)</MenuItem>
                      <MenuItem key='O-' value='O-' >O型(Rh-)</MenuItem>
                      <MenuItem key='B-' value='B-' >B型(Rh-)</MenuItem>
                      <MenuItem key='AB-' value='AB-' >AB型(Rh-)</MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={1}>
                  <Grid item xs={2}>
                    <TextField select id="sel_height" label="身長"  className="w-70px" value={this.state.Height} onChange={(e)=>this.handleHeightChange(e)}>
                      {heightList}
                    </TextField>
                  </Grid>
                  <Grid item xs={2} style={{textAlign: "left"}}>
                    cm
                  </Grid>
                  <Grid item xs={2}>
                    <TextField select id="sel_weight" label="体重"  className="w-70px" value={this.state.Weight} onChange={(e)=>this.handleWeightChange(e)}>
                      {weightList}
                    </TextField>
                  </Grid>
                  <Grid item xs={2}>
                    kg
                  </Grid>
                  <Grid item xs={2}>
                    <TextField select id="sel_normal_tmp1" label="平熱"  className="w-70px" value={this.state.normal_tmp1} onChange={(e)=>this.handleNormalTmp1Change(e)}>
                      {tmp1List}
                    </TextField>
                  </Grid>
                  <Grid item xs={2}>
                    ℃
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField id="tf_allegy" className="w-100" label="アレルギー" value={this.state.allergy} onChange={(e)=>this.handleAllergyChange(e)} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="tf_regularDrug" className="w-100" label="普段飲んでるお薬" multiline rows='4' style={{height:120}} value={this.state.regularDrug} onChange={(e)=>this.handleRegularDrugChange(e)} />
              </Grid>
              <Grid item xs={12}>
                <TextField id="tf_history1" className="w-100" label="大きな病気・メモなど" multiline rows='4' style={{height:120}} value={this.state.history1} onChange={(e)=>this.handleHistory1Change(e)} />
              </Grid>
              <Grid container justifyContent="space-between">
                {deleteButton} {/* 家族情報の編集時のみ、削除ボタンを表示する */}
                <Grid item>
                  <Button variant="outlined" style={{margin: '0 8px'}} color="secondary" onClick={this.handleGoBack}>キャンセル</Button>
                </Grid>
                <Grid item>
                  <Button variant="outlined" style={{margin: '0 8px', padding: '5px 20px'}} color="primary" onClick={this.handleRegistClick}>登録</Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </div>
      </React.Fragment>
    )
  }
}
export default withStyles(useStyles)(withRouter(FamilyRegist))