import React from "react";
import {withRouter} from "react-router-dom";

class DemoTop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  // ページ表示時に家族ホーム画面に移動する
  componentDidMount(){
    //this.UserStatusCheck() //ログイン状態チェック
  }

  //「ログイン状態取得」
  UserStatusCheck=()=>{
    let uid      = 'demo_uid'
    let FamilyID = 'DemoFamilyID'
    console.log("push URL: /DemoFamilyHome/" + FamilyID)
    this.props.history.push({ //ログイン中の場合は、家族ホーム画面に移動する。
      pathname: '/DemoFamilyHome/' + FamilyID,
      state: {
        uid: uid,  // ログインユーザーID
      }
    })
  }

  render(){
    return (
      <React.Fragment>
        デモ画面
      </React.Fragment>
    )
  }
}

export default withRouter(DemoTop)
