import React          from "react";
import { withRouter } from 'react-router-dom';
import Button         from '@material-ui/core/Button';
import Grid           from '@material-ui/core/Grid'
import Table          from '@material-ui/core/Table';
import TableBody      from '@material-ui/core/TableBody';
import TableCell      from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow       from '@material-ui/core/TableRow';
import Paper          from '@material-ui/core/Paper';
//import {Modal}        from 'react-bootstrap';
import firebase       from'firebase/app';
import 'firebase/auth';

class ModalFamilyView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      medicalrecordid:   (props.MedicalRecordID !== '') ? props.MedicalRecordID : '', //診療情報ID
      DisplayName:       (props.DisplayName     !== '') ? props.DisplayName     : '', //家族の表示名
      image_src:         (props.image_src       !== '') ? props.image_src       : '', //家族のサムネイル画像
      uid:               (props.uid             !== '') ? props.uid             : '', //ログインUID
      FamilyID:          (props.FamilyID        !== '') ? props.FamilyID        : '', //家族ID
      MemberID:          (props.MemberID        !== '') ? props.MemberID        : '', //メンバーID
      Gender:            '',                     //性別
      Allergy:           '',                     //アレルギー情報
      MedicalHistory:    '',                     //既往歴等
      RegularDrug:       '',                     //飲んでいるお薬
      NormalTemperature: '',                     //平熱
      BloodType:         '',                     //血液型
      BirthDay:          '',                     //生年月日
      Height:            '',                     //身長
      Weight:            '',                     //体重
    }
    console.log(`ModalFamilyView props[${JSON.stringify(this.props,null,'\t')}]`);
    console.log(`ModalFamilyView handleModalClose[${typeof this.props.handleModalClose}]`);
    console.log(`ModalFamilyView DisplayName[${this.state.DisplayName}]`);
    console.log(`ModalFamilyView image_src[${this.state.image_src}]`);
    console.log(`ModalFamilyView uid[${this.state.uid}]`);
    console.log(`ModalFamilyView FamilyID[${this.state.FamilyID}]`);
    console.log(`ModalFamilyView MemberID[${this.state.MemberID}]`);
    console.log(`ModalFamilyView medicalrecordid[${this.state.medicalrecordid}]`);
  }

  componentDidMount(){
    console.log("componentDidMount: this.state.uid["+this.state.uid+"]")
    this.handleModalShow()
    console.log("componentDidMount: finish handleModalShow");
  }

  //モーダルを非表示状態に切り替える
  handleModalClose = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // PersonalHomeよりロードされた場合は、モーダルを閉じる
    }
  }

  //モーダルを表示状態に切り替える
  handleModalShow = () => {
    let uid         = this.state.uid
    let FamilyID    = this.state.FamilyID
    let MemberID    = this.state.MemberID
    let DisplayName = this.state.DisplayName
    let member_url  = this.state.image_src
    var GetMemberID = new Promise((resolve)=>{
      if (FamilyID === '' || MemberID === '') {
        firebase.auth().onAuthStateChanged((user)=>{ //現在ログインしているユーザーを取得する。
          if (user) {
            uid = user.uid

            firebase.firestore().collection("Person").doc(uid).get().then((Person_result)=>{ //ログインユーザー情報から、家族IDの情報を取得する
              FamilyID = Person_result.data().FamilyID[0].id // 1件目の家族IDを取得する。

              if (this.state.medicalrecordid !== ''){ // 診療情報のレコードIDが存在することを確認する。
                MemberID = this.state.medicalrecordid.split('_')[0] // 診療情報のレコードIDから、「_」の前のメンバーIDを取り出す。

                firebase.firestore().collection("Family").doc(FamilyID).collection("Member").doc(MemberID).get().then((Member_result)=>{
                  DisplayName = ((Member_result.data().DisplayName) ? Member_result.data().DisplayName : '')
                  member_url  = ((Member_result.data().image_url  ) ? Member_result.data().image_url   : '')

                  this.setState({
                    uid:         uid,
                    FamilyID:    FamilyID,
                    MemberID:    MemberID,
                    DisplayName: DisplayName,
                    image_src:   member_url
                  })
                  resolve()
                })
              } else {
                this.props.history.push({pathname: '/'}) //診療情報のレコードIDが無い場合は、アプリトップページに移動する
              }
            })
          } else {
            this.props.history.push({pathname: '/'}) //ログインユーザー情報が取得できない場合は、アプリトップページに移動する
          }
        })
      } else {
        resolve()
      }
    })

    GetMemberID.then(()=>{
      firebase.firestore().doc('Family/' + FamilyID + '/Member/' + MemberID)
      .get().then((result)=>{
        var data = result.data()
        var birthDate = (data.BirthDay === null) ? null : data.BirthDay.toDate()
        var birthdayText = birthDate.getFullYear() + '年' + (birthDate.getMonth()+1) + '月' + birthDate.getDate() + '日'
        this.setState({
          Gender:            (data.Gender            === null) ? '' : data.Gender,             //性別
          Allergy:           (data.Allergy           === null) ? '' : data.Allergy,            //アレルギー情報
          MedicalHistory:    (data.MedicalHistory    === null) ? '' : data.MedicalHistory,     //既往歴等
          RegularDrug:       (data.RegularDrug       === null) ? '' : data.RegularDrug,        //飲んでいるお薬
          NormalTemperature: (data.NormalTemperature === null) ? '' : data.NormalTemperature,  //平熱
          BloodType:         (data.BloodType         === null) ? '' : (data.BloodType + '型'), //血液型
          Height:            (data.Height            === null) ? '' : data.Height + ' cm',     //身長
          Weight:            (data.Weight            === null) ? '' : data.Weight + ' kg',     //体重
          BirthDay:          birthdayText,                                                     //生年月日
          //modalShow: true, //モーダルを表示に切り替える。
        })
      })
      .catch(function(error) {
        console.log("Error getting family member data: ", error)
      })
    })
  }

  render(){
    return (
      <React.Fragment>
        <div> {/* モーダルのヘッダー部 */}
          <img src={this.state.image_src} alt={this.state.DisplayName} width="80" height="80" />{/* 家族の画像 */}
          <span style={{paddingLeft: '1em'}}>{this.state.DisplayName}</span> {/* 家族の名前 */}
        </div>
        <div style={{marginTop: '1rem'}}> {/* モーダルの本文部分 */}
          <Grid container spacing={2}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="Family Member Data">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan="2" align="left">性別：<span style={{paddingLeft: '2.5em'}}>{this.state.Gender}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" align="left">生年月日：<span style={{paddingLeft: '0.5em'}}>{this.state.BirthDay}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{width: '50%'}}>身長：<span style={{paddingLeft: '2.5em'}}>{this.state.Height}</span></TableCell>
                    <TableCell align="left" style={{width: '50%'}}>体重：<span style={{paddingLeft: '0.5em'}}>{this.state.Weight}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{width: '50%'}}>血液型：<span style={{paddingLeft: '1.5em'}}>{this.state.BloodType}</span></TableCell>
                    <TableCell align="left" style={{width: '50%'}}>平熱：<span style={{paddingLeft: '0.5em'}}>{this.state.NormalTemperature}</span>℃</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" align="left">アレルギー：<br />{this.state.Allergy}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" align="left">大きな病気・メモ：<br />{this.state.MedicalHistory}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" align="left">飲んでいるお薬：<br />{this.state.RegularDrug}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </div>
        <div style={{marginTop: '1rem'}}> {/* モーダルのフッター部 */}
          <Button variant="outlined" color="primary" onClick={this.handleModalClose}>閉じる</Button>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(ModalFamilyView)