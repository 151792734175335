import React from "react";
import { withRouter, BrowserRouter as Link } from "react-router-dom";
import logo from '../img/logo.png';
//import googleLoginLogo from '../img/btn_google_signin_dark_pressed_web.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, Container, Row, Col } from 'react-bootstrap';
import firebase from 'firebase/app';
import 'firebase/auth';

class Login extends React.Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(this.props.location.search) // URLクエリを使用する
    let modalShow = false
    let modalRegistProcessShow = false
    let modalLoginProcessShow = false
    if (params.get("view") === "Regist") { //URLが「/Login?view=Regist」の場合は会員登録画面表示を行う。
      modalShow = true  //会員登録画面をモーダルで表示する
    } else {
      modalShow = false //会員登録画面は非表示とする。（ログイン画面を表示）
    }
    if (params.get("view") === "Regist_Process") { //URLが「/Login?view=Regist_Process」の場合は会員登録処理中表示を行う。
      modalRegistProcessShow = true
    } else {
      modalRegistProcessShow = false
    }
    if (params.get("view") === "Login_Process") { //URLが「/Login?view=Login_Process」の場合はログイン処理中表示を行う。
      modalLoginProcessShow = true
    } else {
      modalLoginProcessShow = false
    }

    this.state = {
      modalShow:                     modalShow,              //ユーザー新規登録画面の表示コントロール（true=会員登録画面、false=ログイン画面）
      modalRegistProcessShow:        modalRegistProcessShow, //ユーザー新規登録処理中画面の表示コントロール（true=処理中画面、false=ログイン画面）
      modalLoginProcessShow:         modalLoginProcessShow,  //ログイン処理中画面の表示コントロール（true=ログイン中画面、false=ログイン画面）
      isLogin:                       false,            //ログイン状態
      loginEmailValue:               '',               //ユーザーのメールアドレス
      loginPasswordValue:            '',               //ユーザーのパスワード
      loginPasswordConfirmValue:     '',               //新規ユーザーのパスワード再入力
      loginFamilyNameValue:          '',               //新規ユーザーの家族名
      loginFirstNameValue:           '',               //新規ユーザーの名前
      loginFamilyNameKanaValue:      '',               //新規ユーザーの家族名(カナ)
      loginFirstNameKanaValue:       '',               //新規ユーザーの名前(カナ)
      loginAgrrement:                false,            //個人情報保護方針の同意
      loginAgrrementID:              '',               //個人情報保護方針のバージョン
      loginQuestionnaireValue:       false,            //新規ユーザーのアンケート協力
      loginEmailAlertText:           '',               //ログインメールアドレス警告文字列
      loginPasswordAlertText:        '',               //ログインパスワード警告文字列
      loginPasswordConfirmAlertText: '',               //ログインパスワード再入力警告文字列
    }

    //ログイン画面表示時は、エラー防止のため、１回だけ画面の再読み込みを行う。
    if (sessionStorage.getItem("LoginReload") === null) {
      sessionStorage.setItem('LoginReload', 'true'); //セッションストレージにLoginReload=trueを保存して、ログイン画面が繰り返し再読み込みされないようにする
      window.location.reload() //ブラウザの機能でページ再読み込みを行う
    }
  }

  //「ログイン」ボタンを押した際の処理
  handleEmailLogin = () => {
    let emailVerified = false
    let user = []
    Promise.resolve()
    .then(() => {
      return new Promise((resolve) => {
        //ログイン処理中画面に移動
        this.setState({
          modalShow:              false, //会員登録画面のモーダルを非表示にする
          modalRegistProcessShow: false, //会員登録処理中画面のモーダルを非表示する
          modalLoginProcessShow:  true,  //ログイン中画面のモーダルを表示にする
        })
        resolve()
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        firebase.auth().signOut().then(() => {//ユーザーのサインアウトを行う。
          resolve()
        })
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        this.setState({ isLogin: false })
        resolve()
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        //メールアドレスとパスワードを使用してユーザーのログインを行う。
        firebase.auth().signInWithEmailAndPassword(this.state.loginEmailValue, this.state.loginPasswordValue)
        .then((result) => {
          user = result.user;
          console.log("user.email: " + user.email)
          console.log("user.uid: " + user.uid)
          emailVerified = user.emailVerified //Eメールアドレスの認証可否状況
          resolve()
        })
        .catch((error) => { //ログイン失敗を検知
          if (error.code === 'auth/user-not-found' && error.message === 'There is no user record corresponding to this identifier. The user may have been deleted.') {
            console.log(`ログインに失敗しました。\n code: ${error.code}\n message: ${error.message}`)
            alert('ログインに失敗しました。\nアカウントは存在しません。')
          } else if (error.code === 'auth/invalid-email' && error.message === 'The email address is badly formatted.') {
            console.log(`ログインに失敗しました。\n code: ${error.code}\n message: ${error.message}`)
            alert('ログインに失敗しました。\n正しいメールアドレスを入力してください。')
          } else if (error.code === 'auth/wrong-password' && error.message === 'The password is invalid or the user does not have a password.') {
            console.log(`ログインに失敗しました。\n code: ${error.code}\n message: ${error.message}`)
            alert('ログインに失敗しました。\nパスワードを確認してください')
          } else {
            console.log(`ログインに失敗しました。\n code: ${error.code}\n message: ${error.message}`)
            alert('ログインに失敗しました。')
          }

          //ログイン画面に移動
          this.setState({
            modalShow:              false, //会員登録画面のモーダルを非表示にする
            modalRegistProcessShow: false, //処理中画面のモーダルを非表示する
            modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
          })
        })
      })
    })
    .then(() => {
      if (emailVerified) { //Eメールアドレスの認証可否をチェックする。
        let FamilyID = ''
        console.log("Email verified. user.emailVerified: " + user.emailVerified)  //メールアドレス承認済みの場合

        //「Person」コレクションからユーザーの姓・名を取得し、コンソールに出力する。
        firebase.firestore().collection("Person").doc(user.uid).get().then((result) => {
          var data = result.data()
          console.log("familyName: " + data.FamilyName)
          console.log("firstName: " + data.FirstName)
          FamilyID = data.FamilyID[0].id //1件目の家族IDを取得する。
          this.setState({ isLogin: true })
          this.props.history.push('/FamilyHome/' + FamilyID) //家族ホーム画面に遷移する。
        })
      } else {
        console.log("Do verify email. user.emailVerified: " + user.emailVerified) //メールアドレス未承認の場合
        var actionCodeSettings = {
          url: 'https://app.famikar.jp/', //メールアドレス認証後に開くURLを指定する
          handleCodeInApp: false,         //Webサイトとして開く設定
        }
        firebase.auth().currentUser.sendEmailVerification(actionCodeSettings) //メールアドレス未承認の場合は、確認メールを送信する。
        .then(() => { //確認メール送信成功時
          console.log('メールアドレス確認用のメールをお送りしました。 送信先アドレス：' + user.email)
          alert('メールアドレス確認用のメールをお送りしました。\n送信先アドレス：' + user.email)
          //ログイン画面に移動
          this.setState({
            modalShow:              false, //会員登録画面のモーダルを非表示にする
            modalRegistProcessShow: false, //処理中画面のモーダルを非表示する
            modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
          })
        })
        .catch((error) => { //確認メール送信失敗時
          if (error.code === 'auth/too-many-requests' && error.message === 'We have blocked all requests from this device due to unusual activity. Try again later.') {
            console.log('メールアドレス確認メールの送信に失敗しました。\n登録メールアドレス(' + user.email + ')\n' + error.code + '\n' + error.message)
            alert('メールアドレス確認メールの送信に失敗しました。\n登録メールアドレス(' + user.email + ')\n送信済みの確認メールを確認してください。')
          } else {
            console.log('メールアドレス確認メールの送信に失敗しました。\n登録メールアドレス(' + user.email + ')\n' + error.code + '\n' + error.message)
            alert('メールアドレス確認メールの送信に失敗しました。\n登録メールアドレス(' + user.email + ')\n' + error.message)
          }
          //ログイン画面に移動
          this.setState({
            modalShow:              false, //会員登録画面のモーダルを非表示にする
            modalRegistProcessShow: false, //処理中画面のモーダルを非表示する
            modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
          })
        })
      }
    })
  }

  //googleログインボタンを押した際の処理（一旦無効化）
  /*
  handleGoogleLogin= () => {
    this.setState({ modalShow: false })
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
    .then((result)=>{
      var user = result.user;
      console.log("user.email:"+user.email)
      console.log("user.uid:"+user.uid)

      this.setState({ isLogin: true })
      this.props.history.push('/top/'+user.uid)
    })
    .catch(error => alert('ログインに失敗'+error.message))
  }
  */

  //ログアウト処理
  handleGoogleSignOut = () => {
    firebase.auth().signOut();
  }

  //ログイン・新規作成用メールアドレス
  handleLoginEmailChange = (e) => {
    this.setState({ loginEmailValue: e.target.value })
  }

  //新規作成用メールアドレス入力から離れた際にチェックする
  //参考サイト：メールアドレスの正規表現 | 正規表現入門
  // https://www.javadrive.jp/regex-basic/sample/index13.html
  handleLoginEmailBlue = (e) => {
    const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/; //メールアドレスの正規表現文字列
    if (!regex.test(e.target.value)) { //メールアドレスの形式をチェックする。
      this.setState({ loginEmailAlertText: "メールアドレスの形式が間違っています。" })
    } else {
      this.setState({ loginEmailAlertText: '' }) //メールアドレスの形式が正しい場合はメッセージを出さない。
    }
  }

  //ログイン・新規作成用パスワード
  handleLoginPasswordChange = (e) => {
    this.setState({ loginPasswordValue: e.target.value })
  }

  //パスワード入力から離れた際にチェックする
  handleLoginPasswordBlue = (e) => {
    if (e.target.value.length < 6) { //パスワード文字列が6文字未満の場合は、警告メッセージを表示する
      this.setState({ loginPasswordAlertText: "パスワードは6文字以上設定してください" })
    } else {
      this.setState({ loginPasswordAlertText: '' }) //パスワード文字列が6文字以上の場合はメッセージは出さない。
    }
  }

  //ユーザー新規登録時のパスワード再確認用
  handleLoginPasswordConfirmChange = (e) => {
    this.setState({ loginPasswordConfirmValue: e.target.value })
  }

  //パスワード再入力からフォーカスが離れた際にチェックする
  handleLoginPasswordConfirmBlue = (e) => {
    if (this.state.loginPasswordValue !== e.target.value) { //パスワード文字列が不一致の場合は警告する
      this.setState({ loginPasswordConfirmAlertText: "パスワードが一致しません。" })
    } else {
      this.setState({ loginPasswordConfirmAlertText: '' }) //パスワード文字列一致の場合は警告しない
    }
  }

  //ユーザー新規登録時用ログインユーザーの名前
  handleLoginFirstNameChange = (e) => {
    this.setState({ loginFirstNameValue: e.target.value })
  }

  //ユーザー新規登録時用のログインユーザーの名字
  handleLoginFamilyNameChange = (e) => {
    this.setState({ loginFamilyNameValue: e.target.value })
  }

  //ユーザー新規登録時用ログインユーザーの名前（カナ）
  handleLoginFirstNameKanaChange = (e) => {
    this.setState({ loginFirstNameKanaValue: e.target.value })
  }

  //ユーザー新規登録時用のログインユーザーの名字（カナ）
  handleLoginFamilyNameKanaChange = (e) => {
    this.setState({ loginFamilyNameKanaValue: e.target.value })
  }

  //個人情報保護方針の同意
  handleAgreementChange = (e) => {
    this.setState({
      loginAgrrement:   e.target.checked, //個人情報保護方針の同意
      loginAgrrementID: e.target.id,      //個人情報保護方針のバージョン（privacy_20210426, 2022/12/25より：privacy_20221225）
    })
  }

  //ユーザーアンケート同意
  handleQuestionnaireChange = (e) => {
    this.setState({ loginQuestionnaireValue: e.target.checked }) //ユーザーアンケートへの協力可否
  }

  //ユーザー新規登録処理
  handleUserRegist = () => {
    let user = []
    Promise.resolve()
    .then(() => {
      return new Promise((resolve) => {
        const regex = /^[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/; //メールアドレスの正規表現文字列
        if (!regex.test(this.state.loginEmailValue)) {
          alert('メールアドレスの形式が間違っています。\n正しいメールアドレスを入力してください。') //メールアドレスの形式に誤りがある場合は、修正が必要なことを記載する。
        } else {
          resolve() //メールアドレスの形式が正しい場合は次の処理に進む
        }
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        if (this.state.loginPasswordValue.length < 6) {
          alert('パスワードは6文字以上で設定してください。') //パスワードが6文字未満の場合は、パスワードは6文字以上で設定が必要なことを記載する。
        } else {
          resolve() //パスワードが6文字以上の場合は次の処理に進む
        }
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        if (this.state.loginPasswordValue !== this.state.loginPasswordConfirmValue) {
          alert('パスワードが違います') //パスワードのダブルチェック失敗の場合
        } else {
          resolve() //パスワードのダブルチェック成功の場合は次の処理に進む
        }
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        //個人情報保護方針に同意がない場合は、同意を必須とする。
        if (this.state.loginAgrrement === false) {
          alert('個人情報保護方針の同意が必要です。') //個人情報保護方針の同意確認に失敗の場合
        } else {
          resolve() //個人情報保護方針に同意の場合は次の処理に進む
        }
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        //新規登録処理中画面に移動
        this.setState({
          modalShow:              false, //会員登録画面のモーダルを非表示にする
          modalRegistProcessShow: true,  //処理中画面のモーダルを表示する
          modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
        })
        resolve()
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        //メールアドレスとパスワードを使用して、新しいユーザーを登録する。
        firebase.auth().createUserWithEmailAndPassword(this.state.loginEmailValue, this.state.loginPasswordValue)
        .then((result) => { //ユーザー登録成功
          user = result.user
          console.log("create user UID: " + user.uid)
          resolve() //ユーザー登録処理に成功した場合は次の処理に進む
        })
        .catch((error) => { //ユーザー登録失敗
          if (error.message === 'The email address is already in use by another account.') {
            alert('ユーザー登録に失敗。\nメールアドレスは既に他のアカウントで登録されています。')
          } else if (error.message === 'Password should be at least 6 characters') {
            alert('ユーザー登録に失敗。\nパスワードは6文字以上必要です。')
          } else if (error.message === 'The email address is badly formatted.') {
            alert('ユーザー登録に失敗。\nメールアドレスの形式が正しくありません。')
          } else {
            alert('ユーザー登録に失敗。\n(' + error.message + ')');
          }
          //ユーザー登録失敗時は、元の会員登録画面を表示する。
          this.setState({
            modalShow:              true, //会員登録画面のモーダルを表示する。
            modalRegistProcessShow: false, //会員登録勝利中画面のモーダルを非表示にする。
            modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示する
          })
        })
      })
    })
    .then(() => {
      return new Promise((resolve) => {
        //既存のユーザーをログインさせる
        firebase.auth().signInWithEmailAndPassword(this.state.loginEmailValue, this.state.loginPasswordValue)
        .then(async () => { //ログイン成功の場合
          await this.registUserInfomation(user.uid) //ユーザー情報をデータベースに登録する。
          firebase.auth().currentUser.updateProfile({
            displayName: this.state.loginFamilyNameValue + " " + this.state.loginFirstNameValue,
          })
          .then(() => {
            resolve() //ユーザー情報の登録に成功した場合
          })
        })
      })
    })
    .then(() => {
      return new Promise((resolve, reject) => {
        //メールアドレス存在確認を行う。
        //Eメールアドレスの認証可否をチェックする。
        if (user.emailVerified) {
          console.log("Email verified. user.emailVerified: " + user.emailVerified)  //メールアドレス承認済みの場合
          resolve() //ユーザーメールアドレスが承認済みの場合は、ログイン処理を継続する
        } else {
          console.log('メールアドレスの認証操作が必要です。')
          console.log("Do verify email. user.emailVerified: " + user.emailVerified) //メールアドレス未承認の場合
          var actionCodeSettings = {
            url: 'https://app.famikar.jp/', //メールアドレス認証後に開くURLを指定する
            handleCodeInApp: false,         //Webサイトとして開く設定
          }
          firebase.auth().currentUser.sendEmailVerification(actionCodeSettings) //メールアドレス未承認の場合は、確認メールを送信する。
          .then(() => { //確認メール送信成功時
            console.log('メールアドレス確認用のメールをお送りしました。 送信先アドレス：' + user.email)
            alert('メールアドレス確認用のメールをお送りしました。\n送信先アドレス：' + user.email)
            reject()
          })
          .catch((error) => { //確認メール送信失敗時
            alert('メールアドレス確認メールの送信に失敗しました。\n登録メールアドレス(' + user.email + ')\n' + error.message)
            reject()
          })
        }
      })
    })
    .then(() => { //メールアドレス承認済みの場合は、家族ホーム画面に移動する
      var FamilyID = '';
      new Promise((resolve) => {
        this.setState({
          modalShow: false,
          modalRegistProcessShow: false,
          modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
          isLogin: true
        })
        resolve()
      })
      .then(() => {
        firebase.firestore().collection("Person").doc(user.uid).get().then((result) => {
          FamilyID = result.data().FamilyID[0].id //1件目の家族IDを取得する。
          this.props.history.push('/FamilyHome/' + FamilyID) //ログイン完了時は、家族ホーム画面に移動する。
          console.log("Login.js handleUserRegist after push URL: /FamilyHome/" + FamilyID)
        })
      })
    })
    .catch(() => {
      new Promise((resolve) => {
        firebase.auth().signOut() //firebaseのサインアウトを実施
        resolve()
      })
      .then(() => {
        document.title = 'ログイン - ふぁみかるくん'
        new Promise((resolve) => {
          this.setState({ 
            modalShow: false,
            modalRegistProcessShow: false,
            modalLoginProcessShow:  false, //ログイン中画面のモーダルを非表示にする
          })
          resolve()
        })
        .then(() => {
          this.props.history.push('/Login')
        })
      })
    })
  }

  //ユーザー情報登録時
  registUserInfomation(uid) {
    return new Promise((resolve, reject) => {
      console.log("Login.js registUserInfomation call uid: " + uid)
      const db = firebase.firestore()
      var familyID = ''

      //新規作成した家族IDを取得する。
      db.collection("Family").add({ FamilyName: this.state.loginFamilyNameValue })
        .then((result) => {
          familyID = result.id

          //Editorにオーナー権限で登録を行う。
          db.collection("Family").doc(familyID).collection("Editor").doc(uid)
            .set({ Permission: "owner" })
            .then(() => {
              //「Person」にログインユーザーの情報を登録する。
              if (familyID !== '') {
                db.collection("Person").doc(uid).set({
                  Email:           this.state.loginEmailValue,
                  FamilyName:      this.state.loginFamilyNameValue,
                  FirstName:       this.state.loginFirstNameValue,
                  FamilyName_Kana: this.state.loginFamilyNameKanaValue,
                  FirstName_Kana:  this.state.loginFirstNameKanaValue,
                  Agrrement:       this.state.loginAgrrement,
                  CreateTime:      firebase.firestore.FieldValue.serverTimestamp(),
                  UpdateTime:      firebase.firestore.FieldValue.serverTimestamp(),
                  AgrrementID:     this.state.loginAgrrementID,
                  Questionnaire:   this.state.loginQuestionnaireValue,
                  FamilyID: [{
                    id:        familyID, //アクセス対象の家族ID
                    CanAccess: true      //アクセス対象の有効無効設定（デフォルトを有効とする）
                  }]
                })
                .then(() => { console.log("Login.js registUserInfomation family created"); resolve(); })
              }
            })
            .catch((error) => { console.log("Error registUserInfomation Editor documents: ", error); reject(); })
        })
        .catch((error) => { console.log("Error registUserInfomation Family documents: ", error); reject(); })
    })
  }

  componentDidMount() { //ページ表示時に実行
    if (this.state.modalShow === true) {
      document.title = '会員登録 - ふぁみかるくん'
    } else {
      document.title = 'ログイン - ふぁみかるくん'
    }
  }

  componentDidUpdate() { //ページ表示内容更新時に実行
    if (this.state.modalShow === true) {
      document.title = '会員登録 - ふぁみかるくん'
    } else {
      document.title = 'ログイン - ふぁみかるくん'
    }
  }

  componentWillUnmount() { //ページを離れる際に実行
    document.title = 'ふぁみかるくん' //家族ホーム画面を離れる際、ページタイトルを初期値に戻す
  }

  render() {
    return (
      <div>
        <ul style={{ display: 'flex', justifyContent: 'center', padding: '0px' }}>
          <div className="justify-md-center align-Center">
            <Link to='/'><img src={logo} alt="ふぁみかるくんロゴ" /></Link>

            {/* ログイン用メールアドレス、パスワード入力画面 */}
            <div>
              <Form onSubmit={(e) => this.handleEmailLogin(e)}>
                <Form.Group controlId="formLoginEmail">
                  <Form.Label>Eメールアドレス</Form.Label>
                  <Form.Control type="email" placeholder="Eメールアドレスを入力ください" name="formLoginEmail"
                    value={this.state.loginEmailValue} onChange={(e) => this.handleLoginEmailChange(e)} />
                  <Form.Text className="text-muted">　　　　　　　　　　　　　　　　　　　　　　　</Form.Text>
                </Form.Group>

                <Form.Group controlId="formLoginPassword">
                  <Form.Label>パスワード</Form.Label>
                  <Form.Control type="password" placeholder="パスワードを入力ください" name="formLoginPassword"
                    value={this.state.loginPasswordValue} onChange={(e) => this.handleLoginPasswordChange(e)} />
                </Form.Group>
              </Form>
            </div>

            {/* 「ログイン」ボタン */}
            <button type="button" className="nav-link btn btn-primary w-100 m-5px text-center col-md" onClick={this.handleEmailLogin}>
              ログイン
            </button>

            {/* 「会員登録」ボタン */}
            <button type="button" className="nav-link btn btn-primary w-100 m-5px text-center col-md"
              onClick={() => {
                document.title = '会員登録 - ふぁみかるくん'
                this.setState({ modalShow: true })
                this.props.history.push('/Login?view=Regist')
              }}>
              会員登録
            </button>
            <Form.Text className="text-muted">
              <br />
              <a href="https://famikar.jp/privacy/" target="_blank" rel="noopener noreferrer">個人情報保護方針</a><br />
              <br />
              <a href="https://famikar.jp" target="_blank" rel="noopener noreferrer">ふぁみかるくん　サービスページ</a><br />
              <br />
              運営元：<a href="https://gometo.co.jp/" target="_blank" rel="noopener noreferrer">株式会社GoMeTo</a>
            </Form.Text>

            {/* Googleログインボタン（無効化） */}
            {/*
            <Link to="" onClick={this.handleGoogleLogin} className="m-5px">
              <img src={googleLoginLogo} alt="Google Login" width={150} height={35} />
            </Link>
            */}

            {/* 会員登録画面（modal） */}
            <Modal show={this.state.modalShow} 
              onHide={() => {
                this.setState({ modalShow: false });
                this.props.history.push('/Login');
              }}>
              <Modal.Header closeButton>
                <Modal.Title>会員登録</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <Form>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formRegistFamilyName">
                          <Form.Label>姓</Form.Label>
                          {/* <Form.Control type="text" placeholder="名字を入力してください" name="formRegistFamilyName"  */}
                          <Form.Control type="text" name="formRegistFamilyName" autoComplete="family-name"
                            value={this.state.loginFamilyNameValue} 
                            onChange={(e) => this.handleLoginFamilyNameChange(e)} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formRegistFirstName">
                          <Form.Label>名</Form.Label>
                          {/* <Form.Control type="text" placeholder="名前を入力してください" name="formRegistFirstName" */}
                          <Form.Control type="text" name="formRegistFirstName" autoComplete="given-name"
                            value={this.state.loginFirstNameValue} 
                            onChange={(e) => this.handleLoginFirstNameChange(e)} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Form.Group controlId="formRegistFamilyNameKana">
                          <Form.Label>姓（カタカナ）</Form.Label>
                          {/* <Form.Control type="text" placeholder="名字（カナ）を入力してください" name="formRegistFamilyName_Kana"  */}
                          <Form.Control type="text" name="formRegistFamilyName_Kana"
                            value={this.state.loginFamilyNameKanaValue} 
                            onChange={(e) => this.handleLoginFamilyNameKanaChange(e)} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId="formRegistFirstNameKana">
                          <Form.Label>名（カタカナ）</Form.Label>
                          {/* <Form.Control type="text" placeholder="名前（カナ）を入力してください" name="formRegistFirstName_Kana" */}
                          <Form.Control type="text" name="formRegistFirstName_Kana"
                            value={this.state.loginFirstNameKanaValue} 
                            onChange={(e) => this.handleLoginFirstNameKanaChange(e)} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Form.Group controlId="formRegistEmail">
                      <Form.Label>Eメールアドレス</Form.Label>
                      {this.state.loginEmailAlertText !== '' ? (<div style={{ color: 'red' }}>{this.state.loginEmailAlertText}</div>) : (<div style={{ display: 'none' }}></div>)}
                      {/* <Form.Control type="email" placeholder="メールアドレスを入力ください" name="formRegistEmail" */}
                      <Form.Control type="email" name="formRegistEmail" autoComplete='email'
                        value={this.state.loginEmailValue} 
                        onChange={(e) => this.handleLoginEmailChange(e)}
                        onBlur={(e) => this.handleLoginEmailBlue(e)} />
                    </Form.Group>

                    <Form.Group controlId="formRegistPassword">
                      <Form.Label>パスワード ※6文字以上</Form.Label>
                      {this.state.loginPasswordAlertText !== '' ? (<div style={{ color: 'red' }}>{this.state.loginPasswordAlertText}</div>) : (<div style={{ display: 'none' }}></div>)}
                      {/* <Form.Control type="password" placeholder="パスワードを入力ください(6文字以上)" name="formRegistPassword" */}
                      <Form.Control type="password" name="formRegistPassword" 
                        minLength="6" placeholder="設定するパスワードを入力してください" autoComplete="new-password"
                        value={this.state.loginPasswordValue} 
                        onChange={(e) => this.handleLoginPasswordChange(e)} 
                        onBlur={(e) => this.handleLoginPasswordBlue(e)} />
                    </Form.Group>

                    <Form.Group controlId="formRegistPassword_confirm">
                      <Form.Label>パスワード確認</Form.Label>
                      {this.state.loginPasswordConfirmAlertText !== '' ? (<div style={{ color: 'red' }}>{this.state.loginPasswordConfirmAlertText}</div>) : (<div stye={{ display: 'none' }}></div>)}
                      {/* <Form.Control type="password" placeholder="パスワードを再入力ください" name="formRegistPassword_confirm" */}
                      <Form.Control type="password" name="formRegistPassword_confirm" 
                        minLength="6" placeholder="設定するパスワードを再入力してください" autoComplete="off"
                        value={this.state.loginPasswordConfirmValue} 
                        onChange={(e) => this.handleLoginPasswordConfirmChange(e)} 
                        onBlur={(e) => this.handleLoginPasswordConfirmBlue(e)} />
                    </Form.Group>

                    <Form.Group controlId="formAgrrementCheckbox">
                      <Form.Text className="text-muted">
                        <a href="https://famikar.jp/privacy/" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>
                      </Form.Text>
                      <Form.Check type="checkbox" id="privacy_20221225" checked={this.state.loginAgrrement}
                        onChange={(e) => this.handleAgreementChange(e)} label="個人情報保護方針に同意します。（必須）" />
                    </Form.Group>

                    <Form.Group controlId="formQuestionnaireCheckbox">
                      <Form.Text className="text-muted">
                        アプリご利用にあたり、ユーザー様にアプリ利用に関するアンケートをお願いしています。<br />
                        アンケートにご協力頂ける方は、改めて連絡させて頂きたく、下記の項目でチェックを付けて頂けますと幸いです。（任意）
                      </Form.Text>
                      <Form.Check type="checkbox" checked={this.state.loginQuestionnaireValue}
                        onChange={(e) => this.handleQuestionnaireChange(e)} label="アンケートに協力します。" />
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" 
                  onClick={() => { 
                    this.setState({ modalShow: false }); 
                    this.props.history.push('/Login'); 
                  }}>
                  キャンセル
                </Button>
                <Button variant="primary" onClick={this.handleUserRegist}>登録</Button>
              </Modal.Footer>
            </Modal>

            {/* 処理中画面（modal） */}
            <Modal show={this.state.modalRegistProcessShow}>
              <Modal.Header>
                <Modal.Title>会員登録 - 処理中</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <div style={{margin: '7em 0'}}>
                    会員登録処理中<br />
                    画面を閉じないでください。
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>

            {/* ログイン中画面（modal） */}
            <Modal show={this.state.modalLoginProcessShow}>
              <Modal.Header>
                <Modal.Title>ログイン - 処理中</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container>
                  <div style={{margin: '7em 0'}}>
                    ログイン処理中<br />
                    画面を閉じないでください。
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          </div>
        </ul>
      </div>
    )
  }
}

export default withRouter(Login)
