import React          from "react";
import { withRouter } from 'react-router';
import Button         from '@material-ui/core/Button';
import TextField      from '@material-ui/core/TextField';
import Grid           from '@material-ui/core/Grid'
import MenuItem       from '@material-ui/core/MenuItem';
import queryString    from 'query-string'
import Resizer        from 'react-image-file-resizer';
import 'bootstrap/dist/css/bootstrap.min.css';
import firebase       from 'firebase/app';
import 'firebase/auth';

const DataImageList = {
  list:[
    //{
    //  number:       "(数値)",     //画像番号
    //  source_url:   "(文字列)",   //取り込み元画像
    //  image_url:    "(文字列)",   //経過画像パス
    //  thum_url:     "(文字列)",   //経過サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //  image_change: "(ブール値)", //経過登録画像の変更有無（true=変更あり、false=変更なし）
    //}
  ],
  UploadList:[
    //{
    //  image_url:    "(文字列)",   //経過画像パス
    //  thum_url:     "(文字列)",   //経過サムネイル画像パス
    //  CreateTime:   "(日時)",     //作成時刻
    //  UpdateTime:   "(日時)",     //更新時刻
    //  ShootingTime: "(日時)",     //撮影時刻
    //}
  ]
}

//画像リサイズ用定数
const resizer = {
  maxWidth:           1920,    //変換後画像の最大横幅
  maxHeight:          1920,    //変換後画像の最大縦幅
  maxThumbnailWidth:  320,     //変換後サムネイル画像の最大横幅
  maxThumbnailHeight: 320,     //変換後サムネイル画像の最大縦幅
  compressFormat:     "JPEG",  //圧縮フォーマット（JPEG, PNG or WEBP）
  quality:            80,      //画質（0 ~ 100 圧縮しない場合は100をセットする。）
  outputType:         "bolb" //出力タイプ（base64, bolb, file）
}

/**
 * 経過情報登録・更新・削除モーダル用のコンポーネント
 */
class ModalFollowUp extends React.Component {
  constructor(props) {
    super(props);

    // URL情報から更新・削除対象のDocIdを取得
    let docId = '';
    if(window.location.search){
      docId = queryString.parse(window.location.search).data||null;
    }
    //if(this.props.location.search){
    //  docId = queryString.parse(this.props.location.search).data||null;
    //}

    this.state = {
      //DisplayName:     this.props.location.state.DisplayName,     // 表示対象者の名前
      //MedicalRecordId: this.props.location.state.MedicalRecordId, // 症状カードID
      DisplayName:        props.DisplayName, // 表示対象者の名前
      MedicalRecordId:    props.MedicalRecordId, // 症状カードID
      FollowUpId:         docId,                       //経過情報ID(更新・削除用)
      FollowUpTime:       this.getDateDisplayFormat(), //入力日時(自動入力)
      Appetite:           '', //食欲
      BodyTemperature:    '', //体温
      Moisture:           '', //水分摂取
      Mood:               '', //機嫌
      RunnyNose:          '', //鼻水
      Headache:           '', //頭痛
      Diarrhea:           '', //下痢
      Vomit:              '', //嘔吐
      Phlegm:             '', //痰
      BreathSounds:       '', //呼吸音
      Stomachache:        '', //腹痛
      Eruption:           '', //発疹
      Spasm:              '', //けいれん
      Note:               '', //メモ
      image_url:          '', //経過記録画像URL
      thum_url:           '', //経過記録サムネイル画像URL
      CreateTime:         '', //作成日時
      UpdateTime:         '', //更新日時
      FollowUp_image_url: '', //経過登録用画像
      FollowUp_thum_url:  '', //経過登録用サムネイル画像
      data_image_list:    [], //経過画像格納用リスト
    }
    DataImageList.list = []
    DataImageList.UploadList = []
    //console.log(`this.state.DisplayName: ${this.state.DisplayName}`)
    //console.log(`this.state.MedicalRecordId: ${this.state.MedicalRecordId}`)
    //console.log(`docId: ${docId}`)
  }

  /**
   * 画面上に表示される各要素の選択肢一覧
   * (下記の設定を変更すれば、render()部の修正は不要)
   */
  choicesMap = {
//    Appetite: [     // 食欲
//      "普段通り食べれている",
//      "普段の半分くらい食べれている",
//      "全く食べれていない"],
    Appetite: [     // 食欲
      "普段通り",
      "普段の半分くらい",
      "全く食べられない"],
//    Moisture: [     // 水分
//      "普段通り取れている",
//      "普段の半分くらい取れている",
//      "全く取れていない"],
    Moisture: [     // 水分
      "普段通り",
      "普段の半分くらい",
      "全く取れない"],
//    Mood: [         // 機嫌
//      "機嫌が良い",
//      "機嫌がやや悪い",
//      "機嫌が悪い"],
    Mood: [         // 機嫌
      "良い",
      "やや悪い",
      "悪い"],
//    RunnyNose: [    // 鼻水
//      "鼻水なし",
//      "鼻水がやや出ている",
//      "鼻水が出ている"],
    RunnyNose: [    // 鼻水
      "なし",
      "やや出ている",
      "出ている"],
//    Headache: [     // 頭痛
//      "頭痛なし",
//      "頭痛がやや出ている",
//      "頭痛が出ている"],
    Headache: [     // 頭痛
      "なし",
      "やや出ている",
      "出ている"],
//    Diarrhea: [     // 下痢
//      "下痢なし",
//      "下痢がやや出ている",
//      "下痢が出ている"],
    Diarrhea: [     // 下痢
      "なし",
      "やや出ている",
      "出ている"],
//    Vomit: [        // 嘔吐
//      "嘔吐なし",
//      "嘔吐がやや出ている",
//      "嘔吐が出ている"], 
    Vomit: [        // 嘔吐
      "なし",
      "やや出ている",
      "出ている"], 
//    Phlegm: [       // 痰
//      "痰なし",
//      "痰がやや出ている",
//      "痰が出ている"],
    Phlegm: [       // 痰
      "なし",
      "やや出ている",
      "出ている"],
//    BreathSounds: [ // 呼吸音
//      "呼吸音の異常なし",
//      "呼吸音にやや異常が出ている",
//      "呼吸音に異常あり"],
    BreathSounds: [ // 呼吸音
      "異常なし",
      "やや異常あり",
      "異常あり"],
//    Stomachache: [  // 腹痛
//      "腹痛なし",
//      "腹痛がやや出ている",
//      "腹痛がある"],
    Stomachache: [  // 腹痛
      "なし",
      "やや痛い",
      "痛い"],
//    Eruption: [ // 発疹
//      "発疹なし",
//      "発疹がやや出ている",
//      "発疹がある"],
    Eruption: [ // 発疹
      "なし",
      "やや出ている",
      "あり"],
//    Spasm: [        // けいれん
//      "けいれん なし",
//      "けいれん がやや出ている",
//      "けいれん がある"],
    Spasm: [        // けいれん
      "なし",
      "やや出ている",
      "あり"],
    x: ["-"]
  }

  /**
   * 登録・削除の場合、登録済みデータをfirestoreから取得
   * (コンポーネントマウント時に実行)
   */
  async componentDidMount() {
    await this.ReadFollowUp()    //データベースから経過情報を読み出す。
  }

  //データベースに登録されている、経過情報を読み出す。
  async ReadFollowUp() {
    console.log("ReadFollowUp() start!!")
    var db = firebase.firestore()

    // firestoreから現在の登録データを取得し、モーダル表示画面に反映
    if(this.state.FollowUpId){
      let FollowUpTime = '' //入力日時
      let Appetite = ''     //食欲
      let Moisture = ''     //水分について
      let Mood = ''         //機嫌について
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("datas").doc(this.state.FollowUpId)
      .get().then(async(doc)=>{
        if(doc.data()){
          var data = doc.data()
          var FollowUp_source_url = ''                                          //経過登録用
          var FollowUp_thum_url   = ("thum_url"  in data) ? data.thum_url  : '' //経過登録用サムネイル画像
          var FollowUp_image_url  = ("image_url" in data) ? data.image_url : '' //経過登録用画像
          DataImageList.list = []
          DataImageList.UploadList = []
          
          //入力日時のフォーマット変更
          if (Object.prototype.toString.call(data.FollowUpTime) === '[object String]') {
            FollowUpTime = data.FollowUpTime
            console.log(FollowUpTime)
          } else if (Object.prototype.toString.call(data.FollowUpTime.toDate()) === '[object Date]') {
            let FollowUpTime_date = data.FollowUpTime.toDate()
            FollowUpTime = FollowUpTime_date.getFullYear() + "年" + 
                           (FollowUpTime_date.getMonth()+1).toString().padStart(2, '0') + "月" +
                           FollowUpTime_date.getDate().toString().padStart(2, '0') + "日（" +
                           [ "日", "月", "火", "水", "木", "金", "土" ][FollowUpTime_date.getDay()] + "）" +
                           FollowUpTime_date.getHours().toString().padStart(2, '0') + "時" +
                           FollowUpTime_date.getMinutes().toString().padStart(2, '0') + "分"
            console.log(FollowUpTime)
          }
          
          //食欲について
          Appetite = data.Appetite || ''
          switch(Appetite) {
            case '◯':
            case 'ok':
            case '普段通り':
              Appetite = '普段通り'
              break
            case '△':
            case '普段の半分くらい':
              Appetite = '普段の半分くらい'
              break
            case '✕':
            case 'no':
            case '全く食べられない':
              Appetite = '全く食べられない'
              break
            case '':
            default:
              Appetite = ''
              break
          }
          
          //水分について
          Moisture = data.Moisture || ''
          switch(Moisture) {
            case '◯':
            case 'ok':
            case '普段通り':
              Moisture = '普段通り'
              break
            case '△':
            case '普段の半分くらい':
              Moisture = '普段の半分くらい'
              break
            case '✕':
            case 'no':
            case '全く取れない':
              Moisture = '全く取れない'
              break
            case '':
            default:
              Moisture = ''
              break
          }
          
          //機嫌について
          Mood = data.Mood || ''
          switch(Mood) {
            case '◯':
            case 'ok':
            case '良い':
              Mood = '良い'
              break
            case '△':
            case 'やや悪い':
              Mood = 'やや悪い'
              break
            case '✕':
            case 'no':
            case '悪い':
              Mood = '悪い'
              break
            case '':
            default:
              Mood = ''
              break
          }
          
          //旧型式の経過画像データに画像登録がある場合は、経過画像リストに読み込む（2021/08/29 追加 高橋）
          if (FollowUp_image_url !== '' && FollowUp_thum_url !== '') {
            //ブラウザ上で一時格納する、画像リストに旧形式で参照データを格納した画像を0番目の画像として格納する
            DataImageList.list.push({
              number:       0,                                                                //画像番号
              source_url:   FollowUp_source_url,                                              //取り込み元画像（旧型式の経過データは空欄として扱う。）
              image_url:    FollowUp_image_url,                                               //経過画像パス
              thum_url:     FollowUp_thum_url,                                                //経過サムネイル画像パス
              CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //経過情報更新時刻
              ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報画像作成時刻
              image_change: false,                                                            //経過登録画像の変更有無（true=変更あり、false=変更なし）
            })
            
            //データベースにアップロードする画像リストに画像を登録する
            DataImageList.UploadList.push({
              image_url:    FollowUp_image_url,                                               //経過画像パス
              thum_url:     FollowUp_thum_url,                                                //経過サムネイル画像パス
              CreateTime:   ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報が初めて記録された時刻
              UpdateTime:   ("UpdateTime"   in data) ? data.UpdateTime.toDate() : new Date(), //経過情報更新時刻
              ShootingTime: ("CreateTime"   in data) ? data.CreateTime.toDate() : new Date(), //経過情報画像作成時刻
            })
          }
          
          if("images" in data){
            //新形式で保存された画像情報読み込みを行う
            data.images.forEach((image, index)=>{
              
              //旧形式で保存された画像情報を、新形式用画像情報読み込みしないようにする
              if(index !== '0' && FollowUp_image_url !== image.image_url && FollowUp_thum_url !== image.thum_url){
                DataImageList.list.push({
                  number:       index,                                                                //画像番号
                  source_url:   '',                                                                   //取り込み元画像（旧型式の経過データは空欄として扱う。）
                  image_url:    image.image_url,                                                      //経過画像パス
                  thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
                  CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
                  UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
                  ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
                  image_change: false,                                                                //経過登録画像の変更有無（true=変更あり、false=変更なし）
                })
                
                DataImageList.UploadList.push({
                  image_url:    image.image_url,                                                      //経過画像パス
                  thum_url:     image.thum_url,                                                       //経過サムネイル画像パス
                  CreateTime:   ("CreateTime"   in image) ? image.CreateTime.toDate()   : new Date(), //経過情報が初めて記録された時刻
                  UpdateTime:   ("UpdateTime"   in image) ? image.UpdateTime.toDate()   : new Date(), //経過情報更新時刻
                  ShootingTime: ("ShootingTime" in image) ? image.ShootingTime.toDate() : new Date(), //経過情報画像撮影時刻
                })
              }
            })
          }
          
          this.setState({
            FollowUpTime:    FollowUpTime                            || '', //入力日時
            Appetite:        Appetite                                || '', //食欲
            BodyTemperature: Number(data.BodyTemperature).toFixed(1) || '', //体温
            Moisture:        Moisture                                || '', //水分摂取
            Mood:            Mood                                    || '', //機嫌
            RunnyNose:       data.RunnyNose                          || '', //鼻水
            Headache:        data.Headache                           || '', //頭痛
            Diarrhea:        data.Diarrhea                           || '', //下痢
            Vomit:           data.Vomit                              || '', //嘔吐
            Phlegm:          data.Phlegm                             || '', //痰
            BreathSounds:    data.BreathSounds                       || '', //呼吸音
            Stomachache:     data.Stomachache                        || '', //腹痛
            Eruption:        data.Eruption                           || '', //発疹
            Spasm:           data.Spasm                              || '', //けいれん
            Note:            data.Note                               || '', //メモ
            image_url:       data.image_url                          || '', //経過情報画像URL
            thum_url:        data.thum_url                           || '', //経過情報サムネイル画像URL
            CreateTime:      data.CreateTime.toDate()                || '', //作成日時
            UpdateTime:      data.UpdateTime.toDate()                || '', //更新日時
            data_image_list: DataImageList.list,                            //propsに経過画像リストを反映する。
          })
        }
      })
    }
  }


  /**
   * キャンセルボタン用関数
   */
  handleGoBack = () =>{
    this.props.history.goBack();
  }

  //モーダルを非表示状態に切り替える
  handleModalClose = () => {
    if(typeof this.props.handleModalClose == 'function'){
      this.props.handleModalClose() // 症状カード画面のモーダルを閉じる
    }
  }

  //モーダルを非表示状態に切り替え、症状カード画面で再読み込みする
  handleReloadModalClose = async() => {
    if(typeof this.props.handleReloadModalClose == 'function'){
      await this.props.handleReloadModalClose() // 症状カード画面のモーダルを閉じ、画面内データを再読み込みする。
    }
  }

  /**
   * 経過情報登録モーダルの各選択肢の変更を制御する関数
   * (TextFieldのname要素とstateの名前を対応付けて処理を共通化)
   * @param {*} e 各選択肢のTextField要素
   */
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  //画像添付時の処理
  //handleRegistFamilyImage = (e) =>{
  //  var btn_input_file = document.getElementById('file_affectedPartImage');
  //  btn_input_file.click();
  //}

  // 経過画像変更用「参照」ボタンを押した際の処理
  handleFollowUpReferenceButton = () => {
    var btn_input_file = document.getElementById("file_affectedPartImage");
    btn_input_file.click();
  }

  //経過記録画像変更時の処理
  handleFollowUpImageFileChange = async(e) => {
    var createObjectURL = (window.URL || window.webkitURL).createObjectURL || window.createObjectURL;
    var files = e.target.files
    var fileCount = files.length //読み込んだファイル数
    var num = DataImageList.list.length
    for (var i = 0; i < fileCount; i++ ) {
      var source_url     = createObjectURL(files[i])
      var image_url      = await this.resizeImage(files[i])
      var image_thum_url = await this.resizeThumbnailImage(files[i])
    
      DataImageList.list.push({
        number:       (num + i),                       //画像番号
        source_url:   source_url,                      //取り込み元画像
        image_url:    image_url,                       //経過画像パス
        thum_url:     image_thum_url,                  //経過サムネイル画像パス
        CreateTime:   new Date(),                      //経過情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //経過情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //経過画像撮影日時
        image_change: true,                            //経過登録画像の変更有無（true=変更あり、false=変更なし）
      })

      DataImageList.UploadList.push({
        image_url:    image_url,                       //経過画像パス
        thum_url:     image_thum_url,                  //経過サムネイル画像パス
        CreateTime:   new Date(),                      //経過情報が初めて記録された時刻
        UpdateTime:   new Date(),                      //経過情報更新時刻
        ShootingTime: new Date(files[i].lastModified), //経過画像撮影日時
      })
    }

    this.setState({
      //FollowUp_image_url:      image_url, // 経過画像をブラウザメモリ上で参照するオブジェクトURLで取得する。
      FollowUp_image_url:      '',   // 経過画像をブラウザメモリ上で参照するオブジェクトURLで取得する。javascriptのセキュリティエラー対策で参照URLを登録しない
      FollowUp_image_change:   true, // 経過登録画像の変更有りとして記録する。
      FollowUpImageBox_width:  320,  // 経過画像表示領域の横幅を320pxで設定する。
      FollowUpImageBox_height: 320,  // 経過画像表示領域の高さを320pxで設定する。
      data_image_list: DataImageList.list //propsに経過画像リストを反映する。
    }) 
  }

  /**
   * 現在日付を[YYYYMMDDhhmmss]形式で返す関数
   */
  getDateIdFormat = () => {
    var now = new Date();
    return "" + now.getFullYear()
      + (now.getMonth()+1).toString().padStart(2, '0')
      + now.getDate().toString().padStart(2, '0')
      + now.getHours().toString().padStart(2, '0')
      + now.getMinutes().toString().padStart(2, '0')
      + now.getSeconds().toString().padStart(2, '0');
  }

  /**
   * 現在日付を[MM月DD日（曜日）hh時mm分]形式で返す関数
   */
  getDateDisplayFormat = () => {
    var now = new Date();
    return (now.getMonth()+1).toString().padStart(2, '0') + "月"
      + now.getDate().toString().padStart(2, '0') + "日（"
      + [ "日", "月", "火", "水", "木", "金", "土" ][now.getDay()] + "）"
      + now.getHours().toString().padStart(2, '0') + "時"
      + now.getMinutes().toString().padStart(2, '0') + "分"
  }

  /**
   * 削除ボタン押下時の処理
   */
  handleDeleteButtonClick = async() => {
    // 削除実行の確認用ポップアップ
    if (!window.confirm("経過情報を削除します")) {
      alert("削除をキャンセルしました");
      this.props.history.goBack();
      return;
    }

    await this.deleteFollowUp();
  }

  async deleteFollowUp(){
    let db = firebase.firestore()

    //削除処理は前画面からデータを引き継いでいる場合のみ実行可能
    if(this.state.FollowUpId){
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("datas").doc(this.state.FollowUpId).delete()
      .then(()=>{
        alert("削除に成功しました")
      })
      .catch(function(error) {
        alert("削除に失敗しました")
        console.log("経過情報の削除に失敗しました ", error);
      });
    }
    //this.props.history.goBack();
    this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
  }

  /**
   * 登録ボタン押下時の処理
   */
  handleRegistButtonClick = async() => {
    await this.registFollowUp();
  }

  async registFollowUp(){
    let db = firebase.firestore()
    let doc_id = this.getDateIdFormat()

    const setData = {  // NULLチェックは右記のショートハンドで実施([条件式]?[true]:[false] → ||)
      FollowUpTime:    this.state.FollowUpTime     || '', //入力日時
      BodyTemperature: this.state.BodyTemperature  || '', //体温（数値型）
      Appetite:        this.state.Appetite         || '', //食欲
      Moisture:        this.state.Moisture         || '', //水分
      Mood:            this.state.Mood             || '', //機嫌
      RunnyNose:       this.state.RunnyNose        || '', //鼻水
      Headache:        this.state.Headache         || '', //頭痛
      Diarrhea:        this.state.Diarrhea         || '', //下痢
      Vomit:           this.state.Vomit            || '', //嘔吐
      Phlegm:          this.state.Phlegm           || '', //痰
      BreathSounds:    this.state.BreathSounds     || '', //呼吸音
      Stomachache:     this.state.Stomachache      || '', //腹痛
      Eruption:        this.state.Eruption         || '', //発疹
      Spasm:           this.state.Spasm            || '', //けいれん
      Note:            this.state.Note             || '', //メモ
      image_url:       this.state.image_url        || '', //経過記録画像URL
      UpdateTime:      this.state.UpdateTime       || firebase.firestore.FieldValue.serverTimestamp(), //更新時刻（firebaseのサーバー時刻）
      CreateTime:      this.state.CreateTime       || firebase.firestore.FieldValue.serverTimestamp(), //作製時刻（firebaseのサーバー時刻）
      //images:          DataImageList.UploadList,           //登録画像リスト
    }
    console.log("FollowUpRegist registFollowUp["+this.state.MedicalRecordId+"]")

    if(this.state.FollowUpId){ //更新時
      await this.RegistFollowUpImage(doc_id)
      setData.images = DataImageList.UploadList //登録画像リスト
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("datas").doc(this.state.FollowUpId).set(setData).then(async()=>{
        doc_id = this.state.FollowUpId
        alert("経過情報を登録しました")
        await this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
      })
      .catch(function(error) {
        alert("更新に失敗しました")
        console.log("経過情報の更新に失敗しました ", error);
      });
    }
    else{ //新規登録時
      await this.RegistFollowUpImage(doc_id)
      setData.images = DataImageList.UploadList //登録画像リスト
      await db.collection("MedicalRecords").doc(this.state.MedicalRecordId)
      .collection("datas").doc(doc_id).set(setData).then(async()=>{
        alert("経過情報を登録しました")
        await this.handleReloadModalClose() //モーダル画面を閉じ、画面データの再読み込みを行う。
      })
      .catch(function(error) {
        alert("登録に失敗しました")
        console.log("経過情報の登録に失敗しました ", error);
      });
    }
  }

  //画像リサイズ処理（サムネイル画像）
  resizeThumbnailImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxThumbnailWidth,  //変換後サムネイル画像の最大横幅
      resizer.maxThumbnailHeight, //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  //画像リサイズ処理
  resizeImage = (file) => new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,                       //画像ファイルのパス
      resizer.maxWidth,           //変換後サムネイル画像の最大横幅
      resizer.maxHeight,          //変換後サムネイル画像の最大縦幅
      resizer.compressFormat,     //圧縮フォーマット（JPEG, PNG or WEBP）
      resizer.quality,            //画質（0 ~ 100 圧縮しない場合は100をセットする。）
      0,                          //画像の回転度数（0,90,180,270,360）
      (uri) => {resolve(uri)},    //responseUriFunc
      resizer.outputType,         //出力タイプ（base64, bolb, file）
    )
  })

  //経過画像登録処理
  async RegistFollowUpImage(doc_id){
    console.log('RegistFollowUpImage() doc_id:' + doc_id)
    if(this.state.FollowUp_image_change === true){
      //DataImageList.list.forEach((images)=>{
      await Promise.all(DataImageList.list.map(async(images)=>{
      //for (const images of DataImageList.list) {
        var ShootingTime = `${images.ShootingTime.getFullYear()}/${('0' + (images.ShootingTime.getMonth() + 1)).slice(-2)}/${('0' + images.ShootingTime.getDate()).slice(-2)} ` +
                          ` ${('0' + images.ShootingTime.getHours()).slice(-2)}:${('0' + images.ShootingTime.getMinutes()).slice(-2)}:${('0' + images.ShootingTime.getSeconds()).slice(-2)}`
        //サムネイル画像アップロード
        if(images.thum_url.includes('data:image/jpeg;base64,') && images.image_url.includes('data:image/jpeg;base64,')){ //base64形式フォーマットとなっていることを確認する
          var thum_data        = images.thum_url
          var data             = images.image_url
          var thum_fileName    = images.number + "-thum.jpg"                                                        // サムネイル画像のファイル名を設定
          var fileName         = images.number + ".jpg"                                                             // 画像のファイル名を設定
          var storageRef       = firebase.storage().ref('member_data_image')                                        // Storageの参照先ディレクトリを「member_data_image」に設定する。
          var thum_ImagesRef   = storageRef.child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + thum_fileName) // サムネイル画像のStorageの参照先ファイル名を設定する。
          var ImagesRef        = storageRef.child(this.state.MedicalRecord_ID + '/' + doc_id + '/' + fileName)      // 画像のStorageの参照先ファイル名を設定する。
          var update_thum_url  = ''                                                                                 // サムネイル画像のStorageアクセス用URLを格納する
          var update_image_url = ''                                                                                 // 画像のStorageアクセス用URLを格納する
          
          // サムネイル画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await thum_ImagesRef.putString(thum_data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // 画像をfirebase Storageに保存する。
          // Data stringからアップロードする。
          await ImagesRef.putString(data, 'data_url', {
            contentType: 'image/jpeg',
            customMetadata: {
              ShootingTime: ShootingTime
            }
          }).catch(function(er){
            console.log("image upload error", er)
          })
          
          // firebase Storageへ保存したサムネイル画像への参照URLを取得
          await thum_ImagesRef.getDownloadURL().then((url)=>{
            update_thum_url = url
            DataImageList.list[images.number].thum_url       = update_thum_url
            DataImageList.UploadList[images.number].thum_url = update_thum_url
          })
          
          // firebase Storageへ保存した画像への参照URLを取得
          await ImagesRef.getDownloadURL().then((url)=>{
            update_image_url = url
            DataImageList.list[images.number].image_url       = update_image_url
            DataImageList.UploadList[images.number].image_url = update_image_url
          })
        }
      }))
    }
    console.log('RegistFollowUpImage() doc_id:' + doc_id + ' finish !')
  }

  render(){
    //体温の選択肢アイテムを用意
    const tmp1List = [];
    for ( let temp = Number(35.5).toFixed(1); temp <= 42.0; temp = Number(Number(temp) + 0.1).toFixed(1) ){
      tmp1List.push(<MenuItem key={temp} value={temp}>{temp}</MenuItem>)
    }

    //経過画像リスト確認用
    const FollowUpImgView = []
    this.state.data_image_list.forEach((images)=>{
      FollowUpImgView.push(
        <Grid item xs={12} key={'DataImage_' + images.number}>
          <img style={{width: '100%', margin: '5px 0'}} src={images.image_url} alt={images.CreateTime.toLocaleDateString()} />
        </Grid>
      )
    })
  
    return (
      /*<Modal.Body>*/
      <React.Fragment>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            経過：自宅での様子を残しておきましょう
          </Grid>
          <Grid container item xs={12} justifyContent="flex-end">
            {this.state.DisplayName}
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth required id="FollowUpTime" label="日時" name="FollowUpTime" value={this.state.FollowUpTime} onChange={(e)=>this.handleChange(e)}/>
          </Grid>
        
          <Grid item xs={5}>
            <TextField select fullWidth required id="selBodyTemperature" label="体温" name="BodyTemperature" value={this.state.BodyTemperature} onChange={(e)=>this.handleChange(e)}>
              {tmp1List}
            </TextField>
          </Grid>
          <Grid item xs={1}>℃</Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selRunnyNose" label="鼻水" name="RunnyNose" value={this.state.RunnyNose} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.RunnyNose.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selHeadache" label="頭痛" name="Headache" value={this.state.Headache} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Headache.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selPhlegm" label="痰" name="Phlegm" value={this.state.Phlegm} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Phlegm.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selBreathSounds" label="呼吸音" name="BreathSounds" value={this.state.BreathSounds} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.BreathSounds.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selVomit" label="嘔吐" name="Vomit" value={this.state.Vomit} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Vomit.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selDiarrhea" label="下痢" name="Diarrhea" value={this.state.Diarrhea} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Diarrhea.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selStomachache" label="腹痛" name="Stomachache" value={this.state.Stomachache} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Stomachache.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selEruption" label="発疹" name="Eruption" value={this.state.Eruption} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Eruption.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField select fullWidth id="selSpasm" label="けいれん" name="Spasm" value={this.state.Spasm} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Spasm.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField select fullWidth id="selAppetite" label="食欲" name="Appetite" value={this.state.Appetite} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Appetite.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField select fullWidth id="selMoisture" label="水分摂取" name="Moisture" value={this.state.Moisture} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Moisture.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={4}>
            <TextField select fullWidth id="selMood" label="機嫌" name="Mood" value={this.state.Mood} onChange={(e)=>this.handleChange(e)}>
              {this.choicesMap.Mood.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)}
            </TextField>
          </Grid>
          <Grid item xs={6}></Grid>

          <Grid item xs={12}>
            <TextField fullWidth id="inputNote" label="メモ" name="Note" value={this.state.Note} onChange={(e)=>this.handleChange(e)}/>
          </Grid>

          {/* 経過画像登録 */}
          <Grid item xs={12}>症状がわかる画像があれば登録しましょう</Grid>
          <div id="FollowUpImg">{FollowUpImgView}</div>
          <Grid item xs={12} style={{display: 'block'}}>
            {/* 読み込みした経過画像を表示する。 */}
          </Grid>
          <Grid item xs={12}>
            <Button variant="outlined" color="primary" onClick={this.handleFollowUpReferenceButton}>
            画像登録
            </Button>
            <input type="file" multiple id="file_affectedPartImage" accept=".jpg,.jpeg,.png,.webp,.svg" style={{display: 'none'}} onChange={(e)=>this.handleFollowUpImageFileChange(e)} />
          </Grid>
          <Grid item xs={12}>　</Grid>
          {/*
          <Grid item xs={12}>
            <div>
              <Button fullWidth variant="outlined" color="primary" onClick={this.handleRegistFamilyImage} >
                画像を添付する
                <canvas id="cv_image" width="0" height="0" style={{position:'absolute', textAlign:'center'}} src={this.state.image_url} />
              </Button>
            </div>
            <div>
              <input type="file" id="file_affectedPartImage" accept=".jpg,.jpeg,.png" style={{display:'none'}} onChange={this.handleImageFileChange} />
            </div>
          </Grid>
          */}
        </Grid>
        <Grid container justifyContent="space-between" alignItems="flex-start" style={{marginTop: '1rem',}}>
          <div style={{display: (this.state.FollowUpId ? 'block' : 'none')}}> {/* 経過情報が登録済みの場合のみ削除ボタンを表示する */}
            <Button variant="outlined" color="secondary" onClick={this.handleDeleteButtonClick}>削除</Button>
          </div>
          {/*<Button variant="outlined" color="primary" onClick={this.handleGoBack}>キャンセル</Button>*/}
          <Button variant="outlined" color="primary" onClick={this.handleModalClose}>キャンセル</Button>
          <Button variant="outlined" color="primary" onClick={this.handleRegistButtonClick}>保存</Button>
        </Grid>
      </React.Fragment>
      /*</Modal.Body>*/
    );
  }
}
export default withRouter(ModalFollowUp)