import React                          from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ReactGA                        from 'react-ga';
import { createBrowserHistory }       from 'history';
import 'react-router';
import './App.css';

import Top              from './pages/Top';
import Login            from './pages/Login';
import FamilyHome       from './pages/FamilyHome';
import Invitation       from './pages/Invitation';
import PersonalHome     from './pages/PersonalHome';
import FamilyRegist     from "./pages/FamilyRegist";
import SymptomCard      from './pages/SymptomCard';
import DemoTop          from './pages/DemoTop';

import 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

const trackingId = "G-0Y8HC6D0LR"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId, {
  gaOptions: {
    cookieFlags: 'SameSite=None;Secure'
  }
});
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

class App extends React.Component {
  
  //constructor(props){
  //  super(props);
  //}
  
  render(){
    return (
      /* <Router history={history}> */
      <BrowserRouter>
        <div style={{minHeight: '100vh', maxWidth: '480px'}} className="App">
          <Switch>
            <Route path='/'                             exact component={Top} />              {/* サイト表示時はTopでログインチェックする。 */}
            <Route path='/Login'                        exact component={Login} />            {/* ログイン画面 */}
            <Route path='/FamilyHome/:FamilyID'         exact component={FamilyHome} />       {/* 家族ホーム画面 */}
            <Route path='/Invitation'                   exact component={Invitation} />       {/* 家族招待画面 */}
            <Route path='/Invitation/:FamilyID'         exact component={Invitation} />       {/* 家族招待画面 */}
            <Route path='/familyregist'                 exact component={FamilyRegist} />     {/* 家族登録画面 */}
            <Route path='/PersonalHome/:MemberID'       exact component={PersonalHome} />     {/* 個人ホーム画面 */}
            <Route path='/SymptomCard/:MedicalRecordID' exact component={SymptomCard} />      {/* 症状カード画面 */}
            {/*<Route path='/FamilyHome/'                  exact component={FamilyHome} />*/}       {/* 家族ホーム画面 */}
            {/*<Route path='/PersonalHome/'                exact component={PersonalHo\me} />*/}     {/* 個人ホーム画面 */}
            <Route path='/Demo'                         exact component={DemoTop} />          {/* デモ画面トップ */}
            <Route component={Top} /> {/* URLが不一致の場合はTopでログインチェックする */}
          </Switch>
        </div>
      </BrowserRouter>
    )
  }
}

export default App;
