import React          from 'react';
import PropTypes      from 'prop-types';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Grid           from '@material-ui/core/Grid';
import Typography     from '@material-ui/core/Typography';
import Card           from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent    from '@material-ui/core/CardContent';
//import Button         from '@material-ui/core/Button';
//import CardMedia      from '@material-ui/core/CardMedia';
//import AddIcon        from '@material-ui/icons/Add'
//import Camera         from '@material-ui/icons/CameraAlt'
//import Tooltip        from '@material-ui/core/Tooltip';
//import IconButton     from '@material-ui/core/IconButton';
//import Button         from '@material-ui/core/Button';
import InterviewImg from '../img/clinical_f.svg'
import DiagnosisImg from '../img/doctor.svg'
import FollowupImg  from '../img/chart_line.svg'
import MedicineImg  from '../img/medicines.svg'

const useStyles = {
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  details: {
  },
  content: {
    flex: '1 0 auto',
    margin: 0,
    /*height: '5rem',*/
    padding: '8px 0.5rem 8px'
  },
  cover: {
    maxWidth:      65,
    maxHeight:     65,
    display:       'flex',
    alignItems:    'center',
    paddingTop:    1,
    paddingBottom: 1,
  },
  action: {
    width: 60
  },
  controls: {
    display:       'flex',
    alignItems:    'center',
    paddingLeft:   1,
    paddingBottom: 1,
  },
  paper: {
    padding:  2,
    margin:   'auto',
    maxWidth: 500,
  },
  image: {
    width:  64,
    height: 64,
  },
  img: {
    margin:    'auto',
    display:   'block',
    maxWidth:  '60',
    maxHeight: '60',
  },
};

/**
 * @brief 各種記録カードコンポーネント
 * @note 診療結果・経過情報・お薬情報を記録するカード
 * 
 * @date 2020.12.05 created
 * @author created by S.Yamada
*/
class VariousRecord extends React.Component {

  static propTypes = {
    match:    PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history:  PropTypes.object.isRequired
  }
  
  constructor(props) {
    super(props);
    this.state = {
      symptomClass:    props.symptomClass,    // カードの種類（経過・診察・お薬）
      symptomContent:  props.symptomContent,  // 症状の詳細
      registeredDate:  props.registeredDate,  // 登録日
      symptomNote:     props.symptomNote,     // メモ
      MedicalRecordID: props.MedicalRecordID, // 症状カードID
      uid:             props.uid,             // ユーザーのuid
      FamilyID:        props.FamilyID,        // 家族ID
      MemberID:        props.MemberID,        // メンバーID
      member_url:      props.member_url,      // メンバー画像URL
      thum_url:        props.thum_url,        // サムネイル画像URL
      record:          props.record,          // 症状カードの情報
      DocID:           props.DocID,           // （経過・診察結果・お薬）ドキュメントID
      //symptomRecode:  props.symptomRecode,  // レコード
      // memberName: props.memberName,
      // record_url: props.record_url,
      // memberID: props.memberID,
      // record: props.record,
      // uid: props.uid,
      // record_class: props.record_class,
      // datetime: props.datetime,
      // body_temperature: props.body_temperature,
      // note: props.note,
      // HospitalName: props.HospitalName,
    }
    
    //console.log(`VariousRecord props[${JSON.stringify(this.props,null,'\t')}]`);
    //console.log(`VariousRecord constructor`);
  }

  /* 処方箋登録 2020/07/11 アイコン非表示化に伴い無効化とする。
  handleRegistPrescription = (e) =>{
    var btn_input_file = document.getElementById('file_prescription');
    btn_input_file.click();
  }
  */
  
  handleInterview = () => {
    this.props.handleUpdateModalOpen(this.state.symptomClass,'')
  }

  /**
   * @brief 経過情報画面へ移動
   */
  handleFollowUp = () => {
    this.props.handleUpdateModalOpen(this.state.symptomClass,this.state.DocID)
  }

  /**
   * @brief 診察結果情報画面へ移動
   */
  handleDiagnosis = () => {
    this.props.handleUpdateModalOpen(this.state.symptomClass,this.state.DocID)
  }

  /**
   * @ brief お薬情報画面へ移動
   */
  handleMedicine = () => {
    this.props.handleUpdateModalOpen(this.state.symptomClass,this.state.DocID)
  }

  
  render(){
    // カードの種類に応じたパラメータを設定
    let symptomImage    = "";
    let symptomText     = "";
    let handleAction    = "";  // 画面遷移処理
    let backgroundColor = "#FFFFFF";
    switch(this.state.symptomClass) {
      case "Interview":
        symptomImage    = InterviewImg;
        symptomText     = "問診票"
        handleAction    = this.handleInterview
        backgroundColor = "#d9d2e9"
        //console.log("VariousRecord.js view Interview card")
        break
      case "Diagnosis":  // 診察結果
        symptomImage = DiagnosisImg;
        symptomText  = "診断"
        handleAction = this.handleDiagnosis
        backgroundColor = "#cfe2f3"
        //console.log("VariousRecord.js view Diagnosis card")
        break
      case "FollowUp":  // 経過情報
        symptomImage = FollowupImg;
        symptomText  = "経過"
        handleAction = this.handleFollowUp
        backgroundColor = "#f4cccc"
        //console.log("VariousRecord.js view FollowUp card")
        break
      case "Medicine":   // お薬情報
        symptomImage = MedicineImg;
        symptomText  = "お薬"
        handleAction = this.handleMedicine
        backgroundColor = "#fce5cd"
        //console.log("VariousRecord.js view Medicine card")
        break
      default:
        break
      
    }
    return (
      <React.Fragment>
        {/*console.log(`this.props.symptomClass[${this.props.symptomClass}]`)*/}
        {/*{console.log(`this.props.thum_url[${this.props.thum_url}]`)}*/}
        <Card className={this.props.classes.root}>
          <CardActionArea onClick={handleAction}>
            <CardContent className={this.props.classes.content} style={{backgroundColor: backgroundColor}}>
              <Grid container alignItems="center">
                <Grid item style={{width: '3rem', marginRight: '.5rem', textAlign: "center"}}>
                  {/* <div style={{pointerEvents: 'none', width: '3rem'}}className="btn btn-primary btn-sm">{symptomText}</div> */}
                  <img src={symptomImage} alt={symptomText} width="35px" />
                  <div>{symptomText}</div>
                </Grid>
                <Grid item style={{width: 'calc(100% - 3.5rem)', padding: "3px", backgroundColor: "#FFFFFF"}}>
                  <div className="text-left textTitle famikarColor">
                    <Typography variant="subtitle1" className="border-bottom" component="div">
                      {'登録日：' + this.state.registeredDate.toString()}
                      {/* {this.state.record.CreateTime.getFullYear()}年{this.state.record.CreateTime.getMonth()+1}月{this.state.record.CreateTime.getDate()}日 */}
                    </Typography>
                    <Typography variant="body1" component="div">
                      <div>{this.state.symptomContent}</div>
                      {/*console.log('this.state.symptomContent: ' + this.state.symptomContent)*/}
                      <div>{this.state.symptomNote}</div>
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </React.Fragment>
    );
  }
}
export default withStyles(useStyles)(withRouter(VariousRecord))